import * as React from 'react';
import { Grid, TextField, Button, } from '@mui/material';
import { useEffect, useState } from 'react';
import { useChangeState } from '../utils/useChangeState';
import * as Models from '../models/Models';
import { useSaveUserForceChangePasswordMutation } from '../store/apiSlice';
import { setError } from '../store/ApiInterface';
import { useAppDispatch } from '../store/configureStore';


export interface ForceChangePasswordProps {
    onPasswordChangedCallback(): void;
}


export const ForceChangePassword : React.FC<ForceChangePasswordProps> = (props) => {

    const dispatch = useAppDispatch();

    const [state, setState] = useState<Models.ForceChangePassword>({
        Password1: '',
        Password2: '',
    });
    
    const changeState = useChangeState(state, setState);

    const [ forceChangePassword, { isSuccess, reset } ] = useSaveUserForceChangePasswordMutation();
    
    useEffect(() => {
        if (isSuccess) {            
            props.onPasswordChangedCallback();
            reset();
        }
    }, [isSuccess, props, reset]);
    
    const handleChangePassword = (event: any) => {
        event.preventDefault();
        if (handleValidation())
        {
            forceChangePassword(state);
        }        
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var Password1 = state.Password1;
        var Password2 = state.Password2;        

        if (Password1 === "") {
            err += "Password is required!\n";
            result = false;
        }
        
        if (Password2 === "") {
            err += "Password confirmation is required!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }    
    

    // Returns the HTML Form to the render() method.  
    const renderCreateForm = () => {
        return (
            <div>
                <form name="formhandleChangePassword" onSubmit={handleChangePassword} >

                    <Grid container spacing={5}>
                        <Grid item xs={4}>
                            <TextField
                                name="Password1"
                                variant="standard"
                                label="Password"
                                type="password"
                                onChange={changeState}
                                required
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={5}>
                        <Grid item xs={4}>
                            <TextField
                                name="Password2"
                                variant="standard"
                                label="Confirm Password"
                                type="password"
                                onChange={changeState}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                        <Grid item xs={4}>
                            <Button variant="contained" color="primary" type="submit">
                                Change password
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }

    return <div>
        <h3>Change password is required</h3>
        <hr />
        {renderCreateForm()}
    </div>;
}

export default ForceChangePassword;