import * as React from 'react';
import { useState } from 'react';
import * as Models from "../models/Models";
import { Menu, MenuItem, ListItemAvatar, ListItemText, IconButton, ListItemButton } from '@mui/material';
import { MoreVert as MoreVertIcon, } from '@mui/icons-material';
import Image from './Image'

export interface MealItemState {
    anchorEl: null | HTMLElement;
}

export interface MealItemProps {    
    dish: Models.Dish;    
    menu_id?: number; //folosit cand se vizualizeaza dish-ul din order (in order pot fi mai multe meniuri si nu stii dinainte pe care meniu face userul click ca sa vada dish-ul)
    structure_id?: number;
    disabled? : boolean;
    onDeleteCallback?(id: number, menu_title: string): void;
    onViewDishCallback?(dish_id: number): void;
    onViewDishFromOrderCallback?(menu_id: number, dish_id: number, structure_id: number): void;
}

export const MealMenuItem : React.FC<MealItemProps> = (props) => {

    const { dish, disabled } = props;
    
    const [state, setState] = useState<MealItemState>({
        anchorEl: null
    });
    

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setState({ anchorEl: event.currentTarget });
    }

    const handleClose = () => {
        setState({ anchorEl: null });
    }

    const handleDelete = () => {
        setState({ anchorEl: null });
        if (props.onDeleteCallback) {
            props.onDeleteCallback(props.dish.data.menu_dish_id, props.dish.data.title);
        }
    }

    const handleView = () => {
        setState({ anchorEl: null });
        if (props.onViewDishCallback){
            props.onViewDishCallback(props.dish.data.dish_id);
        }
        if (props.onViewDishFromOrderCallback){
            props.onViewDishFromOrderCallback(props.menu_id || 0, props.dish.data.dish_id, props.structure_id || 0);
        }
    }
    
    return (
        <ListItemButton
            key = {dish.data.dish_id}
            disabled = { disabled || false }
        >                
            <ListItemAvatar>
                <Image 
                    url = {dish.picture.picture_thumbnail_url}
                    height = {48}
                    width = {48}
                />
            </ListItemAvatar>
            <ListItemText
                primary={dish.data.title}
                secondary={dish.data.description}
                primaryTypographyProps={{
                    style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }
                }}
                secondaryTypographyProps={{
                    style: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }
                }}
            />
            <IconButton
                edge="end"
                size="small"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={state.anchorEl}
                open={Boolean(state.anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem key={"view"} onClick={handleView}>View</MenuItem>
                {props.onDeleteCallback!=null ?
                    <MenuItem key={"delete"} onClick={handleDelete}>Delete</MenuItem>
                    : ""
                }
            </Menu>
        </ListItemButton>
    );

}

export default MealMenuItem;

