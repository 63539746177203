import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Tooltip, IconButton, Box, Popper, Button, Grid, TextField, ClickAwayListener, FormControlLabel, Switch, Badge } from '@mui/material';
import { AltRoute as AltRouteIcon,  } from '@mui/icons-material';
import { useChangeState } from '../utils/useChangeState';
import { MenuAvailable, OrderMenu } from '../models/Models';


export interface EditOrderPopperState {
    anchorEl: null | HTMLElement;
    menu_custom: string;
    menu_custom_checked: boolean;
}

export interface EditOrderPopperProps {
    name: string;
    menu_custom: string;
    menu_ids: OrderMenu[];
    menus_available: MenuAvailable[];
    onSaveCallback(menu_custom: string): void;
    disabled: boolean;
}

export const EditOrderPopper : React.FC<EditOrderPopperProps> = (props) => {

    const [state, setState] = useState<EditOrderPopperState>({        
        anchorEl: null,
        menu_custom: '',
        menu_custom_checked: false
    });

    const changeState = useChangeState(state, setState);

    const handleCustomMenuChecked = useCallback((menu_custom_checked: boolean) => {
        var menu_custom: string = '';

        if (menu_custom_checked){
            const menus_selected = props.menus_available.filter(m => props.menu_ids.findIndex(x => x.menu_id === m.menu_id) >= 0)

            menus_selected.forEach(x => {
                menu_custom += x.title + '\n\n';
            })
        }
        
        setState((prevState) => ({
            ...prevState,
            menu_custom_checked: menu_custom_checked,
            menu_custom: menu_custom
        }))
    }, [props.menu_ids, props.menus_available])

    
    useEffect(() => {
        //de vazut daca cand deschide popperul pe un item in care nu are salvat meniu custom vrem sa ii afisam, fara sa faca click pe switch, meniul custom precompletat
        //daca afisam default atunci nu vom mai sti cand deschide poperul daca ce vede provine dintr-o salvare anterioara sau este afisat default
        /*
        if (props.menu_custom === ''){
            handleCustomMenuChecked(true)
        }*/

    }, [props]);
    
    

    const handleCustomMenuOpen = (event: React.MouseEvent<HTMLElement>) => {        
        setState((prevState) => ({
            ...prevState,
            anchorEl: event.currentTarget,
            menu_custom: props.menu_custom,
            menu_custom_checked: props.menu_custom ? true : false
        }));
    }

    const handleCustomMenuClose = (event: any) => {
        setState((prevState) => ({
            ...prevState,
            anchorEl: null,
            menu_custom: props.menu_custom,
            menu_custom_checked: props.menu_custom ? true : false
        }));
    }

    const handleCustomMenuSave = () => {
        setState({
            ...state,
            anchorEl: null,
        });
        props.onSaveCallback(state.menu_custom);
    }
    
    return (
        <>
            <Tooltip title="Set custom menu">
                <span>                    
                    <IconButton
                        aria-label="Add-custom-menu"
                        size="large"
                        onClick={handleCustomMenuOpen}
                        disabled={ props.disabled }
                    >
                        <Badge color="secondary" variant="dot" invisible={props.menu_custom === ''}>
                            <AltRouteIcon fontSize="inherit" />
                        </Badge>
                        
                    </IconButton>
                </span>
            </Tooltip>

            <Popper id="popper-custom-menu"
                open={Boolean(state.anchorEl)}
                placement="bottom-start"
                anchorEl={state.anchorEl}>

                <ClickAwayListener onClickAway={handleCustomMenuClose}>

                    <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                        <div style={{ padding: 5 }}>

                            <Grid container spacing={2}>                                                                                   
                                <Grid item xs={12}>
                                    
                                    <FormControlLabel 
                                        control={
                                            <Switch
                                                name="menu_custom_checked"
                                                checked={state.menu_custom_checked}
                                                onChange={e => handleCustomMenuChecked(e.target.checked)}
                                                color="primary"
                                            />
                                        } 
                                        label={"Set custom menu for: " + props.name} />
                                    
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="menu_custom"
                                        label="Custom menu"
                                        variant="standard"
                                        fullWidth
                                        multiline
                                        value={state.menu_custom}
                                        onChange={changeState}
                                        disabled={!state.menu_custom_checked}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button onClick={handleCustomMenuClose}>
                                        Close
                                    </Button>
                                    <Button color="primary" onClick={e => handleCustomMenuSave()}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </ClickAwayListener>
            </Popper>
        </>
    );
    
}

export default EditOrderPopper;

