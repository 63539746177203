import * as React from 'react';
import { Box, Grid, TextField, TextareaAutosize, Button, Switch, ListItemAvatar, Card, CardHeader, CardContent, Divider, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import * as styled from './StyledComponents'
import Image from './Image'
import { useGetDishViewQuery } from '../store/apiSlice';


export interface ViewDishProps {
    dish_id: number;
    order_id?: number;
    structure_id?: number;
    menu_id?: number;
    onCloseCallback(): void;
}

export const ViewDish : React.FC<ViewDishProps> = (props) => {    

    const { data } = useGetDishViewQuery({        
        dish_id: props.dish_id,
        menu_id: props.menu_id,
        structure_id: props.structure_id,
        order_id: props.order_id
    }, 
    {skip : props.dish_id === 0});
    
    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        if (props.onCloseCallback)
            props.onCloseCallback();
    }

    return (
        data ?
            <div>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={5}>
                            <Grid item xs={4}>
                                <strong>Title:</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    id="Title"
                                    name="Title"
                                    variant="standard"
                                    value={data.data.title}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <strong>Ext code:</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    id="ExtCode"
                                    name="ExtCode"
                                    variant="standard"
                                    value={data.data.ext_code}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <strong>KCal:</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    id="KCal"
                                    name="KCal"
                                    variant="standard"
                                    type="number"
                                    value={data.data.kcal}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <strong>Description:</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <TextareaAutosize
                                    id="Description"
                                    name="Description"
                                    minRows={5}
                                    maxRows={5}
                                    style={{ width: "100%" }}
                                    className="textarea"
                                    value={data.data.description}
                                    disabled
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <strong>Enabled:</strong>
                            </Grid>
                            <Grid item xs={8}>
                                <Switch
                                    checked={data.data.enabled}
                                    disabled
                                    name="Enabled"
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={5}>
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <ListItemAvatar>
                                        <Image 
                                            url = {data.picture.picture_url}
                                            height = {400}
                                            width = {400}
                                        />                                        
                                    </ListItemAvatar>
                                </Grid>
                            </React.Fragment>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Ingredients" />
                            <Divider />
                            {data.ingredients.length > 0 ?
                                <CardContent>
                                    <Box>
                                        <Table>
                                            <TableHead>
                                                <TableRow key={0}>
                                                    <TableCell>
                                                        Name
                                                    </TableCell>
                                                    <TableCell>
                                                        Unit
                                                    </TableCell>
                                                    <TableCell>
                                                        Qty
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.ingredients.map((item) => (
                                                    <TableRow
                                                        hover
                                                        key={item.id}
                                                    >
                                                        <TableCell>
                                                            {item.ingredient_name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.ingredient_unit}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.qty}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </CardContent>
                                :
                                "There are no ingredients associtated with this dish."
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styled.buttonsBox}>
                            <Button variant="contained" color="primary" onClick={handleClose}>
                                Close
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        :
        <></>
    );
}

export default ViewDish;