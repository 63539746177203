import * as React from 'react';
import { useState } from 'react';
import * as Models from "../models/Models";
import { 
    Menu, 
    MenuItem, 
    ListItem,     
    ListItemText, 
    IconButton,
    List, 
    Divider,    
    Card, 
    CardHeader,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import SchedulerItemAvatar from './SchedulerItemAvatar';


export interface SchedulerItemState {
    anchorEl: null | HTMLElement;
}

export interface SchedulerItemProps {
    effective_date: string;
    day_content: Models.DayContent;
    onOpenEditSchedulerMenuDialog(type: number, effective_date: string, day_content: Models.DayContent): void;
}


export const SchedulerItem : React.FC<SchedulerItemProps> = (props) => {

    const { effective_date, day_content } = props;

    const [state, setState] = useState<SchedulerItemState>({
        anchorEl: null,
    });

  
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setState({ ...state, anchorEl: event.currentTarget });
    }

    const handleClose = () => {
        setState({ ...state, anchorEl: null });
    }

    const handleEdit = (type: number, day_content: Models.DayContent) => {
        setState((prevState) => ({ ...prevState, anchorEl: null }));
        props.onOpenEditSchedulerMenuDialog(type, props.effective_date, day_content);
    }    
    
    
    return (
        <>
            <ListItem
                key={effective_date + ":" + day_content.menu_type_id}
            >
                <Card sx={{ width: '100%'}}>
                    <CardHeader
                        avatar={
                            <SchedulerItemAvatar triggers={day_content.triggers}/>
                        }
                        title={day_content.menu_type}
                        action={
                            <>
                                <IconButton
                                    edge="end"
                                    size="small"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={state.anchorEl}
                                    open={Boolean(state.anchorEl)}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >   
                                    <MenuItem key={"edit_one_time"} onClick={(e) => handleEdit(1, day_content)}>{"Edit menu one time for " + effective_date}</MenuItem>                 
                                    <MenuItem key={"edit_daily"} onClick={(e) => handleEdit(2, day_content)}>{"Edit menu daily recur starting from " + effective_date}</MenuItem>
                                    <MenuItem key={"edit_weekly"} onClick={(e) => handleEdit(3, day_content)}>{"Edit menu weekly recur starting from " + effective_date}</MenuItem>
                                    <MenuItem key={"edit_monthly"} onClick={(e) => handleEdit(4, day_content)}>{"Edit menu monthly recur starting from " + effective_date}</MenuItem>
                                    <MenuItem key={"stop_recurrence"} onClick={(e) => handleEdit(5, day_content)} disabled={day_content.triggers.findIndex(x => x.type >= 2 || x.type <= 4) === -1}>{"Stop recurrence at " + effective_date}</MenuItem>
                                    
                                    
                                </Menu>
                            </>
                        }
                    />
                    <Divider />
                    <List sx={{ width: '100%', overflowWrap: 'anywhere' }}>
                        {day_content.menu != null && day_content.menu.length > 0 ?
                            day_content.menu.map((menu, index) =>
                                <ListItem
                                    key={index}
                                >
                                    <ListItemText
                                        primary={menu}
                                    />
                                </ListItem>
                            )
                            :
                            <ListItem
                                key={0}
                            >
                                <ListItemText
                                    primary={"menu not defined"}
                                />
                            </ListItem>
                        }
                    </List>
                    <Divider />
                </Card>
            </ListItem>
        </>
    );    
}


export default SchedulerItem;
