import * as React from 'react';
import { useEffect, useState } from 'react';
import { Order } from '../models/Models';
import * as Validator from "../validator/Validator";
import { InputLabel, Grid, Button, Select, Input, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import * as utils from '../utils/Utils';
import { useGetCurrentDayQuery, useGetListClientsQuery, useGetListMealsOrderQuery, useSaveOrderMutation } from '../store/apiSlice';
import { setError } from '../store/ApiInterface';
import { useAppDispatch } from '../store/configureStore';
import moment from 'moment';


interface FormData {
    date: string;
    client_id: number;
    meal_id: number;
}

export const CreateOrder : React.FC = (props) => {

    const dispatch = useAppDispatch();

    const { data: currentDay } = useGetCurrentDayQuery();
    const { data: clientsList } = useGetListClientsQuery({default_value: '0', default_description: 'Select the client'});
    const { data: mealList } = useGetListMealsOrderQuery({default_value: '0', default_description: 'Select the meal type'});

    const [openDialog, setOpenDialog] = React.useState(false);

    const [ saveOrder ] = useSaveOrderMutation()

    const [state, setState] = useState<FormData>({
        date: "",
        client_id: 0,
        meal_id: 0,
    });

    useEffect(() => {
        if (currentDay && clientsList && mealList){
            setState({ date: currentDay, client_id: clientsList[0].client_id, meal_id: mealList[0]?.meal_id })
        }

    }, [currentDay, clientsList, mealList]);

    const handleChangeDate = (newValue: Date | null) => {
        setState({ ...state, date: utils.formatDate(newValue) });
    }

    const handleChangeClient = (event: any) => {
        setState({ ...state, client_id: Number(event.target.value) });
    }

    const handleChangeMeal = (event: any) => {
        setState({ ...state, meal_id: Number(event.target.value) });
    }
    
    const handleValidationCreateOrder = () => {
        let result = true;
        let err = '';

        var client_id = state.client_id;
        var meal_id = state.meal_id;

        if (!Validator.isDateISO(state.date)) {
            err += "Date is invalid!\n";
            result = false;
        }

        if (client_id === 0) {
            err += "Select the client!\n";
            result = false;
        }        

        if (meal_id === 0) {
            err += "Select meal type!\n";
            result = false;
        }     

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;

    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        if (handleValidationCreateOrder()) {
            setOpenDialog(true);
        }
    };

    const handleCreateOrder = (event: any) => {

        setOpenDialog(false);
        if (handleValidationCreateOrder()) {
            var order: Order = new Order();

            order.client_id = state.client_id;
            order.meal_id = state.meal_id;
            order.order_date = state.date;

            saveOrder(order);
        }
    }

    const renderSearchBox = () => {
        return (            
            <Grid container spacing={5}>
                <Grid item xs={6} sm={3}>
                    <InputLabel id="labelDatelId">
                        Date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            format="dd.MM.yyyy"
                            value={new Date(state.date)}
                            onChange={handleChangeDate}
                            slotProps={{ textField: { variant: 'standard' } }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <InputLabel id="labelClientId">
                        Client
                    </InputLabel>
                    <Select
                        id="ClientId"
                        labelId="labelClientId"
                        value={clientsList ? state.client_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangeClient}
                    >
                        {clientsList?.map(o =>
                            <MenuItem key={o.client_id} value={o.client_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <InputLabel id="labelMealId">
                        Meal Type
                    </InputLabel>
                    <Select
                        id="MealId"
                        labelId="labelMealId"
                        value={mealList ? state.meal_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangeMeal}
                    >
                        {mealList?.map(o =>
                            <MenuItem key={o.meal_id} value={o.meal_id}>{o.description}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={6} sm={3}>
                    <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                        Create Order
                    </Button>
                </Grid>
            </Grid>
        );
    }
    
    const renderConfirmationDialog = () => {
        return (
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Create new order?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ whiteSpace: 'pre-wrap' }}>
                        {`New order will be created with following details:\nClient: ${clientsList?.find(x => x.client_id === state.client_id)?.name}\nDate: ${moment(state.date).format('DD.MM.YYYY')}\nMeal Type: ${mealList?.find(x => x.meal_id === state.meal_id)?.description}`
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Close
                </Button>
                <Button onClick={handleCreateOrder} color="primary" autoFocus>
                    OK
                </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Create order</h1>
            {renderConfirmationDialog()}
            {renderSearchBox()}
        </React.Fragment>
    );

}

export default CreateOrder;
