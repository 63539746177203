import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DashboardState {
    formData: FormData;
}

export class FormData  {
    date: string = "";
    client_id: number = 0;
    
}

const unloadedState: DashboardState = {
    formData: {        
        date: "",
        client_id: 0,
    },
};

/* new reducer */

const dashboardSlice = createSlice({
    name: 'ClientsMenu',
    initialState: unloadedState,
    reducers: {
      setDashboardFormData(state, action: PayloadAction<FormData>) {
        state.formData = {                        
            ...action.payload
        }
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = dashboardSlice
  // Extract and export each action creator by name
  export const { setDashboardFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer