import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import * as Validator from "../validator/Validator";
import { ClientPrice } from '../models/Models';
import { 
    Dialog, 
    Box, 
    Tab,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Select, 
    Grid, 
    TextField, 
    Button, 
    Input, 
    Switch, 
    MenuItem, 
    InputLabel, 
    FormControlLabel,
    Checkbox} from '@mui/material';
import { DialogTitle, DialogContent } from './StyledComponents'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DeleteForever as DeleteForeverIcon, ModeEdit as ModeEditIcon } from '@mui/icons-material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import moment from 'moment';
import * as Models from '../models/Models';
import { useChangeState } from '../utils/useChangeState';
import { useDeleteClientPriceMutation, useGetClientQuery, useGetListCompaniesQuery, useGetListMenuTypeQuery, useGetListsQuery, useSaveClientMutation, useSaveClientPriceMutation } from '../store/apiSlice';
import { setError } from '../store/ApiInterface';
import { useAppDispatch } from '../store/configureStore';



export const EditClient : React.FC = (props) => {

    const params = useParams();
    
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const id = params.id != null ? parseInt(params.id) : 0;

    const { data: companiesList } = useGetListCompaniesQuery({default_value: '0', default_description: 'Select the company'});
    const { data: ccyList } = useGetListsQuery('7');
    const { data: clientData} = useGetClientQuery(id);
    const { data: menu_type } = useGetListMenuTypeQuery({ default_value: '-1', default_description: '', client_id: 0 });
    
    const [ saveClient ] = useSaveClientMutation();
    const [ saveClientPrice, { isSuccess, reset } ] = useSaveClientPriceMutation();
    const [ deleteClientPrice ] = useDeleteClientPriceMutation()


    const [state, setState] = useState({
        ...Models.getClientDefaultValues(),
        //campuri in afara modelului, necesare pt functionarea interfetei
        price_show_modal: false as boolean,
        client_price: {...Models.getClientPriceDefaultValues()} as  ClientPrice,
        tab_value: '1' as  string,
    });
    
    const changeState = useChangeState(state, setState);

    useEffect(() => {
        if (clientData){
            setState((prevState) => ({
                ...prevState,
                ...clientData
            }));
        }    
    }, [clientData]);



    const handleClosePriceModal = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            price_show_modal: false,
        }));
    }, [])

    useEffect(() => {
        if (isSuccess && state.price_show_modal){
            handleClosePriceModal();
            reset();            
        }    
    }, [isSuccess, state.price_show_modal, handleClosePriceModal, reset]);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setState({ ...state, tab_value: newValue });
    };

    const handleCheckMenuType = (event: any) => {        
        let menu_types_new = [...state.menu_types];

        const menu_type_id = Number(event.target.value);
        if (event.target.checked){
            if (menu_types_new.findIndex(x => x.menu_type_id === menu_type_id) < 0){
                const menu = menu_type?.find(x => x.menu_type_id === menu_type_id)
                if (menu){
                    menu_types_new = [...menu_types_new, menu];
                }
            }
        }
        else {
            menu_types_new = menu_types_new.filter(x => x.menu_type_id !== menu_type_id)
        }

        setState(prevState => ({
            ...prevState,
            menu_types: menu_types_new,
        }));
    }

    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {
            saveClient(state as Models.Client);
        }
    }
    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/clients");
    }
    
    const handleValidationClientPrice = () => {
        let result = true;
        let err = '';

        var client_id = state.client_price.client_id;
        var description = state.client_price.description;        

        if (client_id === 0)
        {
            err += "First save the client!\n";
            result = false;
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(description)) {
            err += "Description should be alpha numeric or underscore!\n";
            result = false;
        }
        if (description.length <= 0 || description.length > 50) {
            err += "Description should be between 1 and 50 characters!\n";
            result = false;
        }        

        if (!Validator.isDateISO(state.client_price.start_date)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (state.client_price.end_date !== "" && !Validator.isDateISO(state.client_price.end_date)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err));
        }

        return result;
    }

    const handleSavePrice = (event: any) => {
        event.preventDefault();

        if (handleValidationClientPrice()) {
            saveClientPrice(state.client_price);
        }
    }

    const handleAddPrice = () => {
        if (clientData){
            setState({
                ...state,
                price_show_modal: true,
                client_price: { ...clientData.price_empty, client_id: id },
            });
        }
    }

    const handleEditPrice = (client_price: ClientPrice) => {
        setState({
            ...state,
            price_show_modal: true,
            client_price: { ...client_price }
        });
    }

    const handleDeletePrice = (price_id: number, title: string) => {
        if (!window.confirm("Do you want to delete the price: " + title))
            return;
        else {
            deleteClientPrice({ client_id: id, id: price_id });
        }
    }

    const handleChangePrice = (event: any, meal_id: number) => {
        //modificam un item (gasit dupa meal_id) in array-ul de preturi
        var idx = state.client_price.details.findIndex(x => x.meal_id === meal_id);
        var new_deatils = [
            ...state.client_price.details.slice(0, idx),
            {
                ...state.client_price.details[idx],
                price: Number(event.target.value)
            },
            ...state.client_price.details.slice(idx + 1),
        ];

        setState({
            ...state,
            client_price: {
                ...state.client_price,
                details: new_deatils
            }
        });

    }

    const handleChangePriceCcy = (event: any, meal_id: number) => {
        //modificam un item (gasit dupa meal_id) in array-ul de preturi
        var idx = state.client_price.details.findIndex(x => x.meal_id === meal_id);
        var new_deatils = [
            ...state.client_price.details.slice(0, idx),
            {
                ...state.client_price.details[idx],
                ccy: event.target.value
            },
            ...state.client_price.details.slice(idx + 1),
        ];

        setState({
            ...state,
            client_price: {
                ...state.client_price,
                details: new_deatils
            }
        });
    }

    const handleChangePriceStartDate = (newValue: Date | null) => {
        setState({
            ...state,
            client_price: {
                ...state.client_price,
                start_date: utils.formatDate(newValue)
            }
        });
    }

    const handleChangePriceEndDate = (newValue: Date | null) => {
        setState({
            ...state,
            client_price: {
                ...state.client_price,
                end_date: utils.formatDate(newValue)
            }
        });
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var name = state.name;
        var company_id = state.company_id;

        if (!Validator.isAlphaNumericAndSpaceUnderscore(name)) {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 100) {
            err += "Name should be between 1 and 100 characters!\n";
            result = false;
        }
        if (company_id === 0) {
            err += "Select the company!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }
    
    

    // Returns the HTML Form to the render() method.  
    const renderCreateForm = () => {
        return (
            <div>
                <Dialog
                    onClose={handleClosePriceModal}
                    aria-labelledby="customized-dialog-title-edit-price"
                    open={state.price_show_modal}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <DialogTitle id="customized-dialog-title-edit-price" onClose={handleClosePriceModal}>
                        Edit price
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>

                                    <Grid item xs={12}>
                                        <InputLabel id="labelDescription">
                                            Description
                                        </InputLabel>
                                        <TextField
                                            name="description"
                                            variant="standard"
                                            value={state.client_price.description}
                                            onChange={e => changeState(e, 'client_price')}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel id="labelStartDate">
                                            Start Date
                                        </InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                format="dd.MM.yyyy"
                                                value={new Date(state.client_price.start_date)}
                                                onChange={handleChangePriceStartDate}
                                                slotProps={{ textField: { variant: 'standard' } }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel id="labelEndDate">
                                            End Date
                                        </InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                format="dd.MM.yyyy"
                                                value={new Date(state.client_price.end_date)}
                                                onChange={handleChangePriceEndDate}
                                                slotProps={{ textField: { variant: 'standard' } }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>
                                    {state.client_price.details != null && state.client_price.details.length > 0 ?
                                        state.client_price.details.map(price_detail =>
                                            <Grid item xs={12}>
                                                <InputLabel 
                                                    key={"label_" + price_detail.meal_id.toString()}
                                                    id="labelPrice"
                                                >
                                                    {price_detail.meal_description + " Price"}
                                                </InputLabel>
                                                <TextField
                                                    key={"text_" + price_detail.meal_id.toString()}
                                                    id="Price"
                                                    name="Price"
                                                    variant="standard"
                                                    type="number"
                                                    value={price_detail.price}
                                                    onChange={(e) => handleChangePrice(e, price_detail.meal_id)}
                                                    required
                                                />
                                                <Select
                                                    key={"ccy_" + price_detail.meal_id.toString()}
                                                    id="PriceCcy"
                                                    value={ccyList ? price_detail.ccy : ''}
                                                    input={<Input />}
                                                    MenuProps={utils.MenuProps}
                                                    onChange={(e) => handleChangePriceCcy(e, price_detail.meal_id)}
                                                >
                                                    {ccyList?.map(o =>
                                                        <MenuItem key={o.Value} value={o.Value}>{o.Description}</MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>
                                        )
                                        :
                                        null
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>
                                    {state.client_price.ins_date !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Created at:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {moment(state.client_price.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.client_price.ins_user !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Created by:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {state.client_price.ins_user}
                                            </Grid></> : ""
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>
                                    {state.client_price.upd_date !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Modified at:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {moment(state.client_price.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.client_price.upd_user !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Modified by:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {state.client_price.upd_user}
                                            </Grid></> : ""
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} direction="column" alignItems="center" justifyContent="center">
                                <div className="form-group text-center">
                                    <Box sx={styled.buttonsBox}>
                                        <Button variant="contained" color="primary" onClick={handleSavePrice}>
                                            Save
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={handleClosePriceModal}>
                                            Close
                                        </Button>
                                    </Box>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={state.tab_value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                <Tab label="Basic data" value="1" />
                                <Tab label="Price" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <form name="formEditClient" id="formEditClient" onSubmit={handleSave}>
                                <Grid container spacing={5}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={4}>
                                                <strong>Name:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    name="name"
                                                    variant="standard"
                                                    value={state.name}
                                                    onChange={changeState}
                                                    required
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Enabled:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Switch
                                                    name="enabled"
                                                    checked={state.enabled}
                                                    onChange={(e) => changeState(e)}
                                                    color="primary"
                                                />
                                            </Grid>

                                            <Grid item xs={4}>
                                                <strong>Company:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    name="company_id"
                                                    labelId="labelCompany"
                                                    value={companiesList ? state.company_id : ''}
                                                    input={<Input />}
                                                    MenuProps={utils.MenuProps}
                                                    onChange={(e) => changeState(e)}
                                                >
                                                    {companiesList?.map(o =>
                                                        <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box sx={styled.buttonsBox}>
                                                    <Button variant="contained" color="primary" type="submit">
                                                        Save
                                                    </Button>
                                                    <Button variant="contained" color="primary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid item xs={5}>
                                            <strong>Menu type:</strong>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Grid container spacing={1}>
                                            {
                                                menu_type?.map(item => (
                                                    <Grid item xs={12} key={ item.menu_type_id }>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name="MenuTypes"
                                                                    checked={state.menu_types.findIndex(x => x.menu_type_id === item.menu_type_id) >= 0}
                                                                    value={item.menu_type_id}
                                                                    key={item.menu_type_id}
                                                                    onChange={handleCheckMenuType}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={item.name}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Price" />
                                        <Divider />
                                        {state.price_list.length > 0 ?
                                            <CardContent>
                                                <Box>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow key={0}>
                                                                <TableCell>
                                                                    Description
                                                                </TableCell>
                                                                <TableCell>
                                                                    Start Date
                                                                </TableCell>
                                                                <TableCell>
                                                                    End Date
                                                                </TableCell>
                                                                <TableCell style={{ minWidth: "100px" }}>
                                                                    Edit / Delete
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {state.price_list.map((item: ClientPrice) => (
                                                                <TableRow
                                                                    hover
                                                                    key={item.price_id}
                                                                >
                                                                    <TableCell>
                                                                        {item.description}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {moment(item.start_date).format('DD.MM.YYYY')}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.end_date === "" ? "Unlimited" : moment(item.end_date).format('DD.MM.YYYY')}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={styled.buttonsBox}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                size="small"
                                                                                endIcon={<ModeEditIcon />}
                                                                                onClick={(id) => handleEditPrice(item)}
                                                                            >
                                                                                Edit
                                                                            </Button>

                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                endIcon={<DeleteForeverIcon />}
                                                                                onClick={(id) => handleDeletePrice(item.price_id, item.description)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </CardContent>
                                            :
                                            "Prices are not defined. To add prices use the button 'ADD PRICE'"
                                        }
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(id) => handleAddPrice()}
                                    >
                                        Add price
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Box>
                
            </div>
        )
    }

    return (
        <div>
            <h3>Client</h3>
            <hr />
            {renderCreateForm()}
        </div>
    );
}

export default EditClient;