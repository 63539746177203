import React from 'react';
import { useSelector } from 'react-redux'
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { RootState } from '../../../../../../store/configureStore';

import { ApplicationPaths } from "../../../../../api-authorization/ApiAuthorizationConstants";
import { ReactComponent as Offline } from "../../../../../../icons/offline.svg";

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const storeState = useSelector((state: RootState) => state.App)

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component={RouterLink}
        to="/"
        title="Smart Bio Food"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={'img'}
          src={
            mode === 'light' && !colorInvert
              ? '/logo.svg'
              : '/logo.svg'
          }
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>        
        <Box>
          <Box
            display={'flex'}
            alignItems={'center'}            
            sx={{ cursor: 'pointer' }}
            
          >
            <Button              
              size={'large'}
              component={RouterLink}
              to={'/contact'}
              fullWidth
              sx={{
                justifyContent: 'flex-start',
                color: theme.palette.text.primary,
                backgroundColor: 'transparent',
                fontWeight: 400,
              }}
            >
              Contact
            </Button>
          </Box>
        </Box>
        <Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            sx={{ cursor: 'pointer' }}
          >
            <Button
              size={'large'}
              component={RouterLink}
              to={'/terms'}
              fullWidth
              sx={{
                justifyContent: 'flex-start',
                color: theme.palette.text.primary,
                backgroundColor: 'transparent',
                fontWeight: 400,
              }}
            >
              Terms
            </Button>
          </Box>
        </Box>
        <Box marginLeft={4}>
          {storeState.online ?
            <Button
              variant="contained"
              color="primary"
              component="a"            
              href={storeState.auth_state?.isAuthenticated ? '/' : ApplicationPaths.Login}
              size="large"
            >
              {storeState.auth_state?.isAuthenticated ? 'Get in' : 'Login'}
            </Button>
            :
            <Box>
              <Offline width="24px" height="24px"/>
            </Box>
          }
        </Box>
      </Box>

      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        {!storeState.online &&            
            <Offline width="24px" height="24px"/>
        }
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
