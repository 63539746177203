import * as App from './App';
import * as ApiInterface from './ApiInterface';
import * as FetchUsers from './FetchUsers';
import * as FetchCompanies from './FetchCompanies';
import * as FetchMenuTypes from './FetchMenuTypes';
import * as FetchClients from './FetchClients';
import * as FetchIngredients from './FetchIngredients';
import * as FetchDishes from './FetchDishes';
import * as FetchMenus from './FetchMenus';
import * as ClientStructure from './ClientStructure';
import * as ClientsMenu from './ClientsMenu';
import * as Scheduler from './Scheduler';
import * as FetchOrders from './FetchOrders';
import * as EditOrder from './EditOrder';
import * as Reports from './Reports';
import * as Dashboard from './Dashboard';
import * as Settings from './Settings';

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    App: App.default,
    ApiInterface: ApiInterface.default,
    fetchUsers: FetchUsers.default,
    fetchCompanies: FetchCompanies.default,
    fetchMenuTypes: FetchMenuTypes.default,
    fetchClients: FetchClients.default,
    fetchIngredients: FetchIngredients.default,
    fetchDishes: FetchDishes.default,    
    fetchMenus: FetchMenus.default,
    clientStructure: ClientStructure.default,
    clientsMenu: ClientsMenu.default,
    scheduler: Scheduler.default,
    fetchOrders: FetchOrders.default,
    editOrder: EditOrder.default,
    reports: Reports.default,
    dashboard: Dashboard.default,
    settings: Settings.default,
};

