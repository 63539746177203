import * as React from 'react';
import * as Models from "../models/Models";
import { useSelector } from 'react-redux'
import * as SchedulerStore from '../store/Scheduler';
import * as Validator from "../validator/Validator";
import SchedulerItem from './SchedulerItem';
import { 
    List, 
    Input, 
    InputLabel, 
    Select, 
    MenuItem, 
    Grid, 
    Card, 
    CardHeader, 
    Divider, 
    ListItem, 
    ListItemText, 
    Box, 
    Tab, 
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    TableBody, 
    Button, 
    FormControlLabel, 
    Checkbox, 
    Dialog, 
    TextField, 
    InputAdornment, 
    DialogActions, 
    ListItemIcon,
    Tooltip,
    Typography,
} from '@mui/material';
import { DialogTitle, DialogContent } from './StyledComponents'
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import * as utils from '../utils/Utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RootState } from '../store/configureStore';
import { useDeleteSchedulerMenuMutation, useGetCurrentDayQuery, useGetListClientsQuery, useGetListMealsQuery, useGetListMenuTypeQuery, useGetListsQuery, useGetListsWithDefaultValueQuery, useGetSchedulerMenuQuery, useGetSchedulerQuery, useGetSchedulerTriggersQuery, useSaveSchedulerMenuMutation } from '../store/apiSlice';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useChangeState } from '../utils/useChangeState';
import { useAppDispatch } from '../store/configureStore';
import { setError } from '../store/ApiInterface';
import MealMenuItem from './MealMenuItem';

import { 
    AccessTime as AccessTimeIcon,
    Info as InfoIcon,
} from '@mui/icons-material';
import ViewDish from './ViewDish';
import SchedulerSetEndDatePopper from './SchedulerSetEndDatePopper';
import { formatDateYYYYMMDD } from '../utils/Utils';

class DialogState {
    view_dish_show_modal: boolean = false;
    view_dish_modal_title: string = '';
    view_dish_id: number = 0;

    edit_menu_modal_show: boolean = false;
    edit_menu_modal_title: string = '';
    edit_menu_modal_scheduler_id: number = 0;
    edit_menu_modal_type: number = 0;
    edit_menu_modal_effective_date: string = '';
    edit_menu_modal_menu_type_id: number = 0;
    edit_menu_modal_menu_ids: number[] = [];
    edit_menu_modal_recur_every: number = 0;
    edit_menu_modal_recur_on: number[] = [];
}


export const Scheduler : React.FC = (props) => {
    
    const dispatch = useAppDispatch();
    const storeState = useSelector((state: RootState) => state.scheduler)
    
    const [state, setState] = useState<SchedulerStore.FormData>({
        ...storeState.formData,
    });

    const [dialogState, setDialogState] = useState<DialogState>({
        view_dish_show_modal: false,
        view_dish_modal_title: "",
        view_dish_id: 0,
        edit_menu_modal_show: false,
        edit_menu_modal_title: "",
        edit_menu_modal_scheduler_id: 0,
        edit_menu_modal_type: 0,
        edit_menu_modal_effective_date: '',
        edit_menu_modal_menu_type_id: 0,
        edit_menu_modal_menu_ids: [],
        edit_menu_modal_recur_every: 0,
        edit_menu_modal_recur_on: [],
    });

    const [tabValue, setTabValue] = useState<string>('1');

    const { data: currentDay } = useGetCurrentDayQuery();
    const { data: clients } = useGetListClientsQuery({default_value: '0', default_description: 'Select the client'});    
    const { data: days_of_week } = useGetListsWithDefaultValueQuery({list_id: '4', default_value: '0', default_description: 'All'});
    const { data: menu_type } = useGetListMenuTypeQuery({ default_value: '0', default_description: 'All', client_id: state.client_id });
    const { data: recur_on_week } = useGetListsQuery('4');
    const { data: recur_on_month } = useGetListsQuery('8');

    const changeState = useChangeState(dialogState, setDialogState);

    const { data: mealsList } = useGetListMealsQuery();
    const [ requestSaveMenu, { isSuccess, reset } ] = useSaveSchedulerMenuMutation();
    const [ deleteSchedulerMenu ] = useDeleteSchedulerMenuMutation()   
    const { data: menus } = useGetSchedulerMenuQuery({client_id: state.client_id, menu_type_id: dialogState.edit_menu_modal_menu_type_id}, {skip: !dialogState.edit_menu_modal_show});
      


    const { data: scheduler, isLoading: isLoadingScheduler, isFetching: isFetchingScheduler } = useGetSchedulerQuery((
        state.client_id !== 0 &&
        state.date !== '' &&
        currentDay != null && 
        clients!= null &&
        days_of_week != null &&
        menu_type != null) ? state : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea

    const { data: schedulerTriggers, isLoading: isLoadingSchedulerTriggers, } = useGetSchedulerTriggersQuery((
        state.client_id !== 0 &&
        state.date !== '' &&
        currentDay != null && 
        clients!= null &&
        days_of_week != null &&
        menu_type != null) ? state : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea
    

    useEffect(() => {
        //daca este prima afisare atunci luam start date, end date si clientul din API si declansam search
        if (currentDay && clients){
            if (state.date === ''){ 
                setState((prevState) => ({
                    ...prevState,
                    date: currentDay,                    
                }));
            }
        }
    }, [state.date, currentDay, clients]);
    

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const handleChangeDate = (newValue: Date | null) => {
        setState({ ...state, date: utils.formatDate(newValue) });
    }

    const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {        
        setState({ ...state, all: event.target.checked });
    }

    const handleChangeClient = (event: any) => {
        setState({ ...state, client_id: Number(event.target.value), menu_type_id: 0 });
    }

    const handleChangeDayOfWeek = (event: any) => {
        setState({ ...state, day_of_week: Number(event.target.value) });
    }

    const handleChangeMenuType = (event: any) => {
        setState({ ...state, menu_type_id: Number(event.target.value) });
    }

    const handleOpenViewDishModal = (dish_id: number) => {
        setDialogState((prevState) => ({
            ...prevState, 
            view_dish_show_modal: true,
            view_dish_modal_title: 'View dish',
            view_dish_id: dish_id,
        }));
    }

    const handleCloseViewDishModal = () => {
        setDialogState((prevState) => ({
            ...prevState, 
            view_dish_show_modal: false,
            view_dish_modal_title: "",
            view_dish_id: 0,
        }));
    }

    const getDialogTitle = (type: number, effective_date: string, menu_type: string): string => {
        var dialog_title = '';
        if (type === 1)
            dialog_title = "Edit menu one time for " + effective_date + " and menu type " + menu_type;                
        else if (type === 2)
            dialog_title = "Edit menu daily recur starting from " + effective_date + " and menu type " + menu_type;
        else if (type === 3)
            dialog_title = "Edit menu weekly recur starting from " + effective_date + " and menu type " + menu_type;
        else if (type === 4)
            dialog_title = "Edit menu montly recur starting from " + effective_date + " and menu type " + menu_type;
        else
            dialog_title = "Unknown edit type";

        return dialog_title;

    }

    const handleEditTrigger = (trigger: Models.SchedulerTrigger) => {
        
        const dialog_title = getDialogTitle(trigger.type, trigger.effective_date, trigger.menu_type);

        setDialogState((prevState) => ({
            ...prevState, 
            edit_menu_modal_show: true,
            edit_menu_modal_title: dialog_title,
            edit_menu_modal_scheduler_id: trigger.scheduler_id,
            edit_menu_modal_type: trigger.type,
            edit_menu_modal_menu_type_id: trigger.menu_type_id,
            edit_menu_modal_effective_date: trigger.effective_date,
            edit_menu_modal_menu_ids: trigger?.menu_ids || [],
            edit_menu_modal_recur_every: trigger.type > 1 ? trigger?.recur_every || 0 : 0,
            edit_menu_modal_recur_on: trigger.type > 1 ? trigger?.recur_on || [] : [],
        }))
    }
    const handleDeleteTrigger = (scheduler_id: number) => {

        if (!window.confirm(`Do you want to delete scheduler id ${scheduler_id} ?`))
            return;
        else {
            deleteSchedulerMenu({ scheduler_id });
            return;
        }
    }

    const handleOpenMenuModal = (type: number, effective_date: string, day_content: Models.DayContent) => {

        if (type === 5) /* stop recurence*/ {

            if (!window.confirm("Do you want to stop reccurence at " + effective_date + "?"))
                return;
            else {
                var menu: Models.SchedulerMenu = new Models.SchedulerMenu();

                menu.client_id = state.client_id;
                menu.menu_type_id = day_content.menu_type_id;
                menu.type = type;
                menu.end_date = effective_date;
                menu.recur_every = 0;
                requestSaveMenu(menu);
            }
        }
        else {
            const dialog_title = getDialogTitle(type, effective_date, day_content.menu_type);
            
            const trigger = day_content.triggers.find(x => x.type === type)
            setDialogState((prevState) => ({
                ...prevState, 
                edit_menu_modal_show: true,
                edit_menu_modal_title: dialog_title,
                edit_menu_modal_scheduler_id: 0,
                edit_menu_modal_type: type,
                edit_menu_modal_menu_type_id: day_content.menu_type_id,
                edit_menu_modal_effective_date: effective_date,
                edit_menu_modal_menu_ids: trigger?.menu_ids || [],
                edit_menu_modal_recur_every: type > 1 ? trigger?.recur_every || 0 : 0,
                edit_menu_modal_recur_on: type > 1 ? trigger?.recur_on || [] : [],
            }))
        }
    }

    
    const handleCloseEditMenuModal = useCallback(() => {
        setDialogState((prevState) => ({
            ...prevState, 
            edit_menu_modal_show: false,
            edit_menu_modal_title: "",
            edit_menu_modal_scheduler_id: 0,
            edit_menu_modal_type: 0,
            edit_menu_modal_menu_type_id: 0,
            edit_menu_modal_effective_date: '',
            edit_menu_modal_menu_ids: [],
            edit_menu_modal_recur_every: 0,
            edit_menu_modal_recur_on: [],
        }));
    }, [])

    
    const recur_on_list = useMemo(() => {
        
        if (dialogState.edit_menu_modal_type === 3)
            return recur_on_week;
        if (dialogState.edit_menu_modal_type === 4)
            return recur_on_month;
        return [];

    }, [dialogState.edit_menu_modal_type, recur_on_week, recur_on_month]);

    useEffect(() => {
        //daca este prima afisare atunci luam start date, end date si clientul din API si declansam search
        if (isSuccess){
            handleCloseEditMenuModal();
            reset();
        }
    }, [isSuccess, handleCloseEditMenuModal, reset]);

    
    const handleSelectMenu = (event: any, menu_id: number) => {
        const selectedMenusIds = dialogState.edit_menu_modal_menu_ids;
        const selectedIndex = dialogState.edit_menu_modal_menu_ids.indexOf(menu_id);
        let newSelectedMenuIds: number[] = [];

        if (selectedIndex === -1) {
            newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds, menu_id);
        } else if (selectedIndex === 0) {
            newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds.slice(1));
        } else if (selectedIndex === selectedMenusIds.length - 1) {
            newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedMenuIds = newSelectedMenuIds.concat(
                selectedMenusIds.slice(0, selectedIndex),
                selectedMenusIds.slice(selectedIndex + 1)
            );
        }

        setDialogState((prevState) => ({ ...prevState, edit_menu_modal_menu_ids: newSelectedMenuIds }));
    };

    const handleSelectRecurOn = (event: any, recur_on_id: number) => {
        const selectedRecurOn = dialogState.edit_menu_modal_recur_on;
        const selectedIndex = dialogState.edit_menu_modal_recur_on.indexOf(recur_on_id);
        let newSelectedRecurOn: number[] = [];

        if (selectedIndex === -1) {
            newSelectedRecurOn = newSelectedRecurOn.concat(selectedRecurOn, recur_on_id);
        } else if (selectedIndex === 0) {
            newSelectedRecurOn = newSelectedRecurOn.concat(selectedRecurOn.slice(1));
        } else if (selectedIndex === selectedRecurOn.length - 1) {
            newSelectedRecurOn = newSelectedRecurOn.concat(selectedRecurOn.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedRecurOn = newSelectedRecurOn.concat(
                selectedRecurOn.slice(0, selectedIndex),
                selectedRecurOn.slice(selectedIndex + 1)
            );
        }
        
        setDialogState((prevState) => ({ ...prevState, edit_menu_modal_recur_on: newSelectedRecurOn }));
    };

    
    const handleSaveEditMenuModal = () => {

        var menu: Models.SchedulerMenu = new Models.SchedulerMenu();


        if (state.client_id === 0) {
            dispatch(setError("Selected client can not be 0"));
            return;
        }

        if (dialogState.edit_menu_modal_menu_type_id === 0) {
            dispatch(setError("Selected menu_type can not be 0"));
            return;
        }

        if (!(dialogState.edit_menu_modal_type >= 1 && dialogState.edit_menu_modal_type <= 4)) {
            dispatch(setError("Selected type must be 1, 2, 3, 4"));
            return;
        }

        if (dialogState.edit_menu_modal_effective_date === "") {
            dispatch(setError("Selected effective date must be completed"));
            return;
        }
        
        if (dialogState.edit_menu_modal_type > 1 && dialogState.edit_menu_modal_recur_every <= 0){
            dispatch(setError("Recur every should be a positive number"));
            return;
        }
        if (dialogState.edit_menu_modal_type === 1 && dialogState.edit_menu_modal_recur_every > 0){
            dispatch(setError("Recur every can be filled only for recurence scheduler"));
            return;
        }
        
        if ((dialogState.edit_menu_modal_type >= 3 && dialogState.edit_menu_modal_type <= 4) && dialogState.edit_menu_modal_recur_on.length <= 0){
            dispatch(setError("Recur on must have at least one element selected"));
            return;
        }

        if ((dialogState.edit_menu_modal_type >= 1 && dialogState.edit_menu_modal_type <= 4) && dialogState.edit_menu_modal_menu_ids.length <= 0){
            dispatch(setError("At least one menu must be selected!"));
            return;
        }

        menu.scheduler_id = dialogState.edit_menu_modal_scheduler_id;
        menu.client_id = state.client_id;
        menu.menu_type_id = dialogState.edit_menu_modal_menu_type_id;
        menu.type = dialogState.edit_menu_modal_type;
        menu.effective_date = dialogState.edit_menu_modal_effective_date;
        menu.recur_every = dialogState.edit_menu_modal_recur_every;
        menu.recur_on = dialogState.edit_menu_modal_recur_on;
        menu.menu_ids = dialogState.edit_menu_modal_menu_ids;

        requestSaveMenu(menu);
    }

    const handleSetEndDateModal = (trigger: Models.SchedulerTrigger, end_date: string) => {
        var menu: Models.SchedulerMenu = new Models.SchedulerMenu();

        menu.scheduler_id = trigger.scheduler_id;
        menu.client_id = state.client_id;
        menu.menu_type_id = trigger.menu_type_id;
        menu.type = trigger.type;
        menu.effective_date = trigger.effective_date;
        menu.end_date = end_date;
        menu.recur_every = trigger.recur_every;
        menu.recur_on = trigger.recur_on;
        menu.menu_ids = trigger.menu_ids;

        requestSaveMenu(menu);

    }


    const renderSearchBox = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={3}>
                    <Box>
                        <InputLabel id="labelDatelId">
                            Date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                format="dd.MM.yyyy"
                                value={new Date(state.date)}
                                onChange={handleChangeDate}
                                slotProps={{ 
                                    textField: { 
                                        variant: 'standard',
                                        style: {
                                            maxWidth: '200px'
                                        }
                                     },                                    
                                 }}
                            />
                        </LocalizationProvider>
                        {tabValue === '2' &&                            
                            <>
                                <FormControlLabel
                                    sx={{ marginLeft: 2 }}
                                    control={
                                        <Checkbox
                                            name="All"
                                            value={state.all}
                                            onChange={handleChangeAll}
                                            color="primary"
                                        />
                                    }
                                    label='All'
                                />
                                <Tooltip 
                                    title={
                                        <div style={{ whiteSpace: 'pre-line' }}>
                                            If selected all triggers with effective date above selected date will be displayed.<br/>
                                            If not selected only the triggers that touch selected date will be displayed.
                                        </div>
                                    }>
                                    <InfoIcon/>
                                </Tooltip>
                            </>
                        }
                        
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <InputLabel id="labelClientId">
                        Client
                    </InputLabel>
                    <Select
                        id="ClientId"
                        labelId="labelClientId"
                        value={clients ? state.client_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangeClient}
                    >
                        {clients?.map(o =>
                            <MenuItem key={o.client_id} value={o.client_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={3}>
                    <InputLabel id="labelDayOfWeek">
                        Day of Week
                    </InputLabel>
                    <Select
                        id="DayOfWeek"
                        labelId="labelDayOfWeek"
                        value={days_of_week ? state.day_of_week : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangeDayOfWeek}
                    >
                        {days_of_week?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={3}>
                    <InputLabel id="labelMenuType">
                        Menu Type
                    </InputLabel>
                    <Select
                        id="MenuType"
                        labelId="labelMenuType"
                        value={menu_type ? state.menu_type_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangeMenuType}
                    >
                        {menu_type?.map(o =>
                            <MenuItem key={o.menu_type_id} value={o.menu_type_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
            </Grid>
        );
    }

    const renderScheduler = () => {

        return (
            <Grid container spacing={1} style={{ paddingTop: 20 }}>
                {(Validator.isDateISO(state.date) && state.client_id !== 0) ?
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                {
                                    scheduler != null && scheduler.length > 0 ?
                                        scheduler.map(week =>                                            
                                            <Grid item xs={3} key={week.week_number}>
                                                <Card>
                                                    <CardHeader style={{ textAlign: 'center' }}
                                                        title={week.week_period}
                                                    />
                                                    {week.week_content.map(day =>
                                                        <div key={day.day_of_week_number}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <List>
                                                                        <Divider />
                                                                        <ListItem key={0}>
                                                                            <ListItemText style={{ textAlign: 'center' }}
                                                                                primary={day.day_of_week + " " + day.effective_date}
                                                                            />
                                                                        </ListItem>
                                                                        <Divider />
                                                                        {day.day_content.map(day_content =>
                                                                            <SchedulerItem
                                                                                key={day.effective_date + ":" + day_content.menu_type_id}
                                                                                effective_date={day.effective_date}
                                                                                day_content={day_content}
                                                                                onOpenEditSchedulerMenuDialog={handleOpenMenuModal}
                                                                            />

                                                                        )}
                                                                    </List>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    )}
                                                </Card>
                                            </Grid>                                            
                                        )
                                        :
                                        <Grid item xs={12}>
                                            <h6 id="tabelLabel">Client does not have associated menu types</h6>
                                        </Grid>
                                }
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Grid item xs={12}>
                        <h6 id="tabelLabel">Select the date and the hospial</h6>
                    </Grid>
                }
            </Grid>
        );
    }

    const renderTriggers = () => {

        return (
            <div>
                <Grid container spacing={5}>
                    {schedulerTriggers != null && schedulerTriggers.length > 0 ?
                        <Grid item xs={12}>                        
                            <Card>
                                <Box>
                                    <Table>
                                        <TableHead>
                                            <TableRow key={0}>
                                                <TableCell>
                                                    Scheduler Id
                                                </TableCell>
                                                <TableCell>
                                                    Menu Type
                                                </TableCell>
                                                <TableCell>
                                                    Type
                                                </TableCell>
                                                <TableCell>
                                                    Effective Date
                                                </TableCell>
                                                <TableCell>
                                                    End Date
                                                </TableCell>
                                                <TableCell>
                                                    Recur Every
                                                </TableCell>
                                                <TableCell>
                                                    Recur On
                                                </TableCell>
                                                <TableCell style={{ minWidth: "200px" }}>
                                                    Edit
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {schedulerTriggers?.map((item) => (
                                                <TableRow
                                                    hover
                                                    key={item.scheduler_id}
                                                >
                                                    <TableCell>
                                                        {item.scheduler_id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.menu_type}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.type_description}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.effective_date}
                                                    </TableCell>
                                                    <TableCell>                                                                                                                    
                                                        <SchedulerSetEndDatePopper 
                                                            trigger={item}
                                                            end_date={formatDateYYYYMMDD(item.end_date)}
                                                            onSaveCallback={handleSetEndDateModal}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ whiteSpace: 'nowrap' }}>
                                                            {item.type !== 1 ? item.recur_every: ''}
                                                            {item.type === 2 ? ' days' : item.type === 3 ? ' weeks' : item.type === 4 ? ' months' : ''}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        { (item.type === 3 ? recur_on_week : item.type === 4 ? recur_on_month : [])?.map(listItem => (
                                                            <FormControlLabel
                                                                key={listItem.Value}
                                                                control={
                                                                    <Checkbox
                                                                        name="MenuTypes"
                                                                        checked={item.recur_on.indexOf(Number(listItem.Value)) !== -1}
                                                                        value={listItem.Value}
                                                                        key={listItem.Value}
                                                                        //onChange={(event) => handleSelectRecurOn(event, Number(item.Value))}
                                                                        disabled
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={listItem.Description}
                                                            />                                    
                                                        ))
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            onClick={(id) => handleEditTrigger(item)}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            style={{ marginLeft: 16 }}
                                                            onClick={(id) => handleDeleteTrigger(item.scheduler_id)}
                                                            >
                                                            Delete
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Card>                        
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <h6 id="tabelLabel">Triggers does not exists</h6>
                        </Grid>
                        }
                </Grid>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Dialog
                onClose={handleCloseViewDishModal}
                aria-labelledby="customized-dialog-title-view-dish"
                open={dialogState.view_dish_show_modal}
                fullWidth={true}
                maxWidth={"lg"}
            >
                <DialogTitle id="customized-dialog-title-view-dish" onClose={handleCloseViewDishModal}>
                    {dialogState.view_dish_modal_title}
                </DialogTitle>
                <DialogContent dividers>
                    <ViewDish
                        dish_id={dialogState.view_dish_id}
                        onCloseCallback={handleCloseViewDishModal}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                onClose={handleCloseEditMenuModal}
                aria-labelledby="customized-dialog-title-edit-menu"
                open={dialogState.edit_menu_modal_show}
            >
                <DialogTitle id="customized-dialog-title-edit-menu" onClose={handleCloseEditMenuModal}>
                    {dialogState.edit_menu_modal_title}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={1} style={{ paddingTop: 20 }}>                
                        <Grid item xs={4}>
                            <InputLabel id="labelDescription">
                                Recur every
                            </InputLabel>
                            <TextField
                                name="edit_menu_modal_recur_every"
                                variant="standard"
                                value={dialogState.edit_menu_modal_recur_every}
                                disabled={dialogState.edit_menu_modal_type === 1}
                                onChange={changeState}
                                InputProps={{                                    
                                    endAdornment: <InputAdornment position="end">{dialogState.edit_menu_modal_type === 2 ? 'days' : dialogState.edit_menu_modal_type === 3 ? 'weeks' : dialogState.edit_menu_modal_type === 4 ? 'months' : ''}</InputAdornment>,
                                }}
                                required
                            />
                        </Grid>
                        {(dialogState.edit_menu_modal_type === 3 || dialogState.edit_menu_modal_type === 4) &&
                            <Grid item xs={8}>
                                <InputLabel id="labelDescription">
                                    Recur on
                                </InputLabel>
                                {recur_on_list?.map(item => (
                                    <FormControlLabel
                                        key={item.Value}
                                        control={
                                            <Checkbox
                                                name="MenuTypes"
                                                checked={dialogState.edit_menu_modal_recur_on.indexOf(Number(item.Value)) !== -1}
                                                value={item.Value}
                                                key={item.Value}
                                                onChange={(event) => handleSelectRecurOn(event, Number(item.Value))}
                                                color="primary"
                                            />
                                        }
                                        label={item.Description}
                                    />                                    
                                ))

                                }

                            </Grid>
                        }
                        <Grid item xs={12}>
                            {
                                menus != null && menus.length > 0 ?
                                menus.map(menu =>
                                    <div key={menu.menu_id}>
                                        <Grid container>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={dialogState.edit_menu_modal_menu_ids.indexOf(menu.menu_id) !== -1}
                                                        onChange={(event) => handleSelectMenu(event, menu.menu_id)}
                                                        value="true"
                                                        disabled={(dialogState.edit_menu_modal_type >= 1 || dialogState.edit_menu_modal_type <= 4) ? false : true}
                                                    />}
                                                label={menu.title}
                                            />
                
                                            <Grid item container direction="row" xs={12}>
                                                {mealsList?.map(meal =>
                                                    <Grid item xs={3} key={meal.meal_id}>
                                                        <List sx={{ width: '100%', maxWidth: 560 }}>
                                                            <Divider />
                                                            <ListItem key={0}>
                                                                <ListItemIcon>
                                                                    <AccessTimeIcon color="action" />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    primary={meal.description}
                                                                />
                                                            </ListItem>
                                                            <Divider />
                                                            {menu.dishes.filter(d => d.data.meal_id === meal.meal_id).map(dish =>
                                                                <MealMenuItem
                                                                    key={dish.data.dish_id}
                                                                    dish={dish}
                                                                    disabled = {!dish.data.enabled}
                                                                    onViewDishCallback={handleOpenViewDishModal}
                                                                />
                                                            )}
                                                        </List>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                                :
                                <Grid item xs={3}>
                                    <h6 id="tabelLabel">Menu is empty, please edit the menu</h6>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditMenuModal}>
                        Close
                    </Button>
                    {dialogState.edit_menu_modal_type >= 1 || dialogState.edit_menu_modal_type <= 4 ?
                        <Button color="primary" onClick={handleSaveEditMenuModal}>
                            Save
                        </Button>
                        :
                        null
                    }
                </DialogActions>
            </Dialog>

            <h1 id="tabelLabel">Scheduler</h1>
            {renderSearchBox()}
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                            <Tab label="Daily" value="1" />
                            <Tab label="Triggers" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        {isLoadingScheduler || isFetchingScheduler ? 
                            <h6 id="tabelLabel">Loading scheduler</h6>
                            : 
                            renderScheduler()
                        }
                    </TabPanel>
                    <TabPanel value="2">                        
                        {isLoadingSchedulerTriggers ? 
                            <h6 id="tabelLabel">Loading scheduler</h6>
                        : 
                        renderTriggers()
                        }
                    </TabPanel>
                </TabContext>
            </Box>
            
        </React.Fragment>
    );
}

export default Scheduler;