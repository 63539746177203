import { PayloadAction, createSlice } from '@reduxjs/toolkit';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  SettingsState {
    formData: FormData;
}

export class FormData {    
    company_id: number = 0;
}


const unloadedState: SettingsState = {
    formData: {
        company_id: 0
    }
};


/* new reducer */

const settingsSlice = createSlice({
    name: 'Settings',
    initialState: unloadedState,
    reducers: {
      setSettingsFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = settingsSlice
  // Extract and export each action creator by name
  export const { setSettingsFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer