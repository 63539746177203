import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    Typography
} from '@mui/material';

import {
    LockOutlined as LockIcon,
    Settings as SettingsIcon,
    PersonAddAlt as UserPlusIcon,
    Logout as LogOutIcon,
    EditLocationAltOutlined as EditIcon,    
    BarChart as BarChartIcon,
    AddShoppingCart as ShoppingCartIcon,
    RestaurantMenu as RestaurantMenuIcon,    
    BusinessCenter as BusinessCenterIcon,
    JoinInner as JoinInnerIcon,
    List as ListIcon,
    Fastfood as FastfoodIcon,    
    Coronavirus as CoronavirusIcon,    
    DateRange as DateRangeIcon,
    Mediation as MediationIcon,
    AddCircleOutline as AddCircleOutlineIcon,
    Assessment as AssessmentIcon,
    Kitchen as KitchenIcon,
    Call as CallIcon,
    Policy as PolicyIcon


} from '@mui/icons-material';

import NavItem from './NavItem';
import {generateAvatar} from '../../utils/Utils';



const items_company = [
    {
        href: '/dishes',
        icon: RestaurantMenuIcon,
        title: 'Dishes'
    },
    {
        href: '/ingredients',
        icon: KitchenIcon,
        title: 'Ingredients'
    },
    {
        href: '/menus',
        icon: FastfoodIcon,
        title: 'Menus'
    },
    {
        href: '/menutypes',
        icon: CoronavirusIcon,
        title: 'Menu Types'
    },
    {
        href: '/companies',
        icon: BusinessCenterIcon,
        title: 'Companies'
    },
    {
        href: '/clients',
        icon: JoinInnerIcon,
        title: 'Clients'
    },
    {
        href: '/clientsmenu',
        icon: ListIcon,
        title: 'Clients Menu'
    },
    {
        href: '/clientstructure',
        icon: MediationIcon,
        title: 'Clients Structure'
    },
    {
        href: '/scheduler',
        icon: DateRangeIcon,
        title: 'Scheduler'
    },
    {
        href: '/order/create',
        icon: AddCircleOutlineIcon,
        title: 'Create Order'
    },
    {
        href: '/orders',
        icon: ShoppingCartIcon,
        title: 'Orders'
    },
    {
        href: '/reports',
        icon: AssessmentIcon,
        title: 'Reports'
    },
    {
        href: '/dashboard',
        icon: BarChartIcon,
        title: 'Dashboard'
    },
    {
        href: '/settings',
        icon: SettingsIcon,
        title: 'Settings'
    },
    {
        href: '/fetchusers',
        icon: UserPlusIcon,
        title: 'Users'
    },
    /*
    {
        href: '/app/dashboard',
        icon: BarChartIcon,
        title: 'Dashboard2'
    },
    {
        href: '/app/customers',
        icon: UsersIcon,
        title: 'Customers'
    },
    {
        href: '/app/products',
        icon: ShoppingBagIcon,
        title: 'Products'
    },
    {
        href: '/app/account',
        icon: UserIcon,
        title: 'Account'
    },
    {
        href: '/404',
        icon: AlertCircleIcon,
        title: 'Error'
    },
    */
    {
        href: `${ApplicationPaths.Profile}`,
        icon: EditIcon,
        title: 'Profile'
    },
    {
        href: `${ApplicationPaths.LogOut}`,
        icon: LogOutIcon,
        title: 'Logout'
    },
    {
        href: '/terms',
        icon: PolicyIcon,
        title: 'Terms'
    },
    {
        href: '/contact',
        icon: CallIcon,
        title: 'Contact'
    },
];

const items_client = [    
    {
        href: '/order/create',
        icon: AddCircleOutlineIcon,
        title: 'Create Order'
    },
    {
        href: '/orders',
        icon: ShoppingCartIcon,
        title: 'Orders'
    },
    {
        href: '/reports',
        icon: AssessmentIcon,
        title: 'Reports'
    },
    {
        href: '/dashboard',
        icon: BarChartIcon,
        title: 'Dashboard'
    },
    {
        href: `${ApplicationPaths.Profile}`,
        icon: EditIcon,
        title: 'Profile'
    },
    {
        href: `${ApplicationPaths.LogOut}`,
        icon: LogOutIcon,
        title: 'Logout'
    },
    {
        href: '/terms',
        icon: PolicyIcon,
        title: 'Terms'
    },
    {
        href: '/contact',
        icon: CallIcon,
        title: 'Contact'
    },
];


const items_not_auth = [
    {
        href: `${ApplicationPaths.Login}`,
        icon: LockIcon,
        title: 'Login'
    },
    {
        href: `${ApplicationPaths.Register}`,
        icon: UserPlusIcon,
        title: 'Register'
    },
    {
        href: '/terms',
        icon: PolicyIcon,
        title: 'Terms'
    },
    {
        href: '/contact',
        icon: CallIcon,
        title: 'Contact'
    },
];

const DashboardSidebar = ({ onMobileClose, openMobile, auth }) => {
    const location = useLocation();    

    const isAdmin = auth.roles.includes('Admin');
    const isCompany = auth.roles.includes('Company_Regular') || auth.roles.includes('Company_Manager');
    const isClient = auth.roles.includes('Client_Regular') || auth.roles.includes('Client_Manager');

    const items = useMemo(() => {
        if (auth.isAuthenticated) {
            if (isAdmin || isCompany)
                return items_company;
            else if (isClient)
                return items_client;
        }
        else {
            return items_not_auth;
        }
    }, [auth.isAuthenticated, isAdmin, isCompany, isClient])

    useEffect(() => {
        if (onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname, onMobileClose]);

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            {auth.isAuthenticated &&
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        p: 2
                    }}
                >
                    <Avatar
                        component={RouterLink}
                        //src={user.avatar}
                        src={auth.profile_picture_base64 == null ? generateAvatar(auth.full_name) /*missingImage*/ : auth.profile_picture_base64}
                        sx={{
                            cursor: 'pointer',
                            width: 64,
                            height: 64
                        }}
                        to={`${ApplicationPaths.Profile}`}
                    />
                    <Typography
                        color="textPrimary"
                        variant="h5"
                    >
                        {auth.full_name}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        {auth.work_place}
                    </Typography>
                </Box>
            }
            <Divider />
            <Box sx={{ p: 2 }}>
                <List>
                    {
                        items.map((item) => (
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                                disabled={auth.force_change_password}
                                
                            />
                        ))}
                </List>
            </Box>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    PaperProps={{
                        sx: {
                            width: 256
                        }
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: 256,
                            top: 64,
                            height: 'calc(100% - 64px)'
                        }
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};



export default DashboardSidebar;
