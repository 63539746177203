import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux'
import * as ClientStructureStore from '../store/ClientStructure';
import * as Models from '../models/Models';
import * as Validator from "../validator/Validator";
import {
    Grid,
    FormControl,
    TextField,
    Dialog,
    Button,
    InputLabel,
    Select,
    Input,
    MenuItem,
    Card,
    Box,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import * as utils from '../utils/Utils';
import { DialogTitle, DialogContent, DialogActions, MinusSquare, PlusSquare, CloseSquare, StyledTreeItem } from './StyledComponents'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import moment from 'moment';
import * as styled from './StyledComponents'
import { RootState, useAppDispatch } from '../store/configureStore';
import { useAddClientStructureAccessRightMutation, useAddClientStructureMutation, useDeleteClientStructureAccessRightMutation, useDeletelientStructureMutation, useGetClientStructureAccessRightsQuery, useGetClientStructureQuery, useGetClientStructureUsersQuery, useGetListClientsQuery, useUpdateClientStructureMutation } from '../store/apiSlice';
import { setError } from '../store/ApiInterface';

export class ClientStructureState {    
    tree_toggle: boolean = false;
    user_id: number = 0;
    expandedNodeIds: string[] = [];
    selectedNodeIds: string[] = [];
    selectedNodeName: string = "";
    editNodeShowModal: boolean = false;
    editNodeModalTitle: string = "";
    editNode: Models.ClientStructure = {
        client_id: 0,
        structure_id: 0,
        name: "",
        parent_structure_id: 0,
        children: [],
        ins_date: "",
        ins_user: "",
        upd_date: "",
        upd_user: "",
    }
    tab_value: string = "";
}

const getTreeItemsFromData = (treeItems: Models.ClientStructure[]) => {
    return treeItems.map(treeItemData => {
        let children = undefined;
        if (treeItemData.children && treeItemData.children.length > 0) {
            children = getTreeItemsFromData(treeItemData.children);
        }
        return (
            <StyledTreeItem
                key={treeItemData.structure_id}
                itemId={treeItemData.structure_id.toString()}
                label={treeItemData.name}
                children={children} />
        );
    });
};
const getTreeItemData = (id: number, tree: Models.ClientStructure[] | undefined): Models.ClientStructure | null => {

    var result: Models.ClientStructure | null = null;

    if (tree){    
        for (var i = 0; i < tree.length && result == null; i++) {
            const item = tree[i];

            if (item.structure_id === id) {
                result = item;
            }
            else if (item.children != null) {
                result = getTreeItemData(id, item.children);
            }
        };
    }
    return result;
}


export const ClientStructure : React.FC = (props) => {

    const editNodeNameRef = useRef<HTMLInputElement>();
    
    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.clientStructure)


    const [state, setState] = useState<ClientStructureStore.FormData & ClientStructureState>({
        ...storeState.formData,
        tree_toggle: false,
        user_id: 0,
        expandedNodeIds: [],
        selectedNodeIds: [],
        selectedNodeName: "",
        editNodeShowModal: false,
        editNodeModalTitle: "",
        editNode: {
            client_id: 0,
            structure_id: 0,
            name: "",
            parent_structure_id: 0,
            children: [],
            ins_date: "",
            ins_user: "",
            upd_date: "",
            upd_user: "",
        },
        tab_value: "1",
    });  

    const [searchState, setSearchState] = useState<ClientStructureStore.FormData>({
        ...storeState.formData
    });

    const { data: clients } = useGetListClientsQuery({default_value: '0', default_description: 'Select the client'});
    
    const { data: tree } = useGetClientStructureQuery(searchState.client_id)
    const { data: users } = useGetClientStructureUsersQuery(searchState.client_id)
    const { data: access_rights } = useGetClientStructureAccessRightsQuery(searchState.client_id)

    const [ updateClientStructure, { isSuccess: isSuccessUpdate, reset: resetUpdate } ] = useUpdateClientStructureMutation();
    const [ addClientStructure, { isSuccess: isSuccessAdd, reset: resetAdd } ] = useAddClientStructureMutation();
    const [ deleteClientStructure ] = useDeletelientStructureMutation();
    const [ addClientStructureAccessRight ] = useAddClientStructureAccessRightMutation()
    const [ deleteClientStructureAccessRight ] = useDeleteClientStructureAccessRightMutation()

    const memoizedExpandedNodeIds = useMemo(() => {
        return state.expandedNodeIds;
    }
    , [state.expandedNodeIds]);    

        
    useEffect(() => {        
        if (tree){
            const parentNodeIds = utils.getParentNodes(tree);
            if (!state.tree_toggle){
                //conditia "!state.tree_toggle" este necesara daca a folosit "+"/"-" pt a ascunde noduri si a nu le afisa din nou dupa ce adauga/editeaza un nod
                //use case-ul este urmatorul:
                //  - ascunde toate nodurile pana la ultimul din lista
                //  - la ultimul nod adauga un pat
                //  - daca nu am avea aceasta conditie, dupa ce adauga patul se vor expanda la oc toate nodurile pe care le-a ascuns mai devreme
                setState((prevState) => ({ ...prevState, expandedNodeIds: [...parentNodeIds] }));
            }
        }    
    }, [tree, state.tree_toggle]);

    const handleCloseEditNodeModal = useCallback(() => {
        setState((prevState) => ({
            ...prevState, 
            editNodeShowModal: false,
            editNodeModalTitle: "",
            editNode: {
                client_id: 0,
                structure_id: 0,
                name: "",
                parent_structure_id: 0,
                children: [],
                ins_date: "",
                ins_user: "",
                upd_date: "",
                upd_user: "",
            }
        }));
    }, [])

    useEffect(() => {        
        if (isSuccessUpdate) {
            resetUpdate();
            handleCloseEditNodeModal();
        }
        if (isSuccessAdd) {
            resetAdd();
            handleCloseEditNodeModal();
        }
    }, [isSuccessUpdate, isSuccessAdd, resetUpdate, resetAdd, handleCloseEditNodeModal]);
    

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setState({ ...state, tab_value: newValue });
    };

    const handleChangeClient = (event: any) => {
        setState((prevState) => ({
            ...prevState, 
            client_id: Number(event.target.value), 
            selectedNodeIds: [], 
            selectedNodeName: "", 
            trigger_search: true,
            tree_toggle: false,
            expandedNodeIds: [],
        }));

        setSearchState((prevState) => ({ ...prevState, client_id: Number(event.target.value),}))
    }

    const handleChangeUser = (event: any) => {
        setState({ ...state, user_id: Number(event.target.value) });
    }

    const handleValidationEditNode = (editNode: Models.ClientStructure) => {
        let result = true;
        let err = '';

        var name = editNode.name;
        var client_id = editNode.client_id;

        if (client_id === 0) {
            err += "Client not selected!\n";
            result = false;
        }
        if (!Validator.isAlphaNumericAndSpaceUnderscore(name)) {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 50) {
            err += "Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;

    }
    const handleSaveEditNodeModal = (event: any) => {

        if (editNodeNameRef.current){
            const node = {
                ...state.editNode,
                name: editNodeNameRef.current.value
            }

            if (handleValidationEditNode(node)) {
                if (state.editNode.structure_id === 0) {
                    addClientStructure(node);
                }
                else {
                    updateClientStructure(node);
                }
            }
        }
        else {
            dispatch(setError("editNodeNameRef.current is null"))
        }
    }

    const handleAddNode = (event: any, is_root: boolean) => {

        if (state.client_id === 0) {
            dispatch(setError('First, select the client'));
            return;
        }

        if (is_root) {
            setState({
                ...state, 
                editNodeShowModal: true,
                editNodeModalTitle: 'Add node at root level',
                editNode: {
                    client_id: state.client_id,
                    structure_id: 0,
                    name: "",
                    parent_structure_id: 0,
                    children: [],
                    ins_date: "",
                    ins_user: "",
                    upd_date: "",
                    upd_user: "",
                }
            });
        }
        else {
            if (state.selectedNodeIds.length === 0) {
                dispatch(setError('First, select the parent'));
                return;
            }

            if (state.selectedNodeIds.length > 1) {
                dispatch(setError('Can not add node for multiple parents! Select one single parent!'));
                return;
            }

            const id = Number(state.selectedNodeIds[0]);
            const node = getTreeItemData(id, tree);
            if (node != null) {
                setState({
                    ...state, 
                    editNodeShowModal: true,
                    editNodeModalTitle: 'Add node for parent: ' + node.name,
                    editNode: {
                        client_id: state.client_id,
                        structure_id: 0,
                        name: "",
                        parent_structure_id: node.structure_id,
                        children: [],
                        ins_date: "",
                        ins_user: "",
                        upd_date: "",
                        upd_user: "",
                    },
                    
                });
            }
            else {
                dispatch(setError('Node with id ' + id + ' can not be found in the tree'));
            }
        }
    }

    const handleEditNode = (event: any) => {

        if (state.selectedNodeIds.length === 0) {
            dispatch(setError('First, select an node'));
            return;
        }

        if (state.selectedNodeIds.length > 1) {
            dispatch(setError('Can not edit multiple nodes! Select one single node!'));
            return;
        }

        const id = Number(state.selectedNodeIds[0]);
        const node = getTreeItemData(id, tree);
        if (node != null) {
            setState({
                ...state, 
                editNodeShowModal: true,
                editNodeModalTitle: 'Edit node ' + node.name,
                editNode: node,
            });
        }
        else {
            dispatch(setError('Node with id ' + id + ' can not be found in the tree'));
        }
    }

    const handleDeleteNode = (event: any) => {

        var nodesText = "";
        if (state.selectedNodeIds.length === 0) {
            dispatch(setError('Select the nodes to delete!'));
            return;
        }

        for (let i = 0; i < state.selectedNodeIds.length; i++) {
            const node = getTreeItemData(Number(state.selectedNodeIds[i]), tree);
            if (node != null)
                nodesText += node.name + ", ";
        }

        if (nodesText !== "")
            nodesText = nodesText.slice(0, nodesText.length - 2);

        if (!window.confirm("Do you want to delete the nodes and their descendats for: " + nodesText))
            return;
        else {
            for (let i = 0; i < state.selectedNodeIds.length; i++) {                
                deleteClientStructure({structure_id: Number(state.selectedNodeIds[i]), client_id: state.client_id});
            }
        }
    }
    const handleValidationInsertAccessRight = () => {
        let result = true;
        let err = '';

        if (state.selectedNodeIds.length === 0) {
            err += "Select the node!";
            result = false;
        }

        if (state.selectedNodeIds.length > 1) {
            err += "Select one sigle node!";
            result = false;
        }

        if (state.client_id === 0) {
            err += "Select the client!";
            result = false;
        }

        if (state.user_id === 0) {
            err += "Select the user!";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }
    const handleAddAccessRight = (event: any) => {

        var right: Models.ClientStructureAccessRight = new Models.ClientStructureAccessRight();

        if (handleValidationInsertAccessRight()) {
            right.client_id = state.client_id;
            right.structure_id = Number(state.selectedNodeIds[0]);
            right.user_id = state.user_id;

            addClientStructureAccessRight(right);
        }
    }
    const handleDeleteAccessRight = (id: number, title: string) => {
        if (!window.confirm("Do you want to delete the access rights for: " + title))
            return;
        else {
            deleteClientStructureAccessRight({id: id, client_id: state.client_id});
        }
    }

    const renderSearchBox = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={3}>
                    <InputLabel id="labelClientId">
                        Client
                    </InputLabel>
                    <Select
                        id="ClientId"
                        labelId="labelClientId"
                        value={clients ? state.client_id: ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangeClient}
                    >
                        {clients?.map(o =>
                            <MenuItem key={o.client_id} value={o.client_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
            </Grid>
        );
    }
    
    
    const renderTree = useMemo(() => {

        const handleNodeSelect = (event: React.SyntheticEvent, nodeIds: Array<string> | string) => {
            var nodes: string[] = [];
            var selectedNodeName = "";
            
            if (typeof nodeIds == 'string') {
                nodes.push(nodeIds);            
            }
            else
                nodes = nodeIds;
    
            if (nodes.length === 1) {
                const node = getTreeItemData(Number(nodeIds[0]), tree);
                if (node != null)
                    selectedNodeName = node.name;
            }
    
            setState((prevState) => ({ ...prevState, selectedNodeIds: nodes, selectedNodeName: selectedNodeName }));
        }

        const handleToggle = (event: React.SyntheticEvent, nodeIds: Array<string>) => {
            if ((event.target as Element).closest(".MuiTreeItem-iconContainer")) {
                setState((prevState) => ({ ...prevState, expandedNodeIds: nodeIds, tree_toggle: true }));
            }
        }
        
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            }}>
                {tree != null && tree.length > 0 ?
                    <SimpleTreeView
                        aria-label="customized"
                        expandedItems={memoizedExpandedNodeIds}
                        sx={{ flexGrow: 1, overflowY: 'auto', maxHeight: 'calc(100vh - 400px)' }}
                        multiSelect
                        onSelectedItemsChange={handleNodeSelect}
                        onExpandedItemsChange={handleToggle}
                        slots={{
                            expandIcon: PlusSquare,
                            collapseIcon: MinusSquare,
                            endIcon: CloseSquare,
                        }}
                    >
                        {getTreeItemsFromData(tree)}
                    </SimpleTreeView>
                    : <>Client structure is empty. Please add nodes using 'Add root' button to add root node or 'Add' to add inner node</>
                }
            </Box>
        )}
        , [tree, memoizedExpandedNodeIds, ]);
    

   
    const renderClientStructure = () => {

        var selected_structure_id = Number(state.selectedNodeIds[0]);
        var access_rights_on_selected_structure = access_rights?.filter(x => x.structure_id === selected_structure_id);

        return (
            <div>
                {state.client_id > 0 ? 
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={state.tab_value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                    <Tab label="Structure" value="1" />
                                    <Tab label="Access rights" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <Box sx={styled.buttonsBox}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={state.client_id === 0}
                                                onClick={(e) => handleAddNode(e, true)}>
                                                Add root
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={state.client_id === 0}
                                                onClick={(e) => handleAddNode(e, false)}>
                                                Add
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={state.client_id === 0}
                                                onClick={handleEditNode}>
                                                Edit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={state.client_id === 0}
                                                onClick={handleDeleteNode}>
                                                Delete
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                        {renderTree}
                                    </Grid>
                                    
                                    <Grid item xs={7}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <h3 id="tabelLabel">{"Access rights" + (state.selectedNodeName !== "" ? " for " + state.selectedNodeName : "")}</h3>
                                            </Grid>
                                            {state.selectedNodeIds.length === 1 ?
                                                <>
                                                    <Grid item xs={6}>
                                                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                            <InputLabel id="labelUserId">
                                                                Users
                                                            </InputLabel>
                                                            <Select
                                                                id="UserId"
                                                                labelId="labelUserId"
                                                                value={users ? state.user_id : ''}
                                                                input={<Input />}
                                                                MenuProps={utils.MenuProps}
                                                                onChange={handleChangeUser}
                                                            >
                                                                {users?.map(o =>
                                                                    <MenuItem key={o.UserId} value={o.UserId}>{o.LastName + " " + o.FirstName}</MenuItem>
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={state.user_id === 0}
                                                            onClick={(e) => handleAddAccessRight(e)}>
                                                            Add right
                                                        </Button>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {access_rights_on_selected_structure && access_rights_on_selected_structure?.length > 0 ?
                                                            <Card>                                                    
                                                                <Box>
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableRow key={"structure_access_rights_0"}>
                                                                                <TableCell>
                                                                                    Full Name
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    Granted
                                                                                </TableCell>
                                                                                <TableCell style={{ minWidth: "100px" }}>
                                                                                    Delete
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {access_rights_on_selected_structure.map((item, index) => (
                                                                                <TableRow
                                                                                    hover
                                                                                    key={`structure_access_rights_${item.structure_id}_${index}`}
                                                                                >
                                                                                    <TableCell>
                                                                                        {item.last_name + " " + item.first_name}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {"By " + item.ins_user + " at " + moment(item.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="secondary"
                                                                                            size="small"
                                                                                            endIcon={<DeleteForeverIcon />}
                                                                                            onClick={(id) => handleDeleteAccessRight(item.id, item.last_name + " " + item.first_name)}
                                                                                        >
                                                                                            Delete
                                                                                        </Button>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </Box>
                                                            </Card>
                                                            :
                                                            "There are no access rights associated with this node. To add rights use the button 'ADD RIGHT'"
                                                        }
                                                    </Grid>
                                                </>
                                                :
                                                <Grid item xs={12}>
                                                    Select one node from client structure
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>                                    
                                </Grid>
                            </TabPanel>
                            <TabPanel value="2">
                                <Table>
                                    <TableHead>
                                        <TableRow key={"full_access_rights_0"}>
                                            <TableCell>
                                                Structure Name
                                            </TableCell>
                                            <TableCell>
                                                Full Name
                                            </TableCell>
                                            <TableCell>
                                                Granted
                                            </TableCell>
                                            <TableCell style={{ minWidth: "100px" }}>
                                                Delete
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {access_rights?.map((item) => (
                                            <TableRow
                                                hover                                            
                                                key={`full_access_rights_${item.structure_id}_${item.user_id}`}
                                            >
                                                <TableCell>
                                                    {item.name}
                                                </TableCell>
                                                <TableCell>
                                                    {item.last_name + " " + item.first_name}
                                                </TableCell>
                                                <TableCell>
                                                    {"By " + item.ins_user + " at " + moment(item.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        size="small"
                                                        endIcon={<DeleteForeverIcon />}
                                                        onClick={(id) => handleDeleteAccessRight(item.id, item.last_name + " " + item.first_name)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                            </TabPanel>
                        </TabContext>
                    </Box>
                    :
                    null
                }
            </div>
        );
    }

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Client structure</h1>
            <Dialog 
                onClose={handleCloseEditNodeModal}
                aria-labelledby="customized-dialog-title"
                open={state.editNodeShowModal}
                fullWidth={true}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseEditNodeModal}>
                    {state.editNodeModalTitle}
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{ padding: 20 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <strong>Name:</strong>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    name="name"
                                    variant="standard"
                                    defaultValue={state.editNode.name}
                                    inputRef={editNodeNameRef}
                                    required
                                />
                            </Grid>
                            {state.editNode.ins_date !== "" ?
                                <>
                                    <Grid item xs={3}>
                                        <strong>Created at:</strong>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {moment(state.editNode.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                    </Grid></> : ""
                            }
                            {state.editNode.ins_user !== "" ?
                                <>
                                    <Grid item xs={3}>
                                        <strong>Created by:</strong>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {state.editNode.ins_user}
                                    </Grid></> : ""
                            }
                            {state.editNode.upd_date !== "" ?
                                <>
                                    <Grid item xs={3}>
                                        <strong>Modified at:</strong>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {moment(state.editNode.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                    </Grid></> : ""
                            }
                            {state.editNode.upd_user !== "" ?
                                <>
                                    <Grid item xs={3}>
                                        <strong>Modified by:</strong>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {state.editNode.upd_user}
                                    </Grid></> : ""
                            }
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditNodeModal}>
                        Close
                    </Button>
                    <Button color="primary" onClick={handleSaveEditNodeModal}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {renderSearchBox()}
            {renderClientStructure()}
        </React.Fragment>
    );
}

export default ClientStructure;
