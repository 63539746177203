import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  EditOrderState {
    selectedStructures: number[];    
}

const unloadedState: EditOrderState = {
    selectedStructures: [],
};

/* new reducer */

const EditOrderSlice = createSlice({
    name: 'EditOrderState',
    initialState: unloadedState,
    reducers: {
        clearSelectedStructures(state) {            
            state.selectedStructures = [];            
        },
        handleChangeSelectedStructures(state, action: PayloadAction<{structure_id: number, checked: boolean}>) {            
            const checked = action.payload.checked;
            const structure_id = action.payload.structure_id;

            if (checked){
                if (state.selectedStructures.find(x => x === structure_id) === undefined){
                    state.selectedStructures = state.selectedStructures.concat(structure_id);
                }
            }
            else {
                state.selectedStructures = state.selectedStructures.filter(x => x !== structure_id);
            }
        },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = EditOrderSlice
  // Extract and export each action creator by name
  export const { clearSelectedStructures, handleChangeSelectedStructures } = actions
  // Export the reducer, either as a default or named export
  export default reducer