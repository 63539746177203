import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

export function withNavigation(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}

export function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export function withLocation(Component) {
    return props => <Component {...props} location={useLocation()} />;
}


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
}
  
export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => 
        {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
  
    return windowDimensions;
  }