import * as React from 'react';
import { withNavigation } from '../hocs'
import { NavigateFunction } from 'react-router';

var navigate: NavigateFunction;

export class Navigation extends React.PureComponent {    

    constructor(props: any) {
        super(props);
        navigate = props.navigate;
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
        
    }

    componentDidUpdate(prevProps: any) {
        
    }

    render() {
        return (
            <React.Fragment/>
        );
    }
}

export function navigateToUrl(url: string) {
    if (navigate)
        navigate(url, { replace: true, state: url });
}

export default withNavigation(Navigation as any);
