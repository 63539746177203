import * as React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
//import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store/configureStore';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
    <Provider store={store}>        
        {/*<ConnectedRouter history={history}>*/}
        {/*    <App />*/}
        {/*</ConnectedRouter>*/}
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();