import { PayloadAction, createSlice } from '@reduxjs/toolkit';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  SchedulerState {
    formData: FormData;
}

export class FormData {
    date: string = "";
    all: boolean = false;
    client_id: number = 0;
    day_of_week: number = 0;
    menu_type_id: number = 0;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const unloadedState: SchedulerState = {    
    formData: {
        date: "",
        all: false,
        client_id: 0,
        day_of_week: 0,
        menu_type_id: 0,
    },
};

/* new reducer */

const schedulerSlice = createSlice({
    name: 'Scheduler',
    initialState: unloadedState,
    reducers: {
      setSchedulerFormData(state, action: PayloadAction<FormData>) {
        state.formData = {
            ...action.payload
        }
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = schedulerSlice
  // Extract and export each action creator by name
  export const { setSchedulerFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer