import * as Models from "../models/Models";
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  FetchMenuTypesState {
    formData: FormData;
}

export class FormData extends Models.SearchPaginationInput {
    name: string = "";
    enabled: string = "0";
}


const unloadedState: FetchMenuTypesState = {
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        name: "",
        enabled: "0",
    }
};

/* new reducer */

const fetchMenuTypesSlice = createSlice({
    name: 'FetchMenuTypes',
    initialState: unloadedState,
    reducers: {
      setFetchMenuTypesFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = fetchMenuTypesSlice
  // Extract and export each action creator by name
  export const { setFetchMenuTypesFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer