import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Models from "../models/Models";
import { 
    Box,
    Dialog,
    Link,
    Button,
    Typography,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    ToggleButton,
    Autocomplete,
    TextField,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    DialogActions,
    InputLabel,
    Select,
    Input,
    MenuItem,
    TextareaAutosize,
    FormControlLabel,
    Checkbox,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Badge,
    Chip,
    useTheme,
    useMediaQuery,
    Switch,
 } from '@mui/material';

import Grid from '@mui/material/Grid2'; // Grid version 2



import {
    Info as InfoIcon,
    AccessTime as AccessTimeIcon,
    LocalHotelOutlined as LocalHotelOutlinedIcon,    
    Edit as EditIcon,
    EditOff as EditOffIcon,
    Settings as SettingsIcon,
    FilterAlt as FilterAltIcon,
    EditLocationAlt as EditLocationAltIcon,
    AddCircleOutline as AddCircleOutlineIcon,
    HighlightOff as HighlightOffIcon,
    AutoStories as AutoStoriesIcon,
} from '@mui/icons-material';

import { red, green } from '@mui/material/colors';
import * as utils from '../utils/Utils';
import { DialogTitle, DialogContent } from './StyledComponents'
import moment from 'moment';
import EditOrderItem from './EditOrderItem';
import { useGetOrderQuery, useSaveOrderStatusMutation, useSaveOrderFillMutation, useGetOrderMenuQuery, useGetListMealsQuery, useGetListMenuTypeQuery, useSaveOrderDetailsMutation } from '../store/apiSlice';
import { OrderDetailsSave, OrderMenu } from '../models/Models';
import { ViewDish } from './ViewDish';
import { RootState, useAppDispatch } from '../store/configureStore';
import { setError } from '../store/ApiInterface';
import * as Validator from "../validator/Validator";
import { useChangeState } from '../utils/useChangeState';
import MealMenuItem from './MealMenuItem';
import { getStatusColor } from '../utils/Utils';
import { useSelector } from 'react-redux';
import { clearSelectedStructures } from '../store/EditOrder';
import useWindowDimensions from '../hocs';
import { InView } from 'react-intersection-observer';

export interface EditOrderState {
    status_show_status_modal: boolean;
    status_show_settings_modal: boolean;
    status_description: string;
    status_value: number;

    edit_menu_modal_show: boolean;
    edit_menu_modal_mode: number; /* 1: individual; 2: selectie multipla */
    edit_menu_modal_title: string;
    edit_menu_modal_patient_name: string;
    edit_menu_modal_details: string;
    edit_menu_modal_menu_type_id: number;
    edit_menu_modal_structure_id: number; //folosit pt cazul in care statusul nu mai este 1 (order new) cand trebuie aduse meniurile salvate la nivel de structura
    edit_menu_modal_menu_ids: OrderMenu[];

    view_dish_show_modal: boolean;
    view_dish_modal_title: string;
    view_dish_id: number;
    view_menu_id: number;
    view_dish_structure_id: number;
}

export const EditOrder : React.FC = (props) => {    

    const theme = useTheme();

    const dispatch = useAppDispatch();
    
    const params = useParams();
    const id = params.id != null ? parseInt(params.id) : 0;

    const windowDimensions = useWindowDimensions();
    const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));    

    const storeState = useSelector((state: RootState) => state.editOrder)
    const { selectedStructures } = storeState;
    
    const [state, setState] = useState<EditOrderState>({
        status_show_status_modal: false,
        edit_menu_modal_mode: 0,
        status_show_settings_modal: false,
        status_description: "",
        status_value: 0,

        edit_menu_modal_show: false,        
        edit_menu_modal_title: "",
        edit_menu_modal_patient_name: "",
        edit_menu_modal_details: "",
        edit_menu_modal_menu_type_id: 0,
        edit_menu_modal_structure_id: 0, //folosit pt cazul in care statusul nu mai este 1 (order new) cand trebuie aduse meniurile salvate la nivel de structura
        edit_menu_modal_menu_ids: [],

        view_dish_show_modal: false,
        view_dish_modal_title: "",
        view_dish_id: 0,
        view_menu_id: 0,
        view_dish_structure_id: 0,
    });

    const changeState = useChangeState(state, setState);

    const { data: order, isLoading } = useGetOrderQuery(id);

    const [ saveOrderStatus, { isSuccess: isSuccessStatus, reset: resetStatus } ] = useSaveOrderStatusMutation();
    const [ requestOrderFill ] = useSaveOrderFillMutation();

    const [menusRequest, setMenusRequest] = useState<Models.OrderRequestMenu>({
        order_id: 0,
        client_id: 0,
        order_date: '',
        menu_type_id: 0,
        structure_id: 0,
    });    

    const { data: menus } = useGetOrderMenuQuery(menusRequest, {skip: !state.edit_menu_modal_show});

    const { data: mealsList } = useGetListMealsQuery();
    const { data: menuTypeListWithDefaultValue, isLoading: isLoadingMenuListWithDefaultValue } = useGetListMenuTypeQuery({default_value: '0', default_description: 'Select menu type', client_id: order?.client_id || 0}, {skip: order === undefined});
    /* folosit la EditOrderItem - il incarcam aici ca item-urile sa il aiba deja si sa nu afiseze partial detaliile (de ex se afiseaza numele dar meniurile apar mai tarziu) */
    const { data: menuTypeList, isLoading: isLoadingMenuList } = useGetListMenuTypeQuery({default_value: '-1', default_description: '', client_id: order?.client_id || 0}, {skip: order === undefined});
    
    const [ saveOrderDetails, { isSuccess, reset } ] = useSaveOrderDetailsMutation()

    const navigate = useNavigate();
    
    const [menuTypeSuggestions, setMenuTypeSuggestions] = useState(localStorage.getItem('order-menu-type-suggestions') === 'false' ? false : true); //default enabled

    const [openSpeedDial, setOpenSpeedDial] = useState(false);
    const [multipleSelect, setMultipleSelect] = useState(false);
    const [showFilterSpeedDial, setShowFilterSpeedDial] = useState(localStorage.getItem('order-show-filter') === 'true');
    const [filterStructure, setFilterStructure] = useState<string[]>([]);


    const page_size = 10;
    const [page, setPage] = useState(1);
    const [paginationEnabled, setPaginationEnabled] = useState(localStorage.getItem('order-pagination-enabled') === 'false' ? false : true); //default paginarea este enabled

    const [headerHeight, setHeaderHeight] = useState(0);

    const measuredHeaderRef = useCallback((node: HTMLDivElement) => {
        if (node) {
            setHeaderHeight(node.getBoundingClientRect().height);
        }
    }, []);

    const pagedOrderDetails = useMemo(() => {
        if (order && order.details && order.details.length){
            if (filterStructure !== null && filterStructure.length > 0){
                return order.details.filter(item => filterStructure.includes(item.parent_name));
            }
            else if (paginationEnabled) {
                const page_order_details_new = order.details.slice(0, page * page_size - 1);
                return page_order_details_new;
            }
            else {
                return order.details;
            }
        }
        else return [];
        
    }, [page, paginationEnabled, order, filterStructure]);   
    
    
    useEffect(() => {
        if (order){
            setMenusRequest({
                order_id: order.order_id,
                client_id: order.client_id,
                order_date: order.order_date,
                menu_type_id: 0,
                structure_id: 0,
            });
        }
    }, [order, dispatch]);

    useEffect(() => {
        dispatch(clearSelectedStructures());
    }, [multipleSelect, dispatch]);

    const handleOpenSpeedDial = () => setOpenSpeedDial(true);
    const handleCloseSpeedDial = () => setOpenSpeedDial(false);    

    const selectedStrucuresNames = useMemo(() => {
        return selectedStructures.map(x => { 
            const item = order?.details.find(o => o.structure_id === x);
            return `${item?.parent_name} - ${item?.name}`
        })
    }, [order?.details, selectedStructures]);

    //folosit pt a afisa modala de modificare status - sunt afisate toate structurile
    const group_all_structures = useMemo(() => {
        if (order?.details)
            return utils.groupArrayOfObjects(order?.details, "parent_name");
    }, [order?.details]);

    //folosit pt a afisa
    // - header-ul din modala de modificare status
    // - autocomplete-ul cu filtru pe structuri
    const keys_all_structures = useMemo(() => {
        if (group_all_structures)
            return Object.keys(group_all_structures);
        else
            return [''];
    }, [group_all_structures]);

    //folosit pt a afisa order item-urile
    const group_paged_structures = useMemo(() => {
        if (pagedOrderDetails)
            return utils.groupArrayOfObjects(pagedOrderDetails, "parent_name");
    }, [pagedOrderDetails]);

    //folosit de group_keys_selected
    const keys_paged_structures = useMemo(() => {
        if (group_paged_structures)
            return Object.keys(group_paged_structures);
        else
            return [''];
    }, [group_paged_structures]);
    
    //folosit pt a afisa headrul order item-urilor
    const group_keys_selected = useMemo(() => {
        if (filterStructure == null || filterStructure.length === 0)
            return keys_paged_structures;
        else
            return keys_paged_structures.filter(item => filterStructure.includes(item));
    }, [keys_paged_structures, filterStructure]);
    

    const handleFilterStructure = (value: string[]) => {
        setFilterStructure(value);
        window.scrollTo(0, headerHeight + 10) /* add 10px: padding top of parent div of header */
    }

    const handleCloseStatusModal = useCallback(() => {
        setState((prevState) => ({
            ...prevState, 
            status_show_status_modal: false,
            status_description: '',
            status_value: 0,
        }));
    }, [])

    const handleCloseSettingsModal = useCallback(() => {
        setState((prevState) => ({
            ...prevState, 
            status_show_settings_modal: false,
        }));
    }, [])

    const handleShowStatusModal = (status_description: string, status_value: number) => {
        setState((prevState) => ({
            ...prevState, 
            status_show_status_modal: true,
            status_description: status_description,
            status_value: status_value,            
        }));
    }

    const handleChangeStatus = (status: number, description: string) => {
        if (!window.confirm("Do you want to " + description))
            return;
        else {
            saveOrderStatus({ order_id: id, status: status });
        }
    }

    useEffect(() => {
        if (isSuccessStatus && state.status_show_status_modal){
            handleCloseStatusModal();
            resetStatus();
        }    
    }, [isSuccessStatus, resetStatus, handleCloseStatusModal, state.status_show_status_modal]);

    const handleFill = useCallback((date: string, description: string) => {
        if (!window.confirm("Do you want to " + description))
            return;
        else {
            var order_status: Models.OrderRequestFill = { order_id: id, date: date };
            requestOrderFill(order_status);
        }
    }, [id, requestOrderFill])

    const handleViewDishFromOrder = (menu_id: number, dish_id: number, structure_id: number) => {
        setState({
            ...state, 
            view_dish_show_modal: true,
            view_dish_modal_title: 'View dish',
            view_dish_id: dish_id,
            view_menu_id: menu_id,
            view_dish_structure_id: structure_id,
        });
    }

    const handleCloseViewDishModal = () => {
        setState({
            ...state, 
            view_dish_show_modal: false,
            view_dish_modal_title: "",
            view_dish_id: 0,
            view_menu_id: 0,
            view_dish_structure_id: 0,
        });
    }

    const handleOpenOrderDetailsModal = useCallback((title: string, patient_name: string, details: string, menu_type_id: number, structure_id: number, menu_ids: OrderMenu[]) => {
        setState((prevState) => ({
            ...prevState, 
            edit_menu_modal_show: true,
            edit_menu_modal_mode: 1,
            edit_menu_modal_title: title,
            edit_menu_modal_patient_name: patient_name,
            edit_menu_modal_details: details,
            edit_menu_modal_menu_type_id: menu_type_id,
            edit_menu_modal_structure_id: structure_id,
            edit_menu_modal_menu_ids: menu_ids,
        }));
        searchMenu(menu_type_id, structure_id);
    }, [])

    const handleEditMultipleMenu = (event: any) => {
        setState((prevState) => ({
            ...prevState, 
            edit_menu_modal_show: true,
            edit_menu_modal_mode: 2,
            edit_menu_modal_title: `Edit menu for ${selectedStructures.length} items`,
            edit_menu_modal_patient_name: '',
            edit_menu_modal_details: '',
            edit_menu_modal_menu_type_id: 0,
            edit_menu_modal_structure_id: 0,
            edit_menu_modal_menu_ids: [],
        }));
        searchMenu(0, 0);
    }

    const handleCloseOrderDetailsModal = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            edit_menu_modal_show: false,
            edit_menu_modal_mode: 0,
            edit_menu_modal_title: "",
            edit_menu_modal_patient_name: "",
            edit_menu_modal_details: "",
            edit_menu_modal_menu_type_id: 0,
            edit_menu_modal_structure_id: 0,
            edit_menu_modal_menu_ids: [],
        }));
    }, [])
    
    useEffect(() => {
        if (isSuccess && state.edit_menu_modal_show){
            handleCloseOrderDetailsModal();
            reset();
        }    
    }, [isSuccess, reset, handleCloseOrderDetailsModal, state.edit_menu_modal_show, dispatch]);

    const searchMenu = async (menu_type_id: number, structure_id: number) => {             
        setMenusRequest((prevState) => ({...prevState, menu_type_id: menu_type_id, structure_id: structure_id}));
    }

    const handleValidationOrderDetails = () => {
        let result = true;
        let err = '';

        var menu_type_id = state.edit_menu_modal_menu_type_id;
        var structure_id = state.edit_menu_modal_structure_id;
        var patient_name = state.edit_menu_modal_patient_name;
        var details = state.edit_menu_modal_details;
        var menu_ids = state.edit_menu_modal_menu_ids;

        if (order == null || order.order_id === 0) {
            err += "Order is not saved!\n";
            result = false;
        }

        if (menu_type_id === 0) {
            err += "Select the menu type!\n";
            result = false;
        }

        if (state.edit_menu_modal_mode !== 1 && state.edit_menu_modal_mode !== 2){
            err += "Mode should be 1 or 2!\n";
            result = false;
        }
        if (state.edit_menu_modal_mode === 1 && structure_id === 0) {
            err += "Structure can not be 0!\n";
            result = false;
        }

        if (state.edit_menu_modal_mode === 2 && (selectedStructures === null || selectedStructures.length === 0)) {
            err += "Selected structures are empty!\n";
            result = false;
        }

        if (menu_ids.length === 0) {
            err += "Select the menu!\n";
            result = false;
        }


        if (patient_name !== "" && !Validator.isAlphaNumericAndSpaceUnderscore(patient_name)) {
            err += "Patient name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (patient_name.length > 50) {
            err += "Patient name should not exeed 50 characters!\n";
            result = false;
        }

        if (details !== "" && !Validator.isAlphaNumericAndSpaceUnderscore(details)) {
            err += "Details should be alpha numeric or underscore!\n";
            result = false;
        }
        if (details.length > 1000) {
            err += "Details should not exeed 1000 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;

    }

    const handleChangeMenuType = (event: any) => {
        setState((prevState) => ({ ...prevState, edit_menu_modal_menu_type_id: Number(event.target.value), edit_menu_modal_menu_ids: [] }));
        searchMenu(Number(event.target.value), state.edit_menu_modal_structure_id);
    }

    const handleSelectMenu = (event: any, menu_id: number) => {

        var selectedMenusIds: OrderMenu[] = [];

        //create clone array
        if (order?.allow_multiple_menus)
            selectedMenusIds = state.edit_menu_modal_menu_ids.map(a => { return { ...a } });

        const selectedIndex = selectedMenusIds.findIndex(x => x.menu_id === menu_id);

        const menu_item: OrderMenu = { menu_id: menu_id, qty: 1 };


        let newSelectedMenuIds: OrderMenu[] = [];

        if (selectedIndex === -1) {
            newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds, menu_item);
        } else if (selectedIndex === 0) {
            newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds.slice(1));
        } else if (selectedIndex === selectedMenusIds.length - 1) {
            newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedMenuIds = newSelectedMenuIds.concat(
                selectedMenusIds.slice(0, selectedIndex),
                selectedMenusIds.slice(selectedIndex + 1)
            );
        }

        setState((prevState) => ({ ...prevState, edit_menu_modal_menu_ids: newSelectedMenuIds }));
    };

    const handleChangeQty = (event: any, menu_id: number) => {
        //create clone array
        const selectedMenusIds = state.edit_menu_modal_menu_ids.map(a => { return { ...a } });

        const selectedMenu = selectedMenusIds.find(x => x.menu_id === menu_id);

        if (selectedMenu != null) {
            selectedMenu.qty = Number(event.target.value);
        }

        setState({ ...state, edit_menu_modal_menu_ids: selectedMenusIds });
    };
    
    const handleSaveOrderDetailsModal = (event: any) => {

        if (handleValidationOrderDetails()) {
            var order_details: OrderDetailsSave = new OrderDetailsSave();

            order_details.mode = state.edit_menu_modal_mode;
            order_details.order_id = order?.order_id || 0;
            order_details.structure_id = state.edit_menu_modal_structure_id;
            order_details.structure_ids = selectedStructures;
            order_details.menu_type_id = state.edit_menu_modal_menu_type_id;
            order_details.menu_ids = state.edit_menu_modal_menu_ids;
            order_details.patient_name = state.edit_menu_modal_patient_name;
            order_details.details = state.edit_menu_modal_details;

            saveOrderDetails(order_details);
        }
    }

    const renderOrder = useMemo(() => {

        const handleLoadMore = (inView: boolean) => {
            if (inView) {    
                if (paginationEnabled && order && order.details && order.details.length){
                    if (order?.details?.length >= (page + 1) * page_size){
                        setPage((prevPage) => (prevPage + 1))
                    }
                    else {
                        setPaginationEnabled(false);
                    }
                }
            }
        }

        return (
            <>
                {order != null && order.details.length > 0 && group_paged_structures != null ?
                    group_keys_selected.map((key) => {
                        return (
                            <div key={key} style={{ overflow: 'hidden' }}>
                                <Typography variant="h2" gutterBottom component="div" sx={{ m: 5, textAlign: "center" }}>
                                    {key}
                                </Typography>
                                <Grid container spacing={5}>
                                    {
                                        group_paged_structures[key].map((item: Models.OrderDetails) => {
                                            return (
                                                <Grid size={{xs:12}} key={item.structure_id}>
                                                    <EditOrderItem
                                                        key={item.structure_id}
                                                        order={order}
                                                        item={item}
                                                        multiple_select={multipleSelect}
                                                        onOpenEditMenuDialog={handleOpenOrderDetailsModal}
                                                        menuTypeList={menuTypeList!}
                                                        menuTypeListWithDefaultValue={menuTypeListWithDefaultValue!}
                                                        menuTypeSuggestions={menuTypeSuggestions}
                                                    />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </div>
                        )

                    })
                    :
                    <Grid size={{xs: 12}}>
                        Client structure is empty. Please define the structure in menu 'Client Structure'
                    </Grid>
                }
                
                <InView as="div" onChange={(inView, entry) => handleLoadMore(inView)}>
                    <Button
                        color="primary"
                        variant="contained"                        
                        onClick={e => handleLoadMore(true)}
                        disabled={!paginationEnabled || (filterStructure !== null && filterStructure.length > 0)}>
                        Load more
                    </Button>
                </InView>
            </>
        )}
        , [order, group_paged_structures, group_keys_selected, multipleSelect, handleOpenOrderDetailsModal, menuTypeList, menuTypeListWithDefaultValue, paginationEnabled, page, filterStructure, menuTypeSuggestions]);

    return (
        <React.Fragment>

            {/* START DIALOG **/}
            <Dialog
                onClose={handleCloseViewDishModal}
                aria-labelledby="customized-dialog-title-view-dish"
                open={state.view_dish_show_modal}
                fullWidth={true}
                maxWidth={"lg"}
            >
                <DialogTitle id="customized-dialog-title-view-dish" onClose={handleCloseViewDishModal}>
                    {state.view_dish_modal_title}
                </DialogTitle>
                <DialogContent dividers>
                    <ViewDish
                        dish_id={state.view_dish_id}
                        order_id={order?.order_id}
                        structure_id={state.view_dish_structure_id}
                        menu_id={state.view_menu_id}
                        onCloseCallback={handleCloseViewDishModal}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                onClose={handleCloseOrderDetailsModal}
                aria-labelledby="customized-dialog-title"
                open={state.edit_menu_modal_show}
                disableRestoreFocus

            >
                <DialogTitle id="customized-dialog-title" onClose={handleCloseOrderDetailsModal}>
                    {state.edit_menu_modal_title}
                    {state.edit_menu_modal_mode === 2 ?
                        <Tooltip
                            title={
                                <div style={{ whiteSpace: 'pre-line' }}>
                                    {selectedStrucuresNames.join('\n')}
                                </div>
                            }
                        >
                            <InfoIcon/>
                        </Tooltip>
                        :
                        null
                    }
                </DialogTitle>

                <DialogContent dividers>

                    <div style={{ padding: 20 }}>

                        <Grid container spacing={2}>
                            <Grid size={{xs: 6, md: 4}}>
                                <InputLabel id="labelMenuTypeId">
                                    Menu Type
                                </InputLabel>
                                <Select
                                    id="MenuTypeId"
                                    labelId="labelMenuTypeId"
                                    value={menuTypeListWithDefaultValue ? state.edit_menu_modal_menu_type_id: ''}
                                    input={<Input />}
                                    MenuProps={utils.MenuProps}
                                    onChange={handleChangeMenuType}
                                    sx = {{ maxWidth: 'calc(100% - 2px)' }}
                                >
                                    {menuTypeListWithDefaultValue?.map(o =>
                                        <MenuItem key={o.menu_type_id} value={o.menu_type_id}>{o.name}</MenuItem>
                                    )}
                                </Select>
                            </Grid>

                            <Grid size={{xs: 6, md: 4}}>
                                <InputLabel id="labelMenuTypeId">
                                    Patient Name
                                </InputLabel>
                                <TextField
                                    name="edit_menu_modal_patient_name"
                                    variant="standard"
                                    value={state.edit_menu_modal_patient_name}
                                    onChange={changeState}
                                    required
                                    />
                            </Grid>
                            <Grid size={{xs: 12, md: 4}}>
                                <InputLabel id="labelMenuTypeId">
                                    Details
                                </InputLabel>
                                <TextareaAutosize
                                    name="edit_menu_modal_details"
                                    minRows={2}
                                    style={{ width: "100%" }}
                                    className="textarea"
                                    value={state.edit_menu_modal_details}
                                    onChange={changeState}
                                    required
                                />
                            </Grid>

                            {/** start menus */}
                            <Grid size={{xs: 12}}>
                                <Grid container spacing={1} style={{ paddingTop: 20 }}>
                                    <Grid size={{xs: 12}}>
                                        {(menus != null && menus.length > 0 ?
                                            menus.map(menu => {
                                                var menu_item = state.edit_menu_modal_menu_ids.find(x => x.menu_id === menu.menu_id);
                                                return (
                                                    <div key={menu.menu_id}>
                                                        <Grid container>
                                                            <Grid size={{xs: 12}}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox checked={menu_item != null}
                                                                            onChange={(event) => handleSelectMenu(event, menu.menu_id)}
                                                                            value="true" />
                                                                    }
                                                                    disableTypography
                                                                    label={
                                                                        <Typography style={{overflowWrap: 'anywhere'}}>{menu.title}</Typography>
                                                                    }
                                                                />

                                                                <TextField
                                                                    id="Qty"
                                                                    name="Qty"
                                                                    variant="standard"
                                                                    type="number"
                                                                    label="Qty"
                                                                    value={menu_item != null ? menu_item.qty : 0}
                                                                    onChange={(event) => handleChangeQty(event, menu.menu_id)}
                                                                    disabled={menu_item == null || !order?.allow_multiple_menus}
                                                                    required
                                                                />
                                                            </Grid>

                                                            <Grid container direction="row" size={{xs: 12}} >
                                                                {mealsList?.map(meal =>{
                                                                    return order?.meal_id === 1 || order?.meal_id === meal.meal_id ? 
                                                                        <Grid size={{xs: 12, md: 4}} key={meal.meal_id}>
                                                                            <List sx={{ width: '100%', maxWidth: 560 }}>
                                                                                <Divider />
                                                                                <ListItem key={0}>
                                                                                    <ListItemIcon>
                                                                                        <AccessTimeIcon color="action" />
                                                                                    </ListItemIcon>
                                                                                    <ListItemText
                                                                                        primary={meal.description}
                                                                                    />
                                                                                </ListItem>
                                                                                <Divider />
                                                                                {menu.dishes.filter(d => d.data.meal_id === meal.meal_id).map(dish =>
                                                                                    <MealMenuItem
                                                                                        key={dish.data.dish_id}
                                                                                        dish={dish}
                                                                                        disabled = {!dish.data.enabled}
                                                                                        menu_id={menu.menu_id}
                                                                                        structure_id={state.edit_menu_modal_structure_id}
                                                                                        onViewDishFromOrderCallback={handleViewDishFromOrder}
                                                                                    />
                                                                                )}
                                                                            </List>
                                                                        </Grid>
                                                                        :
                                                                        null
                                                                }
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )
                                            }
                                            )
                                            :
                                            <Grid size={{xs: 3}}>
                                                <h6 id="tabelLabel">Menu is empty, please edit the menu</h6>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/** end menus */}
                        </Grid>
                    </div>

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseOrderDetailsModal}>
                        Close
                    </Button>
                    <Button color="primary" onClick={handleSaveOrderDetailsModal}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                onClose={handleCloseStatusModal}
                aria-labelledby="customized-dialog-title-status"
                open={state.status_show_status_modal}
                fullWidth={true}
                maxWidth={"xl"}
            >
                <DialogTitle id="customized-dialog-title-status" onClose={handleCloseStatusModal}>
                    {state.status_description}
                </DialogTitle>
                <DialogContent dividers>
                    <div>
                        {group_all_structures &&
                            keys_all_structures.map((key) => {
                            return (
                                <div key={key}>
                                    <Typography variant="h2" gutterBottom component="div" sx={{ m: 5, textAlign: "center" }}>
                                        {key}
                                    </Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow key={0}>
                                                <TableCell>
                                                    Status
                                                </TableCell>
                                                <TableCell>
                                                    Name
                                                </TableCell>
                                                <TableCell>
                                                    Menu Type
                                                </TableCell>
                                                <TableCell>
                                                    Menu
                                                </TableCell>
                                                <TableCell>
                                                    Reversal
                                                </TableCell>
                                                <TableCell>
                                                    Patient Name
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>                                        
                                            {group_all_structures[key].map((item: Models.OrderDetails)  => (
                                                <TableRow
                                                    hover
                                                    key={item.order_id.toString() + "_" + item.structure_id.toString()}
                                                >
                                                    <TableCell>
                                                        {item.display_status === 1 ?
                                                            <LocalHotelOutlinedIcon
                                                                style={{
                                                                    color: green[700],
                                                                }}
                                                            />
                                                            : <LocalHotelOutlinedIcon
                                                                style={{
                                                                    color: red[700],
                                                                }}
                                                            />}

                                                        {order?.type === 2 ?
                                                            item.updated ?
                                                                <EditIcon/>
                                                                :
                                                                <EditOffIcon/>

                                                            : null}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.name}                                                            
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.menu_type}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.menu}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.menu_reversal}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.patient_name}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            )})
                        }
                        <Box sx={{ textAlign: "center", mt: 2 }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={(e) => handleChangeStatus(state.status_value, state.status_description)}>
                                {state.status_description}
                            </Button>
                        </Box>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                onClose={handleCloseSettingsModal}
                aria-labelledby="customized-dialog-title-settings"
                fullWidth={true}
                disableRestoreFocus
                open={state.status_show_settings_modal}
                PaperProps={{
                    sx: {
                      width: '100%',
                      margin: '5px'
                    },
                }}

            >
                <DialogTitle id="customized-dialog-title-settings" onClose={handleCloseSettingsModal}>
                    Settings
                </DialogTitle>
                <DialogContent dividers>                    
                    <Grid container
                        justifyContent='center'
                        style={{ paddingBottom: 30 }}
                        spacing={1}>
                            <Grid size={{xs: 12}}>
                                <ToggleButton
                                    value="check"
                                    selected={multipleSelect}
                                    disabled={order?.status !== 1}
                                    onChange={() => {
                                        setMultipleSelect((s) => (!s));
                                    }}
                                    >
                                    {multipleSelect ? <HighlightOffIcon/> : <AddCircleOutlineIcon />}
                                    
                                </ToggleButton>

                                <ToggleButton
                                    value="check"
                                    selected={false}
                                    disabled={!multipleSelect || selectedStructures.length === 0 || order?.status !== 1}
                                    onChange={(e) => {
                                        handleEditMultipleMenu(e);
                                    }}
                                    >
                                    <Badge color="secondary" badgeContent={selectedStructures.length}>
                                        <EditLocationAltIcon />
                                    </Badge>
                                </ToggleButton>
                            </Grid>
                            <Grid size={{xs: 12}}>
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    options={keys_all_structures}
                                    getOptionLabel={(option) => option}                                
                                    onChange={(event, value) => {handleFilterStructure(value)}}
                                    value={filterStructure}                                    
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Filter Structure"
                                            placeholder="Select structure"
                                            variant="standard"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                              }}
                                        />
                                        )}
                                />
                            </Grid>

                            <Grid size={{xs: 12}}>
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    paddingTop={'30px'}
                                >
                                    {order != null && order.next_status.length > 0 ?
                                        order.next_status.map((item) => (                                            
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                key={item.status}
                                                sx = {{ marginLeft: '5px', marginRight: '5px'}}
                                                onClick={e => handleShowStatusModal(item.description, item.status)}>
                                                {item.description}
                                            </Button>
                                        ))
                                        :
                                        null
                                    }
                                </Box>
                            </Grid>

                            <Grid size={{xs: 12}}>
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    paddingTop={'30px'}
                                >
                                    {order != null && order.fill.length > 0 ?
                                        order.fill.map((item) => (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                key={item.date}
                                                onClick={e => handleFill(item.date, item.description)}>
                                                {item.description}
                                            </Button>                                    
                                        ))
                                        :
                                        null
                                    }
                                </Box>
                            </Grid>
                            <Grid size={{xs: 12}}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={menuTypeSuggestions}
                                            onChange={(e) => {
                                                setMenuTypeSuggestions(e.target.checked);
                                                localStorage.setItem('order-menu-type-suggestions', (e.target.checked).toString());
                                            }}
                                            name='menu-type-sugestions'                                            
                                        />
                                    }
                                    label='Menu type selection with search suggestions'
                                />
                            </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSettingsModal}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {/* END DIALOG **/}

            
            {order &&
                <>
                    {/* START HEADER **/}
                    <Box 
                        ref={measuredHeaderRef}
                        style={{ overflow: 'hidden'}}
                    >
                        <Box sx={{ textAlign: "center", mb: 2 }}>
                            <h2>{`Order: ${order?.order_id ?? ''} Client: ${order?.client_name ?? ''}`}</h2>
                        </Box>
                        
                        <Grid container spacing={5}>
                            <Grid size={{xs: 6, sm: 3}}>
                                <h4>{"Date: " + (order?.order_date ? moment(order?.order_date).format('DD.MM.YYYY'): '')}</h4>
                            </Grid>
                            <Grid size={{xs: 6, sm: 3}}>
                                <h4>{`Meal: ${order?.meal_description ?? ''}`}</h4>
                            </Grid>
                            <Grid size={{xs: 6, sm: 3}}>
                                <h4>{`Type: ${order?.type_description ?? ''}`}</h4>
                                {order?.primary_order_id ?
                                    <Link component="button"
                                        variant="body2"
                                        onClick={() => {
                                            navigate("/order/edit/" + order?.primary_order_id, { replace: true });
                                        }}>
                                        {"Primary order id: " + order?.primary_order_id}
                                    </Link>
                                    :
                                    null
                                }
                            </Grid>
                            <Grid size={{xs: 6, sm: 3}}>
                                <Chip label={`Status: ${order.status_description}`} sx={getStatusColor(order.status)} variant="outlined" />
                            </Grid>
                        </Grid>
                    </Box>
                    {/* END HEADER **/}

                    {/* START SPEED DIAL (apare doar pe mobil) **/}
                    <Box sx={{ 
                        display: { xs: 'block', sm: 'none' },
                        position: 'fixed', 
                        bottom: theme.spacing(2),
                        right: theme.spacing(2),
                        transform: 'translateZ(0px)', 
                        flexGrow: 1,
                        zIndex: 999,
                    }}>
                        <SpeedDial
                            ariaLabel="SpeedDial controlled open example"
                            sx={{ position: 'absolute', bottom: 16, right: 16 }}
                            icon={<SpeedDialIcon />}
                            onClose={handleCloseSpeedDial}
                            onOpen={handleOpenSpeedDial}
                            open={openSpeedDial}
                        >
                            <SpeedDialAction
                                icon={<SettingsIcon />}
                                tooltipTitle={'Settings'}
                                onClick={(e) => {
                                    setState((prevState) => ({
                                        ...prevState, 
                                        status_show_settings_modal: true,
                                    }));
                                    handleCloseSpeedDial();
                                }}
                            />

                            <SpeedDialAction
                                icon={<FilterAltIcon />}
                                tooltipTitle={'Filter'}
                                onClick={(e) => {                                    
                                    setShowFilterSpeedDial((s) => {
                                        localStorage.setItem('order-show-filter', (!s).toString());
                                        return !s;
                                    });
                                    handleCloseSpeedDial();
                                }}
                            />

                            <SpeedDialAction
                                icon={<Badge color="secondary" badgeContent={selectedStructures.length}>
                                        <EditLocationAltIcon />
                                    </Badge>}
                                tooltipTitle={'Edit multiple menus'}
                                FabProps={{disabled: !multipleSelect || selectedStructures.length === 0 || order?.status !== 1}}
                                onClick={(e) => {
                                    handleEditMultipleMenu(e);
                                    handleCloseSpeedDial();
                                }}
                            />
                                    
                            <SpeedDialAction
                                icon={multipleSelect ? <HighlightOffIcon/> : <AddCircleOutlineIcon />}
                                tooltipTitle={'Multiple select'}
                                FabProps={{disabled: order?.status !== 1}}
                                onClick={(e) => {
                                    setMultipleSelect((s) => (!s));
                                    handleCloseSpeedDial();
                                }}
                            />
                        </SpeedDial>
                    </Box>
                    {/* END SPEED DIAL **/}

                    {/* START SETTINGS **/}
                    <Box 
                        sx={{ 
                            display: { xs: showFilterSpeedDial ? 'block' : 'none', sm: 'block' },
                            zIndex: 999,
                            padding: 1,
                            position: 'sticky',
                            top: '60px',
                            bgcolor: theme.palette.background.default,
                        }}>
                        <Grid container
                            justifyContent='center'
                            style={{ paddingBottom: 30 }}
                            spacing={1}>
                                <Grid size={{xs: 12, sm: 2}}>
                                    <ToggleButton
                                        value="check"
                                        selected={multipleSelect}
                                        disabled={order?.status !== 1}
                                        onChange={() => {
                                            setMultipleSelect((s) => (!s));
                                        }}
                                        >
                                        {multipleSelect ? <HighlightOffIcon/> : <AddCircleOutlineIcon />}
                                    </ToggleButton>

                                    <ToggleButton
                                        value="check"
                                        selected={false}
                                        disabled={!multipleSelect || selectedStructures.length === 0 || order?.status !== 1}
                                        onChange={(e) => {
                                            handleEditMultipleMenu(e);
                                        }}
                                        >
                                        <Badge color="secondary" badgeContent={selectedStructures.length}>
                                                <EditLocationAltIcon />
                                        </Badge>
                                    </ToggleButton>

                                    <ToggleButton
                                        value="check"
                                        selected={paginationEnabled}
                                        onChange={() => {
                                            setPaginationEnabled((s) => {
                                                localStorage.setItem('order-pagination-enabled', (!s).toString());
                                                return !s;
                                            });
                                        }}
                                        >
                                        <AutoStoriesIcon/>
                                    </ToggleButton>

                                    <ToggleButton
                                        value="check"
                                        selected={false}
                                        onChange={() => {
                                            setState((prevState) => ({
                                                ...prevState, 
                                                status_show_settings_modal: true,
                                            }));
                                        }}
                                        >
                                        <SettingsIcon/>
                                    </ToggleButton>

                                </Grid>
                                <Grid size={{xs: 12, sm:6}}>
                                    {/* trebuie specificat explicit max width pt ca pe mobil daca textul este lung apare overflow;
                                    nu merge varianta cu 'calc': sx={{ maxWidth: 'calc(100% - 16px)', }}
                                        */}
                                    <Box maxWidth={lessThanSmall ? windowDimensions.width  * 10 / 12: windowDimensions.width * 5 / 12}>
                                        <Autocomplete
                                            multiple
                                            id="tags-standard"
                                            options={keys_all_structures}
                                            getOptionLabel={(option) => option}                                
                                            onChange={(event, value) => {handleFilterStructure(value)}}
                                            value={filterStructure}                                           
                                            
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Filter Structure"
                                                    placeholder="Select structure"
                                                    variant="standard"
                                                />
                                            )}
                                        />
                                    </Box>
                                </Grid>                                

                        </Grid>
                    </Box>
                    
                    {/* END SETTINGS (nu apare pe mobil) **/}
                </>
            }
            
            {/* START CLIENT STRUCTURE **/}
            {isLoading || isLoadingMenuList || isLoadingMenuListWithDefaultValue ? /* doar la prima incarcare afisam "loading"; la celelalte incarcari (de ex cand s-a salvat un meniu pt un pat nu mai afisam "loading" pt ca ecranul flickareste) */
                <p><em>Loading order...</em></p> :
                <div 
                    style={{                
                        paddingTop: '30px',
                    }}
                >
                    {renderOrder}
                </div>
            }
            {/* END CLIENT STRUCTURE **/}
        </React.Fragment>
    );
}

export default EditOrder;