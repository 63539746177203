import './css/custom.css'
import * as React from 'react';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';


import CookieConsent from "react-cookie-consent";
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import authService from './components/api-authorization/AuthorizeService';
import { ApplicationPaths, LoginActions, LogoutActions } from './components/api-authorization/ApiAuthorizationConstants';
import * as Models from './models/Models';
import { RootState, useAppDispatch } from './store/configureStore';
import { setError } from './store/ApiInterface';
import { setOnline, setAuthState } from './store/App';

import Navigation from './components/Navigation';
import ApiInterface from './components/ApiInterface';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Login } from './components/api-authorization/Login'
import { Logout } from './components/api-authorization/Logout'
import { Home } from './components/Home';
import FetchUsers from './components/FetchUsers';
import EditUser from './components/EditUser';
import FetchClients from './components/FetchClients';
import EditClient from './components/EditClient';
import FetchCompanies from './components/FetchCompanies';
import EditCompany from './components/EditCompany';
import FetchMenuTypes from './components/FetchMenuTypes';
import EditMenuType from './components/EditMenuType';
import FetchIngredients from './components/FetchIngredients';
import EditIngredient from './components/EditIngredient';
import FetchDishes from './components/FetchDishes';
import EditDish from './components/EditDish';
import FetchMenus from './components/FetchMenus';
import EditMenu from './components/EditMenu';
import ClientsMenu from './components/ClientsMenu';
import ClientStructure from './components/ClientStructure';
import Scheduler from './components/Scheduler';
import FetchOrders from './components/FetchOrders';
import CreateOrder from './components/CreateOrder';
import EditOrder from './components/EditOrder';
import Reports from './components/Reports';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';

import { checkTokenValidTryRefreshIfNot, parseAxiosError, useAxiosUtil } from './utils/axiosUtils';
import * as utils from './utils/Utils';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import theme from './theme';
import DashboardLayout from './components/dashboard/DashboardLayout';
import Account from './pages/Account';
import CustomerList from './pages/CustomerList';
import Dashboard2 from './pages/Dashboard';
import ProductList from './pages/ProductList';
import MobileApp from './components/landing/views/MobileApp';
import CompanyTermsView from './components/landing/views/CompanyTerms';
import ContactPageView from './components/landing/views/ContactPage';


import 'aos/dist/aos.css';
import Page from './components/landing/components/Page';


export const App : React.FC = (props) => {
    const _subscription = useRef<number>(0);

    const storeState = useSelector((state: RootState) => state.App)
    
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { axios_get } = useAxiosUtil();

    const [state, setState] = useState<Models.AuthState | null>(null);

    const populateState = useCallback((event: string) => {

        if (storeState.online &&
            location.pathname !== ApplicationPaths.LoginCallback && //la login inca nu este autentificat si da eroare /UserInfo
            location.pathname !== ApplicationPaths.LogOut //la logout da eroare /UserInfo pt ca token-ul este invalid
            ){

            Promise.all([
                authService.isAuthenticated(),
                authService.getUser(),
            ])
                .then(([isAuthenticated, user]) => {

                    if (isAuthenticated) {                        
                        
                        checkTokenValidTryRefreshIfNot().then(token_valid => {
                            if (!token_valid) {
                                // daca token-ul este invalid apelam totusi API-ul; 
                                // acopera cazul in care aplicatia este offline si nu se poate reinnoi token-ul
                                // daca o sa returneze 401 atunci facem redirect la logout prin "parseAxiosError(e)"
                                console.log('token not valid (2)'); 
                                
                            }

                            Promise.all([
                                axios_get('api/UsersInfo')
                                    .then(result => {
                                        return result;
                                    }),
                                authService.getAccessToken()
                                    .then(token => {
                                        return token;
                                    })
                            ]).then(([result, token]) => {
                                const userInfo = result as Models.UserInfo;

                                var roles = [];

                                if (token != null) {
                                    var jsonObject = utils.decodeTokenAsJson(token);
                                    var jsonRolesObject = jsonObject['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

                                    if (isArray(jsonRolesObject))
                                        for (const role of jsonRolesObject) {
                                            roles.push(role)
                                        }
                                    else
                                        roles.push(jsonRolesObject)
                                    
                                }

                                setState({
                                    isAuthenticated: isAuthenticated,
                                    full_name: userInfo && userInfo.full_name,
                                    work_place: userInfo && userInfo.work_place,
                                    profile_picture_base64: userInfo && userInfo.profile_picture,
                                    roles: roles,
                                    force_change_password: userInfo && userInfo.force_change_password,
                                });
                            })
                            .catch(function (e) {
                                //inlocuim navigate to logout cu parseAxiosError - doar daca e eroare 401 facem redirect la logout
                                //pt ca se poate intampla ca aplicatia sa fie offline (de ex la o actualizare de aplicatie) la momentul cand se apeleaza UserInfo - caz in care returneaza 502 - in acest caz nu trebuie redirect la logout ci asteptat pana aplicatia revine online
                                //navigate(ApplicationPaths.LogOut);
                                parseAxiosError(e);
                                dispatch(setError(e))
                                console.log(e);
                            });
                            
                        })
                    }
                    else {
                        setState({
                            isAuthenticated: false,
                            full_name: "",
                            work_place: "",
                            profile_picture_base64: "",
                            roles: [],
                            force_change_password: false,
                        });
                    }
                });

        }
    }, [axios_get, location.pathname, dispatch, storeState.online])

    useEffect(() => {
        if (!storeState.online){
            navigate('/')
        }
    
    }, [storeState.online, navigate]);

    useEffect(() => {
        //facem o copie in redux ca sa putem folosi info de autentificare usor in restul aplicatiei
        if (state){
            dispatch(setAuthState(state))
        }
    
    }, [state, dispatch]);

    useEffect(() => {
        _subscription.current = authService.subscribe(() => { populateState('populateState/subscribe'); });
        populateState('populateState/useEffect');
        
        return () => {
            // cleanun state mapped to props            
            authService.unsubscribe(_subscription.current);
        };
    
    }, [populateState]);

    
    // Scroll to top if path changes
    useLayoutEffect(() => {
        //cand navigheaza la una din paginile de mai jos facem scroll top pt ca daca foloseste link-ul din footer o sa pastreze pozitia scrool-ului din pagina anterioara (adica jos si cand deschide pagina nou)
        if (location.pathname === '/' || 
            location.pathname === '/contact' ||
            location.pathname === '/terms'){
            window.scrollTo(0, 0);
        }
    }, [location.pathname]);
    

    const setOnlineState = useCallback(() => {
        dispatch(setOnline(true))
    }, [dispatch])

    const setOfflineState = useCallback(() => {
        dispatch(setOnline(false))
    }, [dispatch])

    useEffect(() => {
        window.addEventListener('online', setOnlineState);
        window.addEventListener('offline', setOfflineState);

        return () => {
            window.removeEventListener('online', setOnlineState);
            window.removeEventListener('offline', setOfflineState);
        };
    }, [setOnlineState, setOfflineState]);

    //https://stackoverflow.com/questions/951483/how-to-check-if-a-json-response-element-is-an-array
    const isArray = (what: any) => {
        return Object.prototype.toString.call(what) === '[object Array]';
    }
    

    const onPasswordChangedCallback = () => {
        populateState('password changed')
    }

    return (        
        <React.Fragment>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ApiInterface />
                    <Navigation />
                    <ErrorBoundary>
                        
                        <Routes>
                            <Route path={ApplicationPaths.Login} element={loginAction(LoginActions.Login)} />
                            <Route path={ApplicationPaths.LoginFailed} element={loginAction(LoginActions.LoginFailed)} />
                            <Route path={ApplicationPaths.LoginCallback} element={loginAction(LoginActions.LoginCallback)} />
                            <Route path={ApplicationPaths.Profile} element={loginAction(LoginActions.Profile)} />
                            <Route path={ApplicationPaths.Register} element={loginAction(LoginActions.Register)} />
                            <Route path={ApplicationPaths.LogOut} element={logoutAction(LogoutActions.Logout)} />
                            <Route path={ApplicationPaths.LogOutCallback} element={logoutAction(LogoutActions.LogoutCallback)} />
                            <Route path={ApplicationPaths.LoggedOut} element={logoutAction(LogoutActions.LoggedOut)} />

                            <Route path={'terms'} element={
                                        <Page>
                                            <CompanyTermsView />
                                        </Page>} />

                            <Route path={'contact'} element={
                                        <Page>
                                            <ContactPageView />
                                        </Page>} />
                                        
                            
                            {storeState.online && state?.isAuthenticated ?
                            <Route path='/' 
                                element={<DashboardLayout 
                                            auth={state} 
                                            onPasswordChangedCallback={onPasswordChangedCallback}/>
                                        }>                               

                                <Route path="/" element={
                                    <Home />
                                } />

                                <Route path="/dishes" element={
                                    <AuthorizeRoute component={<FetchDishes />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/dishes/edit/:id" element={
                                    <AuthorizeRoute component={<EditDish />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/ingredients" element={
                                    <AuthorizeRoute component={<FetchIngredients />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/ingredient/edit/:id" element={
                                    <AuthorizeRoute component={<EditIngredient />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/menus" element={
                                    <AuthorizeRoute component={<FetchMenus />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/menus/edit/:id" element={
                                    <AuthorizeRoute component={<EditMenu />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/clients" element={
                                    <AuthorizeRoute component={<FetchClients />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/client/edit/:id" element={
                                    <AuthorizeRoute component={<EditClient />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/menutypes" element={
                                    <AuthorizeRoute component={<FetchMenuTypes />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/menutypes/edit/:id" element={
                                    <AuthorizeRoute component={<EditMenuType />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/companies" element={
                                    <AuthorizeRoute component={<FetchCompanies />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/company/edit/:id" element={
                                    <AuthorizeRoute component={<EditCompany />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/settings" element={
                                    <AuthorizeRoute component={<Settings />}>
                                    </AuthorizeRoute>
                                } />                                    

                                <Route path="/fetchusers" element={
                                    <AuthorizeRoute component={<FetchUsers />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/user/edit/:id" element={
                                    <AuthorizeRoute component={<EditUser />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/clientsmenu" element={
                                    <AuthorizeRoute component={<ClientsMenu />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/clientstructure" element={
                                    <AuthorizeRoute component={<ClientStructure />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/scheduler" element={
                                    <AuthorizeRoute component={<Scheduler />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/order/edit/:id" element={
                                    <AuthorizeRoute component={<EditOrder />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/order/create" element={
                                    <AuthorizeRoute component={<CreateOrder />}>
                                    </AuthorizeRoute>
                                } />



                                <Route path="/orders" element={
                                    <AuthorizeRoute component={<FetchOrders />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/reports" element={
                                    <AuthorizeRoute component={<Reports />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/dashboard" element={
                                    <AuthorizeRoute component={<Dashboard />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/app/account" element={
                                    <AuthorizeRoute component={<Account />}>
                                    </AuthorizeRoute>
                                } />
                                <Route path="/app/customers" element={
                                    <AuthorizeRoute component={<CustomerList />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/app/dashboard" element={
                                    <AuthorizeRoute component={<Dashboard2 />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/app/products" element={
                                    <AuthorizeRoute component={<ProductList />}>
                                    </AuthorizeRoute>
                                } />

                                <Route path="/app/settings" element={
                                    <AuthorizeRoute component={<Settings />}>
                                    </AuthorizeRoute>
                                } />
                            </Route>
                            :
                                <Route path="/" element={
                                    state && // afisam doar daca state-ul este populat ca sa evitam flicker-ul cand userul este autentificat 
                                             // intaii este afisata HomeUnauth apoi Home pt ca popularea state-ului este asincron
                                             // intaii il vede ca neautentificat, apoi dupa ce se executa "populateState" va fi vazut ca autentificat
                                        <Page>
                                            <MobileApp />
                                        </Page>
                                } />
                            }
                        </Routes>
                    </ErrorBoundary>
                </ThemeProvider>
            </StyledEngineProvider>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="consent"
                style={{ background: "#2B373B", zIndex: "2000" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>

        </React.Fragment>
    );
    
}

function loginAction(name: string) {
    return (<Login action={name}></Login>);
}

function logoutAction(name: string) {
    return (<Logout action={name}></Logout>);
}

export default App;
