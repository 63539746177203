import { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import ForceChangePassword from '../ForceChangePassword';
import * as Models from '../../models/Models';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import { useLocation } from "react-router-dom";

const DashboardLayoutRoot = styled('div')<{ overflowVisible: boolean }>(
    ({ theme, overflowVisible }) => ({
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        height: '100%',
        overflow: overflowVisible ? 'visible' : 'hidden',
        width: '100%'
    })
);

const DashboardLayoutWrapper = styled('div')<{ overflowVisible: boolean }>(
    ({ theme, overflowVisible }) => ({
        display: 'flex',
        flex: '1 1 auto',
        overflow: overflowVisible ? 'visible' : 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    })
);

const DashboardLayoutContainer = styled('div')<{ overflowVisible: boolean }>(
    ({ overflowVisible }) => ({
        display: 'flex',
        flex: '1 1 auto',
        overflow: overflowVisible ? 'visible' : 'hidden',
    })
);

const DashboardLayoutContent = styled('div')<{ overflowVisible: boolean }>(
    ({ overflowVisible }) => ({
        flex: '1 1 auto',
        height: '100%',
        overflow: overflowVisible ? 'visible' : 'auto',
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
    })
);

interface Props {  
    auth: Models.AuthState;
    onPasswordChangedCallback(): void;
  }

const DashboardLayout: React.FC<Props> = ({ auth, onPasswordChangedCallback }) => {

    const location = useLocation();

    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    //in edit order avem filtrul care este in css cu position=stiky
    //position=stiky nu functioneaza decat in componente cu overflow=visible
    //prin urmare lasam doar in edit order tema cu overflow=visible
    //in rest pastram overflow=hidden (in DashboardLayoutContent overflow=auto) pt ca in acest fel aranjarea in pagina este buna; in edit order am facut sa fie buna fara aceste setari
    const overflowVisible = location.pathname.startsWith('/order/edit') 

    

    //folosim useCallback pt ca mobileCloseNav este folosit in DashboardSidebar in useEffect cand se schimba locatia;
    //daca se schimba mobileCloseNav atunci useEffect din DashboardSidebar se declanseaza (ca si cand location ar fi fost schimbat) si inchide meniul
    //cum era inaine "onMobileNavOpen={() => setMobileNavOpen(false)}" functia din argument se schimba la fiecare re-render si de aceea useEffect din DashboardSidebar declansa 
    const mobileCloseNav = useCallback(() =>{        
        setMobileNavOpen((open) => open ? false: open);
    }, [])

    const mobileOpenNav = useCallback(() =>{
        setMobileNavOpen(true);
    }, [])

    return (
        <DashboardLayoutRoot overflowVisible={overflowVisible}>
            <DashboardNavbar
                onMobileNavOpen={mobileOpenNav}
                auth={auth}
                 />
            <DashboardSidebar
                onMobileClose={mobileCloseNav}
                openMobile={isMobileNavOpen}
                auth={ auth }
            />            
            <DashboardLayoutWrapper overflowVisible={overflowVisible}>
                <DashboardLayoutContainer overflowVisible={overflowVisible}>
                    <DashboardLayoutContent overflowVisible={overflowVisible}>
                        {auth.force_change_password && location.pathname !== ApplicationPaths.LogOutCallback && location.pathname !== ApplicationPaths.LogOut? 
                        <ForceChangePassword onPasswordChangedCallback = {onPasswordChangedCallback} />
                        :
                        <Outlet />
                        }
                    </DashboardLayoutContent>
                </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
    );
};

export default DashboardLayout;
