import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ClientStructureState {
    formData: FormData;
}

export class FormData {    
    client_id: number = 0;
}

const unloadedState: ClientStructureState = {    
    formData: {
        client_id: 0,
    }
};

/* new reducer */

const clientStructureSlice = createSlice({
    name: 'ClientStructure',
    initialState: unloadedState,
    reducers: {
      setClientsStructureFormData(state, action: PayloadAction<{client_id: number}>) {
        state.formData = {            
            ...unloadedState.formData,
            client_id: action.payload.client_id
        }
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = clientStructureSlice
  // Extract and export each action creator by name
  export const { setClientsStructureFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer