import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as Models from "../models/Models";
import * as Validator from "../validator/Validator";
import { 
    Box, 
    Select, 
    Grid, 
    TextField, 
    Button, 
    MenuItem, 
    Input, 
    InputLabel, 
    Switch, 
    FormControlLabel, 
    Checkbox, 
    Tooltip 
} from '@mui/material';

import { Info as InfoIcon } from '@mui/icons-material';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import { useChangeState } from '../utils/useChangeState';
import { 
    useGetListsWithDefaultValueQuery,
    useGetListClientsQuery, 
    useGetListCompaniesQuery,     
    useGetUserQuery,
    useSaveUserMutation,
    useSaveUserChangePasswordMutation, } 
from '../store/apiSlice';
import { setError } from '../store/ApiInterface';
import { useAppDispatch } from '../store/configureStore';


export const EditUser : React.FC = (props) => {

    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const id = params.id != null ? parseInt(params.id) : 0;

    const [state, setState] = useState<Models.UserData>({
        ...Models.getUserDataDefaultValues()
    });

    const changeState = useChangeState(state, setState);
    
    const { data: user_type } = useGetListsWithDefaultValueQuery({list_id: '5', default_value: '0', default_description: 'Select the user type'});
    const { data: clients_list } = useGetListClientsQuery({default_value: '0', default_description: 'Select the client', default_mandatory: 'yes'});
    const { data: companies_list } = useGetListCompaniesQuery({default_value: '0', default_description: 'Select the company'});
    const { data } = useGetUserQuery(id);
    const [ saveData ] = useSaveUserMutation();
    const [ changePassword ] = useSaveUserChangePasswordMutation();
    

    useEffect(() => {
        if (data){
            setState((prevState) => ({
                ...data
            }));
        }    
    }, [data]);

    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {            
            saveData(state);
        }
    }
    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/fetchusers");
    }
    const handleChangePassword = (event: any) => {
        event.preventDefault();
        changePassword(state);
    }
    
    const handleCheckRole = (event: any) => {
        var i;
        let roles = [...state.Roles];
        for (i = 0; i < roles.length; i++)
        {
            let role = { ...roles[i] };
            if (role.Value === event.target.value) {
                role.Selected = event.target.checked;
                roles[i] = role;
                break;
            }
        }
        setState({ ...state, Roles: roles });
    }    



    const handleValidation = () => {
        let result = true;
        let err = '';

        var Type = state.Type;
        var Client = state.ClientId;
        var Company = state.CompanyId;

        var FirstName = state.FirstName;
        var LastName = state.LastName;
        var Email = state.Email;
        var PhoneNumber = state.PhoneNumber;

        if (Type === "0") {
            err += "Select user type!\n";
            result = false;
        }
        if (Type === "CLIENT") {

            if (Client === 0) {
                err += "Select user client!\n";
                result = false;
            }

            if (Company !== 0) {
                err += "For client users you can not select company!\n";
                result = false;
            }
        }

        if (Type === "COMPANY") {

            if (Company === 0) {
                err += "Select user company!\n";
                result = false;
            }

            if (Client !== 0) {
                err += "For company users you can not select the client!\n";
                result = false;
            }
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(FirstName)) {
            err += "First Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (FirstName.length <= 0 || FirstName.length > 50) {
            err += "First Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(LastName)) {
            err += "Last Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (LastName.length <= 0 || LastName.length > 50) {
            err += "Last Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isEmail(Email)) {
            err += "Email is not valid!\n";
            result = false;
        }
        if (Email.length <= 0 || Email.length > 100) {
            err += "Email should be between 1 and 100 characters!\n";
            result = false;
        }

        if (!Validator.isNumeric(PhoneNumber)) {
            err += "Phone Number is not valid!\n";
            result = false;
        }
        if (PhoneNumber.length <= 0 || PhoneNumber.length > 20) {
            err += "Phone Number should be between 1 and 20 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err));
        }

        return result;
    }

          
    return (
        <div>
            <h3>User management</h3>
            <hr />
            <div>
                <fieldset>
                    <legend>Details</legend>
                    <form name="formEditUser" id="formEditUser" onSubmit={handleSave}>
                        <Grid container spacing={5}>
                            <Grid item xs={5}>
                                <Grid container spacing={5}>
                                    {state.UserId === 0 ? null :
                                        <Grid item xs={8}>
                                            <TextField
                                                id="UserName"
                                                name="UserName"
                                                variant="standard"
                                                label="User Name"
                                                value={state.UserName}
                                                disabled
                                            />                                        
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="UserEnabled"
                                                        checked={state.UserEnabled}
                                                        onChange={e => changeState(e)}
                                                        color="primary"
                                                    />
                                                }
                                                labelPlacement="end"
                                                label="Enabled"
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <TextField
                                            name="Email"
                                            variant="standard"
                                            label="Email"
                                            value={state.Email}
                                            onChange={changeState}
                                            required
                                            disabled = {state.UserId > 0}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="EmailConfirmed"
                                                    checked={state.EmailConfirmed}
                                                    onChange={e => changeState(e)}
                                                    color="primary"
                                                />
                                            }
                                            labelPlacement="end"
                                            label="Email Confirmed"
                                        />
                                    </Grid>
                                    {state.UserId > 0 ? null :
                                        <>
                                            <Grid item xs={6}>
                                                <TextField
                                                    name="Password1"
                                                    variant="standard"
                                                    label="Password"
                                                    type="password"
                                                    onChange={changeState}
                                                    required />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    name="Password2"
                                                    variant="standard"
                                                    label="Confirm Password"
                                                    type="password"
                                                    onChange={changeState}
                                                    required />
                                            </Grid>
                                        </>

                                    }
                                    <Grid item xs={4}>

                                        <InputLabel shrink id="labelType">
                                            Type
                                        </InputLabel>
                                        <Select
                                            name="Type"
                                            labelId="labelType"
                                            value={user_type? state.Type : ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={e => changeState(e)}
                                        >
                                            {user_type?.map(o =>
                                                <MenuItem key={o.Value} value={o.Value}>{o.Description}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <InputLabel shrink id="labelClientId">
                                            Client
                                        </InputLabel>
                                        <Select
                                            name="ClientId"
                                            labelId="labelClientId"
                                            value={clients_list ? state.ClientId : ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={(e) => changeState(e)}
                                        >
                                            {clients_list?.map(o =>
                                                <MenuItem key={o.client_id} value={o.client_id}>{o.name}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <InputLabel shrink id="labelCompany">
                                            Company
                                        </InputLabel>
                                        <Select
                                            name="CompanyId"
                                            labelId="labelCompany"
                                            value={companies_list ? state.CompanyId : ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={(e) => changeState(e)}
                                        >
                                            {companies_list?.map(o =>
                                                <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="ForceChangePassword"
                                                    checked={state.ForceChangePassword}
                                                    onChange={(e) => changeState(e)}
                                                    color="primary"
                                                />
                                            }
                                            labelPlacement="end"
                                            label="Force change password"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="TwoFactorEnabled"
                                                    checked={state.TwoFactorEnabled}
                                                    onChange={(e) => changeState(e)}
                                                    color="primary"
                                                />
                                            }
                                            labelPlacement="end"
                                            label="Two Factor Enabled"
                                        />
                                        <Tooltip title="Disabling two factor authentication will reset authenticator key and recovery codes. Also the user will be able to login without second factor.">
                                            <InfoIcon/>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="FirstName"
                                            variant="standard"
                                            label="First Name"
                                            value={state.FirstName}
                                            onChange={changeState}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="LastName"
                                            variant="standard"
                                            label="Last Name"
                                            value={state.LastName}
                                            onChange={changeState}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="PhoneNumber"
                                            name="PhoneNumber"
                                            variant="standard"
                                            label="Phone Number"
                                            value={state.PhoneNumber}
                                            onChange={changeState}
                                            required
                                        />

                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    name="PhoneNumberConfirmed"
                                                    checked={state.PhoneNumberConfirmed}
                                                    onChange={(e) => changeState(e)}
                                                    color="primary"
                                                />
                                            }
                                            labelPlacement="end"
                                            label="Phone Number Confirmed"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <fieldset>
                                    <legend>User roles</legend>
                                    <Grid container spacing={1}>
                                    {
                                        state.Roles.map(item => (
                                            <Grid item xs={12} key={item.Value}>
                                                <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                            checked={item.Selected}
                                                            value={item.Value}
                                                            key={item.Value}
                                                            onChange={handleCheckRole}
                                                            name="Roles"
                                                            color="primary"
                                                      />
                                                    }
                                                    label={item.Label}
                                                />
                                            </Grid>
                                        ))
                                        }
                                    </Grid>
                                </fieldset>
                            </Grid>                    
                            <Grid item xs={12}>
                                <Box sx={styled.buttonsBox}>
                                    <Button variant="contained" color="primary" type="submit">
                                        Save
                                    </Button>                                
                                    <Button variant="contained" color="primary" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </fieldset>
                {state.UserId === 0 ? null :
                    <fieldset>
                        <legend>Change password</legend>
                        <form name="formhandleChangePassword" onSubmit={handleChangePassword} >

                            <Grid container spacing={5}>
                                <Grid item xs={4}>
                                    <TextField
                                        name="Password1"
                                        variant="standard"
                                        label="Password"
                                        type="password"
                                        onChange={changeState}
                                        required
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={5}>
                                <Grid item xs={4}>
                                    <TextField
                                        name="Password2"
                                        variant="standard"
                                        label="Confirm Password"
                                        type="password"
                                        onChange={changeState}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={5}>
                                <Grid item xs={4}>
                                    <Button variant="contained" color="primary" type="submit">
                                        Change password
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </fieldset>
                }
            </div>
        </div>)
}

export default EditUser;