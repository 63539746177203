import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import * as DashboardStore from '../store/Dashboard';
import * as Validator from "../validator/Validator";
import { Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend, TooltipItem  } from 'chart.js'
import { Grid, Button, Input, InputLabel, Select, MenuItem, colors, CardContent, Box, Card, CardHeader, Divider } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import * as utils from '../utils/Utils';
import { RootState, useAppDispatch } from '../store/configureStore';
import { useGetCurrentDayQuery, useGetDashboardQuery, useGetListClientsQuery } from '../store/apiSlice';
import { setError } from '../store/ApiInterface';
import { shallowCompare } from '../utils/Utils';


ChartJS.register(ArcElement, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);



export const Dashboard : React.FC = (props) => {   

    const dispatch = useAppDispatch();
    
    const storeState = useSelector((state: RootState) => state.dashboard)
    
    const [state, setState] = useState<DashboardStore.FormData>({
        ...storeState.formData,
    });

    const [searchState, setSearchState] = useState<DashboardStore.FormData>({
        ...storeState.formData
    });

    const { data: currentDay } = useGetCurrentDayQuery();
    const { data: clients } = useGetListClientsQuery({default_value: '0', default_description: 'Select the client'});
    const { data: dashboard, refetch} = useGetDashboardQuery(searchState, { skip: searchState.client_id === 0 || searchState.date === ''});

    useEffect(() => {
        //daca este prima afisare atunci luam start date, end date si clientul din API si declansam search
        if (currentDay){
            if (state.date === ''){ 
                setState((prevState) => ({
                    ...prevState,
                    date: currentDay,                    
                }));
            }
        }
    }, [state.date, currentDay]);    

    

    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            if (shallowCompare(state, searchState)){
                //cazul in care apasa pe "search" fara sa schimbe vreun parametru - fortam refresh
                refetch();
            }
            else {
                setSearchState({ ...state })
            }
        }
    }

    const handleChangeDate = (newValue: Date | null) => {
        setState({ ...state, date: utils.formatDate(newValue) });
    }

    const handleChangeClient = (event: any) => {
        setState({ ...state, client_id: Number(event.target.value) });
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var date = state.date;

        if (!Validator.isDateISO(date)) {
            err += "Date is invalid!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }

        return result;
    }   


    const renderSearchBox = () => {
        return (            
            <div>
                <fieldset>
                    <legend>Dashboard</legend>
                    <Grid container spacing={5}>
                        <Grid item xs={3}>
                            <InputLabel id="labelDatelId">
                                Date
                            </InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    format="dd.MM.yyyy"
                                    value={new Date(state.date)}
                                    onChange={handleChangeDate}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={3}>
                            <InputLabel id="labelClientId">
                                Client
                            </InputLabel>
                            <Select
                                id="ClientId"
                                labelId="labelClientId"
                                value={clients ? state.client_id : ''}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                onChange={handleChangeClient}
                            >
                                {clients?.map(o =>
                                    <MenuItem key={o.client_id} value={o.client_id}>{o.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary" onClick={handleSearch}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </fieldset>
            </div>
        );
    }

    const renderSearchResultTable = () => {

        var datasets1 = new Array(dashboard?.dashboard1.length);
        var labels1: string[] = [];
        for (let i = 0; dashboard && i < dashboard.dashboard1.length; i++) {
            let dataset = {
                backgroundColor: dashboard?.dashboard1[i].dataset_background_color,
                barPercentage: 0.5,
                barThickness: 12,
                borderRadius: 4,
                categoryPercentage: 0.5,
                data: dashboard?.dashboard1[i].dataset.map(a => a.value),
                label: dashboard?.dashboard1[i].dataset_label,
                maxBarThickness: 10,
            };

            datasets1[i] = dataset;
            labels1 = dashboard?.dashboard1[i].dataset.map(a => a.label);
        }

        var data1 = {
            datasets: datasets1,
            labels: labels1,
        };

        var options1 = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    ticks: {
                        stepSize: 1
                    }
                }
            }
        };


        var datasets2 = new Array(dashboard?.dashboard2.length);
        var labels2 : string[] = [];

        for (let i = 0; dashboard && i < dashboard.dashboard2.length; i++) {
            
            let dataset = {
                data: dashboard?.dashboard2[i].dataset.map(a => a.value),
                backgroundColor: dashboard?.dashboard2[i].dataset.map(a => a.background_color),
                borderWidth: 8,
                borderColor: colors.common.white,
                hoverBorderColor: colors.common.white,
                label: dashboard?.dashboard2[i].dataset_label,
                key: dashboard?.dashboard2[i].dataset_label,
            }

            datasets2[i] = dataset;
            labels2 = dashboard?.dashboard2[i].dataset.map(a => a.label)
        }

        
        var options2 = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (item: TooltipItem<"doughnut">): string | string[]{                            
                            return "  " + item.dataset.label + ': ' + item.label;
                        }
                    },
                },
            },
        };

        var data2 = {
            datasets: datasets2,
            labels: labels2,
        };

        return (
            dashboard &&
            <div>
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <Card>
                            <CardHeader title="Orders by menu" />
                            <Divider />
                            <CardContent>
                                <Box
                                    sx={{
                                        height: 400,
                                        position: 'relative'
                                    }}
                                >
                                    {dashboard.dashboard1.length > 0 ?
                                        <Bar
                                            data={data1}
                                            options={options1}
                                        />
                                        :
                                        "Data not available"
                                    }
                                </Box>
                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card>
                            <CardHeader title="Orders by menu type" />
                            <Divider />
                            <CardContent>
                                <Box
                                    sx={{
                                        height: 400,
                                        position: 'relative'
                                    }}
                                >
                                    {dashboard.dashboard2.length > 0 ?
                                        <Doughnut
                                            data={data2}
                                            options={options2}

                                        />
                                        :
                                        "Data not available"
                                    }
                                </Box>
                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>
            </div >
        );
    }

    return (
        <React.Fragment>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    {renderSearchBox()}
                    {renderSearchResultTable()}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Dashboard;