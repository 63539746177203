import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as Validator from "../validator/Validator";
import { Box, Grid, TextField, Button, Switch } from '@mui/material';
import * as styled from './StyledComponents'
import moment from 'moment';
import * as Models from '../models/Models';
import { useChangeState } from '../utils/useChangeState';
import { useGetMenuTypeQuery, useSaveMenuTypeMutation } from '../store/apiSlice';
import { useAppDispatch } from '../store/configureStore';
import { setError } from '../store/ApiInterface';


export const EditMenuType : React.FC = (props) => {

    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const id = params.id != null ? parseInt(params.id) : 0;
    const [state, setState] = useState<Models.MenuType>({
        ...Models.getMenuTypeDefaultValues(),
    });
    
    const changeState = useChangeState(state, setState);

    const { data } = useGetMenuTypeQuery(id);
    const [ saveData ] = useSaveMenuTypeMutation();
    
    useEffect(() => {
        if (data){
            setState((prevState) => ({
                ...data
            }));
        }    
    }, [data]);
    

    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {
            saveData(state);
        }
    }
    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/menutypes");
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var name = state.name;
        var color = state.dashboard_color;

        if (!Validator.isAlphaNumericAndSpaceUnderscore(name)) {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 50) {
            err += "Name should be between 1 and 50 characters!\n";
            result = false;
        }
        if (color.length <= 0) {
            err += "Select the color!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err));
        }

        return result;
    }

    // Returns the HTML Form to the render() method.  
    const renderCreateForm = () => {
        return (
            <div>
                <fieldset>
                    <legend>Details</legend>
                    <form name="formEditMenuType" id="formEditMenuType" onSubmit={handleSave}>
                        <Grid container spacing={5}>
                            <Grid item xs={4}>
                                <Grid container spacing={5}>
                                    <Grid item xs={5}>
                                        <strong>Name:</strong>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextField
                                            name="name"
                                            variant="standard"
                                            value={state.name}
                                            onChange={changeState}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={5}>
                                        <strong>Enabled:</strong>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Switch
                                            name="enabled"
                                            checked={state.enabled}
                                            onChange={e => changeState(e)}
                                            color="primary"
                                        />
                                    </Grid>

                                    <Grid item xs={5}>
                                        <strong>Color (dashboard):</strong>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <input 
                                            type="color"
                                            name="dashboard_color" 
                                            value={state.dashboard_color} 
                                            onChange={changeState}/>
                                    </Grid>

                                    {state.ins_date !== "" ?
                                        <>
                                            <Grid item xs={5}>
                                                <strong>Created at:</strong>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {moment(state.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.ins_user !== "" ?
                                        <>
                                            <Grid item xs={5}>
                                                <strong>Created by:</strong>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {state.ins_user}
                                            </Grid></> : ""
                                    }
                                    {state.upd_date !== "" ?
                                        <>
                                            <Grid item xs={5}>
                                                <strong>Modified at:</strong>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {moment(state.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.upd_user !== "" ?
                                        <>
                                            <Grid item xs={5}>
                                                <strong>Modified by:</strong>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {state.upd_user}
                                            </Grid></> : ""
                                    }
                                    <Grid item xs={12}>
                                        <Box sx={styled.buttonsBox}>
                                            <Button variant="contained" color="primary" type="submit">
                                                Save
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </fieldset>
            </div>
        )
    }

    return (
        <div>
            <h3>Menu type</h3>
            <hr />
            {renderCreateForm()}
        </div>
    );
}

export default EditMenuType;