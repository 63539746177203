import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Validator from "../validator/Validator";
import * as Models from '../models/Models';
import {
    Grid,
    TextField,
    TextareaAutosize,
    Button,
    Switch,
    ListItemAvatar,
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Box,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,

} from '@mui/material';
import Image from './Image'
import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import moment from 'moment';
import { useChangeState } from '../utils/useChangeState';
import { useGetDishQuery, useGetListIngredientsQuery, useSaveDishMutation, useSaveDishPictureMutation, useSaveDishIngredientMutation, useDeleteDishIngredientMutation, } from '../store/apiSlice';
import { setError } from '../store/ApiInterface';
import { useAppDispatch } from '../store/configureStore';


export const EditDish : React.FC = (props) => {

    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const id = params.id != null ? parseInt(params.id) : 0;
    const [state, setState] = useState({
        ...Models.getDishDefaultValues(),
        //campuri in afara modelului, necesare pt functionarea interfetei
        ingredient_id: 0 as number,
        qty: 0 as number,
    });
    
    const changeState = useChangeState(state, setState);
    
    const { data: ingredients } = useGetListIngredientsQuery({default_value: '0', default_description: 'Select ingredient'});
    const { data } = useGetDishQuery(id);
    const [ saveData ] = useSaveDishMutation();
    const [ savePicture ] = useSaveDishPictureMutation();
    const [ saveIngredient ] = useSaveDishIngredientMutation();
    const [ deleteIngredient ] = useDeleteDishIngredientMutation();

    useEffect(() => {
        if (data){
            setState((prevState) => ({
                ...prevState,
                data: { ...data.data },
                picture: { ...data.picture },
                ingredients: [ ...data.ingredients ],
                ingredient_id: 0, 
                qty: 0
            }));
        }    
    }, [data]);

    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {
            saveData(state.data);
        }
    }
    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/dishes");

    }

    const handleChangePicture = (event: any) => {
        event.preventDefault();
        if (handleValidationChangePicture() && state.picture.picture_file !== undefined) {

            const formData = new FormData();
            formData.append("dish_id", state.data.dish_id.toString());
            formData.append("picture_file", state.picture?.picture_file);
            savePicture({ picture: formData, dish_id: state.data.dish_id });
        }
    }

    const displayPicture = (file: File | null) => {

        if (file != null) {
            //var picture_base64 = await utils.toBase64(file);
            var picture_url = URL.createObjectURL(file);

            setState(prevState => ({                
                ...prevState,
                picture: {
                    ...prevState.picture,
                    picture_file: file,
                    picture_url: picture_url,
                    picture_uploaded: true,
                }
            }));
        }
    }

    const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event != null && event.target != null && event.target.files != null && event.target.files[0] != null) {
            var file = event.target.files[0];
            
            utils.checkJpegPngSignature(file).then(result => {                
                
                if (result) {
                    displayPicture(file);
                }
                else {
                    alert("File content is invalid");
                }
            });
        }
    }

    const handleAddIngredient = (event: any) => {

        var ingredient: Models.DishIngredient = new Models.DishIngredient();


        if (handleValidationInsertIngredient()) {
            ingredient.dish_id = state.data.dish_id;
            ingredient.ingredient_id = state.ingredient_id;
            ingredient.qty = state.qty;

            saveIngredient(ingredient);
        }
    }
    const handleDeleteIngredient = (id: number, title: string) => {
        if (!window.confirm("Do you want to delete the ingredient: " + title))
            return;
        else {
            deleteIngredient({ id, dish_id: state.data.dish_id });
        }
    }

    const handleValidationInsertIngredient = () => {
        let result = true;
        let err = '';

        if (state.data.dish_id === 0) {
            err += "First save the dish! ";
            result = false;
        }

        if (state.ingredient_id === 0) {
            err += "Select the ingredient! ";
            result = false;
        }

        if (state.qty === 0) {
            err += "Select the quantity! ";
            result = false;
        }


        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }
    

    const handleValidation = () => {
        let result = true;
        let err = '';

        var ext_code = state.data.ext_code;
        var title = state.data.title;
        var description = state.data.description;

        if (!Validator.isText(ext_code)) {
            err += "Illegal character in Ext code field!\n";
            result = false;
        }
        if (ext_code.length > 50) {
            err += "Ext code should be between 0 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isText(title)) {
            err += "Illegal character in Title field!\n";
            result = false;
        }
        if (title.length <= 0 || title.length > 1000) {
            err += "Title should be between 1 and 1000 characters!\n";
            result = false;
        }

        if (!Validator.isText(description)) {
            err += "Illegal character in Description field!\n";
            result = false;
        }

        if (description.length <= 0 || description.length > 3000) {
            err += "Description should be between 1 and 3000 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleValidationChangePicture = () => {
        let result = true;
        let err = '';

        if (state.data.dish_id <= 0) {
            err += "First save the dish the change the picture!\n";
            result = false;
        }

        if (state.picture.picture_file === undefined) {
            err += "Picture is not uploaded!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }


    // Returns the HTML Form to the render() method.  
    const renderCreateForm = () => {
        return (
            <div>
                <form name="formEditCompany" id="formEditCompany" onSubmit={handleSave}>
                    <Grid container spacing={5}>
                        <Grid item xs={6}>
                            <Grid container spacing={5}>
                                <Grid item xs={4}>
                                    <strong>Title:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        name="title"
                                        variant="standard"
                                        value={state.data.title}
                                        onChange={e => changeState(e, 'data')}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>Ext code:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        name="ext_code"
                                        variant="standard"
                                        value={state.data.ext_code}
                                        onChange={e => changeState(e, 'data')}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>KCal:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        name="kcal"
                                        variant="standard"
                                        type="number"
                                        value={state.data.kcal}
                                        onChange={e => changeState(e, 'data')}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>Description:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextareaAutosize
                                        name="description"
                                        minRows={5}
                                        maxRows={5}
                                        style={{ width: "100%" }}
                                        className="textarea"
                                        value={state.data.description}
                                        onChange={e => changeState(e, 'data')}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <strong>Enabled:</strong>
                                </Grid>
                                <Grid item xs={8}>
                                    <Switch
                                        name="enabled"
                                        checked={state.data.enabled}
                                        onChange={e => changeState(e, 'data')}
                                        color="primary"
                                    />
                                </Grid>
                                {state.data.ins_date !== "" ?
                                    <>
                                        <Grid item xs={4}>
                                            <strong>Created at:</strong>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {moment(state.data.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                        </Grid></> : ""
                                }
                                {state.data.ins_user !== "" ?
                                    <>
                                        <Grid item xs={4}>
                                            <strong>Created by:</strong>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {state.data.ins_user}
                                        </Grid></> : ""
                                }
                                {state.data.upd_date !== "" ?
                                    <>
                                        <Grid item xs={4}>
                                            <strong>Modified at:</strong>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {moment(state.data.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                        </Grid></> : ""
                                }
                                {state.data.upd_user !== "" ?
                                    <>
                                        <Grid item xs={4}>
                                            <strong>Modified by:</strong>
                                        </Grid>
                                        <Grid item xs={8}>
                                            {state.data.upd_user}
                                        </Grid></> : ""
                                }
                                <Grid item xs={12}>
                                    <Box sx={styled.buttonsBox}>
                                        <Button variant="contained" color="primary" type="submit">
                                            Save
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={handleClose}>
                                            Close
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={5}>
                                {state.picture.picture_url === "" ? "" :
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            <ListItemAvatar>
                                                <Image 
                                                    url = {state.picture.picture_url}
                                                    height = {400}
                                                    width = {400}
                                                    />
                                            </ListItemAvatar>
                                        </Grid>
                                        {state.picture.picture_uploaded ?
                                            <Grid item xs={12}>
                                                <div className="form-group text-center">
                                                    <Button variant="contained" color="primary" onClick={handleChangePicture}>
                                                        Change picture
                                                    </Button>
                                                </div>
                                            </Grid> : ""
                                        }

                                    </React.Fragment>
                                }
                                <Grid item xs={12}>
                                    Upload Picture: <input type="file" accept="image/png, image/jpeg, image/jpg" onChange={handleUploadFile} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="labelIngredientId">
                                    Ingredients
                                </InputLabel>
                                <Select
                                    name="ingredient_id"
                                    labelId="labelIngredientId"
                                    value={ingredients? state.ingredient_id : ''}
                                    input={<Input />}
                                    MenuProps={utils.MenuProps}
                                    onChange={e => changeState(e)}
                                >
                                    {ingredients?.map(o =>
                                        <MenuItem key={o.ingredient_id} value={o.ingredient_id}>{o.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                name="qty"
                                label="Quantity"
                                variant="standard"
                                type="number"
                                value={state.qty}
                                onChange={e => changeState(e)}
                                required
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={e => handleAddIngredient(e)}>
                                Add ingredient
                            </Button>

                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader title="Ingredients" />
                                <Divider />
                                {state.ingredients.length > 0 ?
                                    <CardContent>
                                        <Box>
                                            <Table>
                                                <TableHead>
                                                    <TableRow key={0}>
                                                        <TableCell>
                                                            Name
                                                        </TableCell>
                                                        <TableCell>
                                                            Unit
                                                        </TableCell>
                                                        <TableCell>
                                                            Qty
                                                        </TableCell>
                                                        <TableCell>
                                                            Added
                                                        </TableCell>
                                                        <TableCell style={{ minWidth: "100px" }}>
                                                            Delete
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {state.ingredients.map((item) => (
                                                        <TableRow
                                                            hover
                                                            key={item.id}
                                                        >
                                                            <TableCell>
                                                                {item.ingredient_name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.ingredient_unit}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.qty}
                                                            </TableCell>
                                                            <TableCell>
                                                                {"By " + item.ins_user + " at " + moment(item.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    size="small"
                                                                    endIcon={<DeleteForeverIcon />}
                                                                    onClick={(id) => handleDeleteIngredient(item.id, item.ingredient_name)}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </CardContent>
                                    :
                                    "There are no ingredients associtated with this dish. To add ingredients use the button 'ADD INGREDIENT'"
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }

    return (
        <div>
            <h3>Dish definition</h3>
            <hr />
            {renderCreateForm()}
        </div>
    );

}

export default EditDish;