import * as React from 'react';
import {  useSelector } from 'react-redux'
import * as FetchMenusStore from '../store/FetchMenus';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { Box, Select, Grid, TextField, Button, MenuItem, Input, InputLabel, Checkbox, FormGroup, FormControlLabel, Stack, Pagination } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { Close as CloseIcon, SaveAlt as SaveAltIcon } from '@mui/icons-material';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useChangeState } from '../utils/useChangeState';
import { RootState, useAppDispatch } from '../store/configureStore';
import { 
    useGetListDishesQuery,
    useGetListsQuery,
    useGetListMenuTypeQuery,
    useGetListIngredientsQuery,
    useGetMenusQuery,
    useDeleteMenuMutation 
} 
from '../store/apiSlice';
import { setError } from '../store/ApiInterface';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { shallowCompare } from '../utils/Utils';


export interface FetchMenusProps {
    selectable?: boolean;
    selectedMenusIds?: number[];
    onCloseCallback?(): void;
    onSaveCallback?(selectedDishesIds: number[]): void;
}


export const FetchMenus : React.FC<FetchMenusProps> = (props) => {

    const navigate = useNavigate();    
    
    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.fetchMenus)

    const [state, setState] = useState<FetchMenusStore.FormData>({
        ...storeState.formData
    });
    
    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<FetchMenusStore.FormData>({
        ...storeState.formData
    });    

    const [selectedMenusIds, setSelectedMenusIds] = useState<number[]>(props.selectedMenusIds ?? []);

    const { data: yesNoList } = useGetListsQuery('1');
    const { data: pageSizeList } = useGetListsQuery('3');
    const { data: dishes} = useGetListDishesQuery({default_value: '0', default_description: 'All'})
    const { data: menuTypes } = useGetListMenuTypeQuery({default_value: '0', default_description: 'All', client_id: 0});
    const { data: ingredients } = useGetListIngredientsQuery({default_value: '0', default_description: 'All'});

    const { data, refetch } = useGetMenusQuery((yesNoList != null && pageSizeList != null && dishes != null && menuTypes != null && ingredients != null) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea

    const [ deleteData ] = useDeleteMenuMutation();    
    
    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            if (shallowCompare(state, searchState)){
                //cazul in care apasa pe "search" fara sa schimbe vreun parametru - fortam refresh
                refetch();
            }
            else {
                setSearchState({ ...state, PageNumber: 0 })
            }
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }

    const handleNewMenu = (event: any) => {
        navigate("/menus/edit/0");
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var title = state.title;
        var ext_code = state.ext_code;

        if (!Validator.isText(title)) {
            err += "Illegal character in Title field!\n";
            result = false;
        }
        if (title != null && title.length > 1000) {
            err += "Title should not exceed 1000 characters!\n";
            result = false;
        }

        if (!Validator.isText(ext_code)) {
            err += "Illegal character in Ext code field!\n";
            result = false;
        }
        if (ext_code != null && ext_code.length > 50) {
            err += "Ext code should not exceed 50 characters!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }
        return result;
    }

    const renderSearchBox = () => {
        return (            
            <Grid container spacing={5}>
                <Grid item xs={2}>
                    <TextField
                        name="title"
                        variant="standard"
                        label="Title"
                        value={state.title}
                        onChange={changeState}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        name="ext_code"
                        variant="standard"
                        label="Ext Code"
                        value={state.ext_code}
                        onChange={changeState}
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel shrink id="labelDish">
                        Dish
                    </InputLabel>
                    <Select
                        name="dish_id"
                        labelId="labelDish"
                        value={dishes? state.dish_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {dishes?.map(o =>
                            <MenuItem key={o.data.dish_id} value={o.data.dish_id}>{o.data.title}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel shrink id="labelMenuType">
                        Menu Type
                    </InputLabel>
                    <Select
                        name="menu_type_id"
                        labelId="labelMenuType"
                        value={menuTypes? state.menu_type_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {menuTypes?.map(o =>
                            <MenuItem key={o.menu_type_id} value={o.menu_type_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel shrink id="labelIngredient">
                        Ingredient
                    </InputLabel>
                    <Select
                        name="ingredient_id"
                        labelId="labelIngredient"
                        value={ingredients? state.ingredient_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {ingredients?.map(o =>
                            <MenuItem key={o.ingredient_id} value={o.ingredient_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={1}>
                    <InputLabel shrink id="labelEnabled">
                        Enabled
                    </InputLabel>
                    <Select
                        name="enabled"
                        labelId="labelEnabled"
                        value={yesNoList? state.enabled : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {yesNoList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={1}>
                    <InputLabel shrink id="labelPageSize">
                        Page Size
                    </InputLabel>
                    <Select
                        id="PageSize"
                        labelId="labelPageSize"
                        value={pageSizeList? state.PageSize : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangePageSize}
                    >
                        {pageSizeList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styled.buttonsBox}>
                        <Button variant="contained" color="primary" onClick={handleSearch}>
                            Search
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleNewMenu} >
                            Add menu
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    const renderSearchResult = useMemo(() => {

        const handleDelete = (menu_id: number, title: string) => {
            if (!window.confirm("Do you want to delete the menu: " + title))
                return;
            else {
                deleteData(menu_id);
            }
        }
        const handleEdit = (menu_id: number) => {
            navigate("/menus/edit/" + menu_id);
        }

        const handleNavigation = (pageNumber: number) => {        
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }

        
        const handleSelectMenu = (event: any, menu_id: number) => {        
            const selectedIndex = selectedMenusIds.indexOf(menu_id);
            let newSelectedMenuIds: number[] = [];

            if (selectedIndex === -1) {
                newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds, menu_id);
            } else if (selectedIndex === 0) {
                newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds.slice(1));
            } else if (selectedIndex === selectedMenusIds.length - 1) {
                newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelectedMenuIds = newSelectedMenuIds.concat(
                    selectedMenusIds.slice(0, selectedIndex),
                    selectedMenusIds.slice(selectedIndex + 1)
                );
            }

            setSelectedMenusIds(newSelectedMenuIds);
        };

        const columns: GridColDef[] = [
            { field: 'title', headerName: 'Title', flex: 0.7 },
            { field: 'ext_code', headerName: 'Ext Code', flex: 0.15 },
            {
                field: 'enabled',
                headerName: 'Enabled',
                renderCell: (params: GridCellParams) => (
                    <span>
                        {(params.row['enabled'] as boolean) ? "Yes" : "No"}
                    </span>
                ),
            },
            {
                field: 'edit',
                headerName: 'Edit',
                width: 200,
                renderCell: (params: GridCellParams) => (
                    <span>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={() => handleEdit(params.row['menu_id'] as number)}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={() => handleDelete(params.row['menu_id'] as number, params.row['title']?.toString() || '')}
                        >
                            Delete
                        </Button>
                    </span>
                ),
            },
        ];
    
    
        const columns_selectable: GridColDef[] = [
            {
                field: 'title',
                headerName: 'Title',
                flex: 0.7,
                renderCell: (params: GridCellParams) => (
                    <span>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedMenusIds.indexOf(params.row['menu_id'] as number) !== -1}
                                        onChange={(event: React.ChangeEvent) => handleSelectMenu(event, params.row['menu_id'] as number)}
                                    />
                                }
                                label={(params.row['title'] as string) || ''} />
                        </FormGroup>
                    </span>
                ),
            },
            { field: 'ext_code', headerName: 'Ext Code', flex: 0.15 },
            {
                field: 'enabled',
                headerName: 'Enabled',
                renderCell: (params: GridCellParams) => (
                    <span>
                        {(params.row['enabled'] as boolean) ? "Yes" : "No"}
                    </span>
                ),
            },
            {
                field: 'client_id',
                headerName: 'Edit',
                width: 200,
                renderCell: (params: GridCellParams) => (
                    <span>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={() => handleEdit(params.row['menu_id'] as number)}
                        >
                            Edit
                        </Button>
                    </span>
                ),
            },
        ];

        return (
            data &&                
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <fieldset>
                            <legend>Search results</legend>
                            <Box sx={{ height: '100%', width: '100%' }}>
                                <DataGrid
                                    autoHeight
                                    rows={data.Data}
                                    columns={props.selectable ? columns_selectable : columns}
                                    getRowId={(r) => r.menu_id}
                                    rowCount={data.RowCount}
                                    pagination
                                    pageSizeOptions={[searchState.PageSize]}
                                    paginationMode="server"
                                    paginationModel= {{ pageSize: searchState.PageSize, page: searchState.PageNumber }}
                                    onPaginationModelChange={(e) => handleNavigation(e.page)}
                                />
                            </Box>
                            <Stack spacing={2} justifyContent="center" alignItems="center">
                                <Pagination 
                                    count={Navigation.pageCount(data.Last, data.Current)} 
                                    page={Navigation.currentPage(data.Current)} 
                                    onChange={(event, page) => handleNavigation(page - 1)}
                                    disabled={Navigation.isNavigationDisabled(data.Last, data.Current)} 
                                    showFirstButton
                                    showLastButton
                                />
                            </Stack>
                        </fieldset>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Box sx={styled.buttonsBox}>
                            {props.onCloseCallback != null ?
                                <Button 
                                    variant="outlined" 
                                    startIcon={<CloseIcon />} 
                                    onClick={props.onCloseCallback}>
                                    Close
                                </Button> : ""
                            }
                            {props.onSaveCallback != null ?
                                <Button 
                                    variant="contained" 
                                    endIcon={<SaveAltIcon />} 
                                    onClick={(e) => {
                                        if (props.onSaveCallback) {
                                            props.onSaveCallback(selectedMenusIds)}
                                        }
                                    }>
                                    Save
                                </Button> : ""
                            }
                        </Box>
                    </Grid>
                </Grid>
        );
    }, [props, data, deleteData, navigate, selectedMenusIds, searchState.PageNumber, searchState.PageSize]);

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Menus</h1>
            {renderSearchBox()}
            {renderSearchResult}
        </React.Fragment>
    );
}


export default FetchMenus;