import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import * as FetchOrdersStore from '../store/FetchOrders';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { 
    Box, 
    Select, 
    Grid, 
    Button, 
    MenuItem, 
    Input, 
    InputLabel, 
    IconButton, 
    Tooltip, 
    Hidden, 
    Card, 
    CardHeader, 
    Avatar, 
    CardContent, 
    Typography, 
    CardActions,
    Collapse, 
    Divider, 
    Chip,
    Stack,
    Pagination, } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import { 
    Launch as LaunchIcon, 
    PictureAsPdf as PictureAsPdfIcon, 
    EditLocationAlt as EditLocationAltIcon, 
    MoreVert as MoreVertIcon,    
    ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import moment from 'moment';
import * as Models from "../models/Models";
import { useChangeState } from '../utils/useChangeState';
import { useGetCurrentDayQuery, useGetListClientsQuery, useGetListMealsOrderQuery, useGetListOrdersStatusQuery, useGetListsQuery, useGetOrdersQuery, useSaveAmendmentMutation } from '../store/apiSlice';
import { RootState, useAppDispatch } from '../store/configureStore';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { setError } from '../store/ApiInterface';
import { useAxiosUtil } from '../utils/axiosUtils';
import { red } from '@mui/material/colors';
import { getStatusColor, shallowCompare } from '../utils/Utils';



const RecipeReviewCard : React.FC<{ order: Models.Order }> = ({ order }) => {

    const navigate = useNavigate();

    const { download } = useAxiosUtil();

    const [ saveAmendment ] = useSaveAmendmentMutation();

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleView = (orderId: number) => {
        navigate("/order/edit/" + orderId);
    }

    const handleCreateAmendment = (order_id: number) => {

        if (!window.confirm("Do you want to create amendment?"))
            return;
        else {
            var order_amendment: Models.OrderSaveAmendment = { order_id: order_id };
            saveAmendment(order_amendment);
        }
    }

    const handleDownloadOrderPdf = (order_id: number) => {
        download('/api/OrdersPdf/' + order_id, 'pdf', order_id.toString() + '.pdf');
    }

    return (
        <Card sx={{ mb:3 }}>
            <CardHeader
                avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    {order.client_name.split(" ").map(str => str[0]).join("")}
                </Avatar>
                }
                action={
                <IconButton aria-label="settings">
                    <MoreVertIcon />
                </IconButton>
                }
                title={
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {`Order id ${order.order_id}`}
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <Chip label={order.status_description} sx={getStatusColor(order.status)} variant="outlined" />
                            </Box>
                        </Grid>
                    </Grid>
                }
                subheader={moment(order.order_date).format('DD.MM.YYYY')}
                
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        Type: {order.type_description}
                    </Grid>

                    <Grid item xs={6}>
                        Client: {order.client_name}
                    </Grid>

                    <Grid item xs={6}>
                        Meal: {order.meal_description}
                    </Grid>

                    <Grid item xs={6}>
                        Created By: {order.ins_user}
                    </Grid>

                    <Grid item xs={12}>
                        Created At: {moment(order.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions disableSpacing>

            
                <IconButton
                    aria-label="open"
                    size="large"                                                                        
                    onClick={() => handleView(order.order_id)}
                >
                    <LaunchIcon fontSize="inherit" />
                </IconButton>
            

            
                <IconButton
                    aria-label="pdf"
                    size="large"                                                                        
                    onClick={() => handleDownloadOrderPdf(order.order_id)}
                >
                    <PictureAsPdfIcon fontSize="inherit" />
                </IconButton>
                {order.allow_amendment ?                
                    <IconButton
                        aria-label="pdf"
                        size="large"                                                                        
                        onClick={() => handleCreateAmendment(order.order_id)}
                    >
                        <EditLocationAltIcon fontSize="inherit" />
                    </IconButton>                
                    :
                    null
                }

                <styled.ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    >
                    <ExpandMoreIcon />
                </styled.ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                <Typography paragraph>Method:</Typography>
                <Typography paragraph>
                    Heat 1/2 cup of the broth in a pot until simmering, add saffron and set
                    aside for 10 minutes.
                </Typography>
                <Typography paragraph>
                    Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over
                    medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring
                    occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a
                    large plate and set aside, leaving chicken and chorizo in the pan. Add
                    pimentón, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook,
                    stirring often until thickened and fragrant, about 10 minutes. Add
                    saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
                </Typography>
                <Typography paragraph>
                    Add rice and stir very gently to distribute. Top with artichokes and
                    peppers, and cook without stirring, until most of the liquid is absorbed,
                    15 to 18 minutes. Reduce heat to medium-low, add reserved shrimp and
                    mussels, tucking them down into the rice, and cook again without
                    stirring, until mussels have opened and rice is just tender, 5 to 7
                    minutes more. (Discard any mussels that don&apos;t open.)
                </Typography>
                <Typography>
                    Set aside off of the heat to let rest for 10 minutes, and then serve.
                </Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}

const OrdersMobileTable : React.FC<{orders: Models.Order[]}> = (props) => {
    return (
        <>
            {props.orders.length === 0 ?
            <Box>
                There are no orders
            </Box>
            :
            props.orders?.map(o =>
                <div key={o.order_id}>
                    <RecipeReviewCard order={o}/>
                    <Divider />
                </div>
            )}
        </>
    );
}


export const FetchOrders : React.FC = (props) => {

    const navigate = useNavigate();

    const { download } = useAxiosUtil();
    
    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.fetchOrders)

    const [state, setState] = useState<FetchOrdersStore.FormData>({
        ...storeState.formData,
    });
    
    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<FetchOrdersStore.FormData>({
        ...storeState.formData
    });

    const { data: currentDay } = useGetCurrentDayQuery();
    const { data: pageSizeList } = useGetListsQuery('3');
    const { data: clientsList } = useGetListClientsQuery({default_value: '0', default_description: 'All'});
    const { data: statusList } = useGetListOrdersStatusQuery({default_value: '0', default_description: 'All'});
    const { data: mealList } = useGetListMealsOrderQuery({default_value: '0', default_description: 'All'});

    const { data: orders, refetch } = useGetOrdersQuery((
        searchState.start_date !== '' &&
        searchState.end_date !== '' &&
        currentDay != null && 
        pageSizeList != null && 
        clientsList!= null && 
        statusList != null && 
        mealList != null) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea

    const [ saveAmendment ] = useSaveAmendmentMutation();

    useEffect(() => {
        //daca este prima afisare atunci luam start date, end date si clientul din API si declansam search
        if (currentDay && clientsList && statusList && mealList){
            if (state.start_date === ''){ 
                setState((prevState) => ({
                    ...prevState,
                    start_date: currentDay,
                    end_date: currentDay,
                    client_id: clientsList[0]?.client_id | 0,
                    status: statusList[0]?.status | 0,
                    meal_id: mealList[0]?.meal_id | 0,
                }));

                setSearchState((prevState) => ({
                    ...prevState,
                    start_date: currentDay,
                    end_date: currentDay,
                    client_id: clientsList[0]?.client_id | 0,
                    status: statusList[0]?.status | 0,
                    meal_id: mealList[0]?.meal_id | 0,
                }));
            }
        }
    }, [state.start_date, currentDay, clientsList, statusList, mealList]);


    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            if (shallowCompare(state, searchState)){
                //cazul in care apasa pe "search" fara sa schimbe vreun parametru - fortam refresh
                refetch();
            }
            else {
                setSearchState({ ...state, PageNumber: 0 })
            }
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }

    const handleChangeStartDate = (newValue: Date | null) => {
        setState({ ...state, start_date: utils.formatDate(newValue) });
    }

    const handleChangeEndDate = (newValue: Date | null) => {
        setState({ ...state, end_date: utils.formatDate(newValue) });
    }

    const handleNewOrder = (event: any) => {
        navigate("/order/create");
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var StartDate = state.start_date;
        var EndDate = state.end_date;

        if (!Validator.isDateISO(StartDate)) {
            err += "Start Date is invalid!\n";
            result = false;
        }
        if (!Validator.isDateISO(EndDate)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }
        return result;
    }    

    const renderSearchBox = () => {
        return (            
            <Grid container spacing={5}>
                <Grid item xs={6} sm={2}>
                    <InputLabel shrink id="labelDatelId">
                        Start Date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            format="dd.MM.yyyy"
                            value={new Date(state.start_date)}
                            onChange={handleChangeStartDate}
                            slotProps={{ textField: { variant: 'standard' } }}
                            sx={{ maxWidth: 'calc(100% - 2px)', }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={6} sm={2}>
                    <InputLabel shrink id="labelDatelId">
                        End Date
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            format="dd.MM.yyyy"
                            value={new Date(state.end_date)}
                            onChange={handleChangeEndDate}
                            slotProps={{ textField: { variant: 'standard' } }}
                            sx={{ maxWidth: 'calc(100% - 2px)', }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={6} sm={2}>
                    <InputLabel shrink id="labelClientId">
                        Client
                    </InputLabel>
                    <Select
                        name="client_id"
                        labelId="labelClientId"
                        value={clientsList ? state.client_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {clientsList?.map(o =>
                            <MenuItem key={o.client_id} value={o.client_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={6} sm={2}>
                    <InputLabel shrink id="labelStatus">
                        Status
                    </InputLabel>
                    <Select
                        name="status"
                        labelId="labelStatus"
                        value={statusList ? state.status : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {statusList?.map(o =>
                            <MenuItem key={o.status} value={o.status}>{o.description}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={6} sm={2}>
                    <InputLabel shrink id="labelMeal">
                        Meal Type
                    </InputLabel>
                    <Select
                        name="meal_id"
                        labelId="labelMeal"
                        value={mealList ? state.meal_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {mealList?.map(o =>
                            <MenuItem key={o.meal_id} value={o.meal_id}>{o.description}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={6} sm={2}>
                    <InputLabel shrink id="labelPageSize">
                        Page Size
                    </InputLabel>
                    <Select
                        id="PageSize"
                        labelId="labelPageSize"
                        value={pageSizeList ? state.PageSize: ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangePageSize}
                    >
                        {pageSizeList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styled.buttonsBox}>
                        <Button variant="contained" color="primary" onClick={handleSearch}>
                            Search
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleNewOrder} >
                            Create order
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    const renderSearchResult = useMemo(() => {

        const handleView = (orderId: number) => {
            navigate("/order/edit/" + orderId);
        }
    
        const handleCreateAmendment = (order_id: number) => {
    
            if (!window.confirm("Do you want to create amendment?"))
                return;
            else {
                var order_amendment: Models.OrderSaveAmendment = { order_id: order_id };
                saveAmendment(order_amendment);
            }
        }

        const handleDownloadOrderPdf = (order_id: number) => {            
            download('/api/OrdersPdf/' + order_id, 'pdf', order_id.toString() + '.pdf');
        }

        const handleNavigation = (pageNumber: number) => {
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }

        const columns: GridColDef[] = [
            { field: 'order_id', headerName: 'Order Id', flex: 1 },
            { field: 'client_name', headerName: 'Client', flex: 4 },
            { field: 'meal_description', headerName: 'Meal', flex: 2 },
            {
                field: "order_date",
                headerName: "Order Date",
                flex: 2,
                type: "string",
                renderCell: (params) => moment(params.row.order_date).format('DD.MM.YYYY')
            },
            { 
                field: 'status_description', 
                headerName: 'Status',
                headerAlign: 'center',
                flex: 2,
                align:'center',
                renderCell: (params: GridCellParams) => (
                    <Chip label={params.row['status_description']} sx={getStatusColor(params.row['status'] as number)} variant="outlined" />
                ),
            },
            { field: 'type_description', headerName: 'Type', flex: 2 },
            {
                field: "ins_date",
                headerName: "Created At",
                flex: 2,
                type: "string",
                renderCell: (params) => moment(params.row.ins_date).format('DD.MM.YYYY HH:mm:ss')
            },
            { field: 'ins_user', headerName: 'Created By', flex: 2 },
            {
                field: 'view',
                headerName: '',
                width: 200,
                renderCell: (params: GridCellParams) => (
                    <Box sx={{ m: 1 }}>
                        <Tooltip title="Open order">
                            <IconButton
                                aria-label="open"
                                size="large"                                                                        
                                onClick={() => handleView(params.row['order_id'] as number)}
                            >
                                <LaunchIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
    
                        <Tooltip title="Export to pdf">
                            <IconButton
                                aria-label="pdf"
                                size="large"                                                                        
                                onClick={() => handleDownloadOrderPdf(params.row['order_id'] as number)}
                            >
                                <PictureAsPdfIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
    
                        {(params.row['allow_amendment'] as boolean) ?
                            <Tooltip title="Create amendament">
                                <IconButton
                                    aria-label="pdf"
                                    size="large"                                                                        
                                    onClick={() => handleCreateAmendment(params.row['order_id'] as number)}
                                >
                                    <EditLocationAltIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                            :
                            null
                        }
    
                    </Box>
                ),
            },        
        ];
        
        return (
            orders &&                
                <fieldset>
                    <legend>Search results</legend>
                    <Hidden smDown>
                        <Box sx={{ height: '100%', width: '100%' }}>
                            <DataGrid
                                autoHeight
                                rows={orders.Data ?? []}
                                columns={columns}
                                getRowId={(r) => r.order_id}                        
                                rowCount={orders.RowCount}
                                pagination
                                pageSizeOptions={[searchState.PageSize]}
                                paginationMode="server"
                                paginationModel= {{ pageSize: searchState.PageSize, page: searchState.PageNumber }}
                                onPaginationModelChange={(e) => handleNavigation(e.page)}
                            />
                        </Box>
                    </Hidden>

                    <Hidden smUp>
                        <OrdersMobileTable orders={orders.Data}/>
                    </Hidden>
                    <Stack spacing={2} justifyContent="center" alignItems="center">
                        <Pagination 
                            count={Navigation.pageCount(orders.Last, orders.Current)} 
                            page={Navigation.currentPage(orders.Current)} 
                            onChange={(event, page) => handleNavigation(page - 1)}
                            disabled={Navigation.isNavigationDisabled(orders.Last, orders.Current)} 
                            showFirstButton
                            showLastButton
                        />
                    </Stack>
                </fieldset>
        );
    }, [orders, navigate, saveAmendment, download, searchState.PageSize, searchState.PageNumber]);
    
    return (
        <React.Fragment>
            <h1 id="tabelLabel">Orders</h1>
            {renderSearchBox()}
            {renderSearchResult}
        </React.Fragment>
    );
}

export default FetchOrders;