import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as Validator from "../validator/Validator";
import { Box, Grid, TextField, Button, Switch, } from '@mui/material';
import * as styled from './StyledComponents'
import * as Models from "../models/Models";
import moment from 'moment';
import { useChangeState } from '../utils/useChangeState';
import { useGetIngredientQuery, useSaveIngredientMutation } from '../store/apiSlice';
import { useAppDispatch } from '../store/configureStore';
import { setError } from '../store/ApiInterface';


export const EditIngredient : React.FC = (props) => {

    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const id = params.id != null ? parseInt(params.id) : 0;
    const [state, setState] = useState<Models.Ingredient>({
        ...Models.getIngredientDefaultValues(),
    });
    
    const changeState = useChangeState(state, setState);

    const { data } = useGetIngredientQuery(id);
    const [ saveData ] = useSaveIngredientMutation();
    

    useEffect(() => {
        if (data){
            setState((prevState) => ({                
                ...data
            }));
        }    
    }, [data]);

    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {
            saveData(state);
        }
    }
    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/ingredients");

    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var ext_code = state.ext_code;
        var name = state.name;
        var unit = state.unit;

        if (!Validator.isText(ext_code)) {
            err += "Illegal character in Ext code field!\n";
            result = false;
        }
        if (ext_code.length > 50) {
            err += "Ext code should be between 0 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isText(name)) {
            err += "Illegal character in Name field!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 1000) {
            err += "Name should be between 1 and 1000 characters!\n";
            result = false;
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(unit)) {
            err += "Unit should be alpha numeric or underscore!\n";
            result = false;
        }
        if (unit.length <= 0 || unit.length > 50) {
            err += "Unit should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    // Returns the HTML Form to the render() method.  
    const renderCreateForm = () => {
        return (
            <div>
                <fieldset>
                    <legend>Details</legend>
                    <form name="formEditIngredient" id="formEditIngredient" onSubmit={handleSave}>
                        <Grid container spacing={5}>
                            <Grid item xs={4}>
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <strong>Name:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="name"
                                            variant="standard"
                                            value={state.name}
                                            onChange={changeState}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Ext Code:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="ext_code"
                                            variant="standard"
                                            value={state.ext_code}
                                            onChange={changeState}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Unit:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="unit"
                                            variant="standard"
                                            value={state.unit}
                                            onChange={changeState}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Enabled:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            name="enabled"
                                            checked={state.enabled}
                                            onChange={e => changeState(e)}
                                            color="primary"
                                        />
                                    </Grid>
                                    {state.ins_date !== "" ?
                                        <>
                                            <Grid item xs={5}>
                                                <strong>Created at:</strong>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {moment(state.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.ins_user !== "" ?
                                        <>
                                            <Grid item xs={5}>
                                                <strong>Created by:</strong>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {state.ins_user}
                                            </Grid></> : ""
                                    }
                                    {state.upd_date !== "" ?
                                        <>
                                            <Grid item xs={5}>
                                                <strong>Modified at:</strong>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {moment(state.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.upd_user !== "" ?
                                        <>
                                            <Grid item xs={5}>
                                                <strong>Modified by:</strong>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {state.upd_user}
                                            </Grid></> : ""
                                    }
                                    <Grid item xs={12}>
                                        <Box sx={styled.buttonsBox}>
                                            <Button variant="contained" color="primary" type="submit">
                                                Save
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </fieldset>
            </div>
        )
    }

    return (
        <div>
            <h3>Ingredient management</h3>
            <hr />
            {renderCreateForm()}
        </div>
    );
}

export default EditIngredient;