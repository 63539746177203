import React from 'react';
import { useSelector } from 'react-redux'
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Button, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RootState } from '../../../../../../../../store/configureStore';

import { ApplicationPaths } from "../../../../../../../api-authorization/ApiAuthorizationConstants";
import { ReactComponent as Offline } from "../../../../../../../../icons/offline.svg";

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const storeState = useSelector((state: RootState) => state.App)


  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component={RouterLink}
          to="/"
          title="Smart Bio Food"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? '/logo.svg'
                : '/logo.svg'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                  size={'large'}
                  component={RouterLink}
                  to={'/contact'}
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    color: theme.palette.text.primary,
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                  }}
                >
                  Contact                  
                </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                  size={'large'}
                  component={RouterLink}
                  to={'/terms'}
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    color: theme.palette.text.primary,
                    backgroundColor: 'transparent',
                    fontWeight: 400,
                  }}
                >
                  Terms            
                </Button>
            </Grid>
          </Grid>
        </Box>
        <Box marginTop={1}>
          {storeState.online ?
            <Button
              size={'large'}
              variant="contained"
              color="primary"
              fullWidth
              component="a"
              href={storeState.auth_state?.isAuthenticated ? '/' : ApplicationPaths.Login}
            >
              {storeState.auth_state?.isAuthenticated ? 'Get in' : 'Login'}
            </Button>
            :
            <Box>
              <Offline width="24px" height="24px"/>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
