import React from 'react';

import * as Models from "../models/Models";
import { Tooltip } from '@mui/material';
import { red, green, blue } from '@mui/material/colors';

import {
    NotListedLocation as NotListedLocationIcon,
    TimesOneMobiledata as TimesOneMobiledataIcon, 
    AllInclusive as AllInclusiveIcon,
    CalendarMonth as CalendarMonthIcon,
    DateRange as DateRangeIcon,
} from '@mui/icons-material';

interface SchedulerItemAvatarProps {    
    triggers: Models.SchedulerTrigger[]
  }
  
  const SchedulerItemAvatar: React.FC<SchedulerItemAvatarProps> = ({ triggers }) => {
    
    return (
        <React.Fragment>
            {triggers.length === 0 ?
                <NotListedLocationIcon style={{
                    color: red[500],
                }}/>
            :
            triggers?.map((trigger, index) => (
                <div key={index}>
                {trigger.type === 1 ?
                    <TimesOneMobiledataIcon style={{
                        color: blue[500],
                    }}/>
                    :
                    <Tooltip
                        title={
                            <div style={{ whiteSpace: 'pre-line' }}>
                                {`Scheduler Id: ${trigger.scheduler_id}\n`}
                                {`Effective Date: ${trigger.effective_date}\n`}
                                {trigger.end_date && `End Date: ${trigger.end_date}\n`}
                                {`Recur every: ${trigger.recur_every} ${trigger.type === 2 ? 'days' : trigger.type === 3 ? 'weeks' : trigger.type === 4 ? 'months' : '' }`}
                            </div>
                        }
                    >
                        {trigger.type === 2 ?
                            <AllInclusiveIcon style={{
                                color: green[500],
                            }}/>
                        :
                        trigger.type === 3 ?
                            <DateRangeIcon style={{
                                color: green[500],
                            }}/>
                        :
                        trigger.type === 4 ?
                            <CalendarMonthIcon style={{
                                color: green[500],
                            }}/>
                        :
                        <></>
                        }
                </Tooltip>
                }
                </div>
            ))}
        </React.Fragment>
    );
  };
  
  export default SchedulerItemAvatar;
  