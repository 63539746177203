export interface AuthState {
    isAuthenticated: boolean;
    full_name: string;
    work_place: string;
    profile_picture_base64: string;
    roles: string[];
    force_change_password: boolean;
}

export class ListValue {
    Value: number = 0;
    Description: string = "";
}

export class SearchPaginationResult {
    RowCount: number = 0;
    First: number = 0;
    Prev: number = 0;
    Current: number = 0;
    Next: number = 0;
    Last: number = 0;
    AfterIdNext: number = 0;
}


export class SearchPaginationInput {
    PageNumber: number = 0;
    PageSize: number = 0;
    AfterId: number = 0;
}

export class ForceChangePassword {    
    Password1: string = "";
    Password2: string = "";
}


export class UserData {
    UserId: number = 0;
    UserName: string = "";
    Type: string = "";
    ClientName: string = "";
    CompanyName: string = "";
    ClientId: number = 0;
    CompanyId: number = 0;
    Email: string = "";
    EmailConfirmed: boolean = false;
    FirstName: string = "";
    LastName: string = "";
    PhoneNumber: string = "";
    PhoneNumberConfirmed: boolean = false;
    UserEnabled: boolean = false;
    TwoFactorEnabled: boolean = false;
    Roles: Role[] = [];
    Password1: string = "";
    Password2: string = "";
    ForceChangePassword: boolean = false;
}

export class Role {
    Value: string = "";
    Label: string = "";
    Selected: boolean = false;
}

export class UserInfo {    
    full_name: string = "";
    work_place: string = "";
    profile_picture_format: string = "";
    profile_picture: string = "";
    force_change_password: boolean = false;
    
}

export class Meal {
    meal_id: number = 0;
    description: string = "";
}

export class OrderStatus {
    status: number = 0;
    description: string = "";
}

export class Company {
    company_id: number = 0;
    name: string = "";
    enabled: boolean = false;
}

export class MenuType {
    menu_type_id: number = 0;
    name: string = "";
    enabled: boolean = false;
    dashboard_color: string = "";
    selected: boolean = false;
    ins_date: string = "";
    ins_user: string = "";
    upd_date: string = "";
    upd_user: string = "";
}

export class Client {
    client_id: number = 0;
    name: string = "";
    enabled: boolean = false;
    menu_types: MenuType[] = [];
    company_id: number = 0;
    price_list: ClientPrice[] = [];
    price_empty: ClientPrice = {...getClientPriceDefaultValues()};
}

export class ClientPrice {
    price_id: number = 0;
    client_id: number = 0;    
    description: string = '';
    details: ClientPriceDetails[] = [];
    start_date: string = '';
    end_date: string = '';
    ins_date: string = '';
    ins_user: string = '';
    upd_date: string = '';
    upd_user: string = '';
}


export class ClientPriceDetails {
    meal_id: number = 0;
    meal_description: string = '';
    price: number = 0;
    ccy: string = '';
}

export class Ingredient {
    ingredient_id: number = 0;
    ext_code: string = "";
    name: string = "";
    unit: string = "";
    enabled: boolean = false;
    ins_date: string = "";
    ins_user: string = "";
    upd_date: string = "";
    upd_user: string = "";
}

export class DishIngredient {
    id: number = 0;
    dish_id: number = 0;
    ingredient_id: number = 0;
    ingredient_name: string = "";
    ingredient_unit: string = "";
    qty: number = 0;
    ins_date: string = "";
    ins_user: string = "";
}


export class Dish {
    data: DishData = {
        menu_dish_id: 0,
        dish_id: 0,
        ext_code: '',
        meal_id: 0,
        title: '',
        kcal: 0,
        description: '',
        enabled: false,
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
    };
    picture: DishPicture = {
        picture_url: '',
        picture_thumbnail_url: '',
        picture_uploaded: false,
    };
    ingredients: DishIngredient[] = [];
}

export class DishData {
    menu_dish_id: number = 0;
    dish_id: number = 0;
    ext_code: string = "";
    meal_id: number = 0;
    title: string = "";
    kcal: number = 0;
    description: string = "";
    enabled: boolean = false;
    ins_date: string = "";
    ins_user: string = "";
    upd_date: string = "";
    upd_user: string = "";
}

export class DishPicture {
    picture_file?: File;
    picture_url: string = '';
    picture_thumbnail_url: string = '';
    picture_uploaded: boolean = false;
}

export class Menu {
    client_id: number = 0;
    client_menu_id: number = 0; /* to do - de vazut cum se poate trata cazul; se foloseste la stergerea unui meniu asociat spitalului*/
    menu_id: number = 0;
    ext_code: string = "";
    title: string = "";
    enabled: boolean = false;
    dishes: Dish[] = [];
    menu_types: MenuType[] = [];
    price_list: MenuPrice[] = [];
    ins_date: string = "";
    ins_user: string = "";
    upd_date: string = "";
    upd_user: string = "";
}

export class MenuPrice {
    price_id: number = 0;
    menu_id: number = 0;    
    description: string = '';
    price: number = 0;
    ccy: string = '';
    start_date: string = '';
    end_date: string = '';
    ins_date: string = '';
    ins_user: string = '';
    upd_date: string = '';
    upd_user: string = '';
}

export class MenuDishes {
    menu_id: number = 0;
    meal_id: number = 0;
    dish_ids: number[] = [];
}

/*
export class ClientMenu extends Dish {
    client_id: number = 0;
    day_of_week_id: number = 0;
    details: string = "";
    ins_user: string = "";
    ins_date: string = "";
    upd_user: string = "";
    upd_date: string = "";
}*/

export class ClientMenu {
    client_id: number = 0;
    menu_ids: number[] = [];
}

export class MenuAvailable {
    menu_type_id: number = 0;
    menu_id: number = 0;
    title: string = '';
}

export class Order {
    order_id: number = 0;
    client_id: number = 0;
    client_name: string = "";
    meal_id: number = 0;
    meal_description: string = "";
    menus_available: MenuAvailable[] = [];
    order_date: string = "";
    status: number = 0;
    status_description: string = "";
    type: number = 0;
    type_description: string = "";
    primary_order_id: number = 0;
    ins_date: string = "";
    ins_user: string = "";
    details: OrderDetails[] = [];
    next_status: OrderNextStatus[] = [];
    fill: OrderFill[] = [];
    allow_amendment: boolean = false;
    allow_multiple_menus: boolean = false;
}

export class OrderDetails {
    order_id: number = 0;
    order_detail_id: number = 0;
    structure_id: number = 0;
    parent_name: string = "";
    name: string = "";
    display_status: number = 0;
    menu_type_id: number = 0;
    menu_type: string = "";
    menu_ids: OrderMenu[] = [];
    menu_custom: string = '';
    menu: string = "";
    menu_reversal: string = "";
    patient_name: string = "";
    details: string = "";
    updated: boolean = false;
}

export class OrderDetailsSave extends OrderDetails {
    mode: number = 0;    
    structure_ids: number[] = [];    
}

export class OrderDetailsStandardMenu {
    order_id: number = 0;
    structure_id: number = 0;
    patient_name: string = '';
    details: string = '';
}

export class OrderDetailsCustomMenu {
    order_id: number = 0;
    order_detail_id: number = 0;
    structure_id: number = 0;
    menu_custom: string = '';
}

export class OrderRequestFill {
    order_id: number = 0;
    date: string = '';
}

export class OrderSaveAmendment {
    order_id: number = 0;
}

export class OrderRequestMenu {
    order_id: number = 0;
    client_id: number = 0;
    order_date: string = '';
    menu_type_id: number = 0;
    structure_id: number = 0;
}

export class OrderMenu {
    menu_id: number = 0;
    qty: number = 0;
}

export class OrderNextStatus {
    status: number = 0;
    description: string = "";
}

export class OrderFill {
    date: string = "";
    description: string = "";
}

export class SchedulerTrigger {
    scheduler_id: number = 0;
    type: number = 0;
    menu_type_id: number = 0;
    menu_type: string = '';
    type_description: string = '';
    effective_date: string = '';
    end_date: string = '';
    menu_ids: number[] = [];
    recur_on: number[] = [];
    recur_every: number = 0;
}

export class DayContent {
    menu_type: string = "";
    menu_type_id: number = 0;
    menu: string[] = [];
    menu_ids: number[] = [];
    triggers: SchedulerTrigger[] = [];
}

export class WeekContent {
    day_of_week_number: number = 0;
    day_of_week: string = "";
    effective_date: string = "";
    day_content: DayContent[] = [];
}

export class Scheduler {
    week_counter: number = 0;
    week_number: string = "";
    week_period: string = "";    
    week_content: WeekContent[] = [];
}

export class SchedulerMenu {
    scheduler_id: number = 0;
    client_id: number = 0;
    menu_type_id: number = 0;
    type: number = 0;
    effective_date: string = "";
    end_date: string = "";
    recur_every: number = 0;
    recur_on: number[] = [];
    menu_ids: number[] = [];
}

export class ClientStructure {
    client_id: number = 0;
    structure_id: number = 0;
    name: string = "";
    parent_structure_id: number = 0;
    children: ClientStructure[] = [];
    ins_user: string = "";
    ins_date: string = "";
    upd_user: string = "";
    upd_date: string = "";
}

export class ClientStructureAccessRight {
    id: number = 0;
    client_id: number = 0;
    structure_id: number = 0;
    name: string = "";
    user_id: number = 0;
    first_name: string = "";
    last_name: string = "";
    ins_date: string = "";
    ins_user: string = "";
}

export class Report {
    report_id: number = 0;
    type: string = "";
    description: string = "";
    ins_date: string = "";
    ins_user: string = "";
    age: string = "";
    has_pdf: boolean = false;
    has_xlsx: boolean = false;
}

export class ReportType {
    type: number = 0;
    name: string = "";
}

export class ReportSave {
    report_type: number = 0;
    start_date: string = '';
    end_date: string = '';
    client_id: number = 0;
    description: string = '';
}


export class Settings {
    company_id: number = 0;
    company_name: string = "";
    cut_off_time: string = "";
    allow_multiple_menus: boolean = false;
}

export function getSearchPaginationResultDefaultValues(): SearchPaginationResult {
    let output: SearchPaginationResult = {
        RowCount: 0,
        First: -1,
        Prev: -1,
        Current: 0,
        Next: -1,
        Last: -1,
        AfterIdNext: -1,
    };
    
    return output;
}

export function getSearchPaginationInputDefaultValues(): SearchPaginationInput {
    let output: SearchPaginationInput = {
        PageSize: 100,
        PageNumber: 0,
        AfterId: 0,
    };
    return output;
}

export function getUserDataDefaultValues(): UserData {
    let output: UserData = {
        UserId: 0,
        UserName: '',
        Type: '0',
        ClientName: '',
        CompanyName: '',
        ClientId: 0,
        CompanyId: 0,
        Email: '',
        EmailConfirmed: false,
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        PhoneNumberConfirmed: false,
        UserEnabled: false,
        TwoFactorEnabled: false,
        Roles: [],
        Password1: '',
        Password2: '',
        ForceChangePassword: false,
    };
    return output;
}

export function getCompanyDefaultValues(): Company {
    let output: Company = {
        company_id: 0,
        name: '',
        enabled: false,
    };
    return output;
}

export function getDishDefaultValues(): Dish {
    let output: Dish = {
        data: {
            menu_dish_id: 0,
            dish_id: 0,
            ext_code: '',
            meal_id: 0,
            title: '',
            kcal: 0,
            description: '',
            enabled: false,
            ins_date: '',
            ins_user: '',
            upd_date: '',
            upd_user: '',
        },
        picture: {
            picture_file: undefined,
            picture_url: '',
            picture_thumbnail_url: '',
            picture_uploaded: false,
        },
        ingredients: [],
    };
    return output;
}


export function getIngredientDefaultValues(): Ingredient {
    let output: Ingredient = {
        ingredient_id: 0,
        ext_code: "",
        name: "",
        unit: "",
        enabled: false,
        ins_date: "",
        ins_user: "",
        upd_date: "",
        upd_user: "",
    };
    return output;
}

export function getMenuDefaultValues(): Menu {
    let output: Menu = {
        client_id: 0,
        client_menu_id: 0,
        menu_id: 0,
        ext_code: "",
        title: "",
        enabled: false,
        dishes: [],
        menu_types: [],
        price_list: [],
        ins_date: "",
        ins_user: "",
        upd_date: "",
        upd_user: "",
    };
    return output;
}

export function getMenuTypeDefaultValues(): MenuType {
    let output: MenuType = {
        menu_type_id: 0,
        name: "",
        enabled: false,
        dashboard_color: "#000000",
        selected: false,
        ins_date: "",
        ins_user: "",
        upd_date: "",
        upd_user: "",
    };
    return output;
}


export function getOrderDefaultValues(): Order {
    let output: Order = {
        order_id: 0,
        client_id: 0,
        client_name: "",
        meal_id: 0,
        meal_description: '',
        menus_available: [],
        order_date: "",
        status: 0,
        status_description: "",
        type: 0,
        type_description: "",
        primary_order_id: 0,
        ins_date: "",
        ins_user: "",
        details: [],
        next_status: [],
        fill: [],
        allow_amendment: false,
        allow_multiple_menus: false,
    };
    return output;
}

export function getOrderDetailsDefaultValues(): OrderDetails {
    let output: OrderDetails = {
        order_detail_id: 0,
        order_id: 0,
        structure_id: 0,
        parent_name: "",
        name: "",        
        display_status: 0,
        menu_type_id: 0,
        menu_type: "",
        menu_ids: [],
        menu_custom: "",
        menu: "",
        menu_reversal: "",
        patient_name: "",
        details: "",
        updated: false,
    };
    return output;
}

export function getClientDefaultValues(): Client {
    let output: Client = {
        client_id: 0,        
        name: '',
        enabled: true,
        menu_types: [],
        company_id: 0,
        price_list: [],
        price_empty: {...getClientPriceDefaultValues()}
    };
    return output;
}

export function getClientPriceDefaultValues(): ClientPrice {
    let output: ClientPrice = {
        price_id: 0,
        client_id: 0,        
        description: '',
        details: [],
        start_date: '',
        end_date: '',
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
    };
    return output;
}

export function getMenuPriceDefaultValues(): MenuPrice {
    let output: MenuPrice = {
        price_id: 0,
        menu_id: 0,
        description: '',
        price: 0,
        ccy: 'RON',
        start_date: '',
        end_date: '',
        ins_date: '',
        ins_user: '',
        upd_date: '',
        upd_user: '',
    };
    return output;
}

export function geSettingsDefaultValues(): Settings {
    let output: Settings = {
        company_id: 0,
        company_name: '',
        cut_off_time: '',
        allow_multiple_menus: false
    };
    return output;
}

export interface UsersSearchResult extends SearchPaginationResult {
    Data: UserData[];
}

export interface ReportsSearchResult extends SearchPaginationResult {
    Data: Report[];
}

export interface ClientsSearchResult extends SearchPaginationResult {
    Data: Client[];
}

export interface CompaniesSearchResult extends SearchPaginationResult {
    Data: Company[];
}

export interface DishesSearchResult extends SearchPaginationResult {
    Data: Dish[];
}

export interface IngredientsSearchResult extends SearchPaginationResult {
    Data: Ingredient[];
}

export interface OrdersSearchResult extends SearchPaginationResult {
    Data: Order[];
}

export interface MenusSearchResult extends SearchPaginationResult {
    Data: Menu[];
}

export interface MenuTypesSearchResult extends SearchPaginationResult {
    Data: MenuType[];
}

export interface DashboardDatasetItem {
    value: number;
    label: string;
    background_color: string;
}

export interface DashboardDataset {
    dataset_label: string;
    dataset_background_color: string;
    dataset: DashboardDatasetItem[];
}

export interface DashboardSearchResult {
    dashboard1: DashboardDataset[];
    dashboard2: DashboardDataset[];
}
