import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Validator from "../validator/Validator";
import { Menu, MenuDishes, MenuPrice, getMenuDefaultValues, getMenuPriceDefaultValues } from '../models/Models';
import {
    Select,
    Input,
    MenuItem,
    InputLabel,
    Box,
    Tab,
    Dialog,
    Grid,
    TextField,
    Button,
    Switch,
    Card,
    CardHeader,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemText,
    FormControlLabel,
    Checkbox,    
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TextareaAutosize
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ArrowRight as ArrowRightIcon, DeleteForever as DeleteForeverIcon, ModeEdit as ModeEditIcon } from '@mui/icons-material';
import ViewDish from './ViewDish';
import FetchDishes from './FetchDishes';
import MealMenuItem from './MealMenuItem';
import * as utils from '../utils/Utils';
import { DialogTitle, DialogContent } from './StyledComponents'
import * as styled from './StyledComponents'
import moment from 'moment';
import { useChangeState } from '../utils/useChangeState';
import { useAppDispatch } from '../store/configureStore';
import { 
    useGetListsQuery,
    useGetListMealsQuery,
    useGetMenuQuery,
    useSaveMenuMutation,
    useSaveMenuPriceMutation,
    useDeleteMenuPriceMutation,
    useSaveMenuDishMutation,
    useDeleteMenuDishMutation, } 
from '../store/apiSlice';
import { setError } from '../store/ApiInterface';


// At runtime, Redux will merge together...

export interface EditMenuState {
    menu: Menu;
    view_dish_show_modal: boolean;
    view_dish_modal_title: string;
    view_dish_id: number;
    edit_menu_show_modal: boolean;
    selected_dishes_ids: number[];
    selected_meal_id: number;

    price_show_modal: boolean;
    menu_price: MenuPrice;

    tab_value: string;
}


export const EditMenu : React.FC = (props) => {

    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const id = params.id != null ? parseInt(params.id) : 0;
    const [state, setState] = useState<EditMenuState>({        
        menu: { ...getMenuDefaultValues() },
        //campuri in afara modelului, necesare pt functionarea interfetei
        view_dish_show_modal: false,
        view_dish_modal_title: "",
        view_dish_id: 0,
        edit_menu_show_modal: false,
        selected_dishes_ids: [],
        selected_meal_id: 0,
        tab_value: "1",
        price_show_modal: false,
        menu_price: { ...getMenuPriceDefaultValues () }        
    });
    
    const changeState = useChangeState(state, setState);
    
    const { data: mealsList } = useGetListMealsQuery();
    const { data: ccyList } = useGetListsQuery('7');
    const { data } = useGetMenuQuery(id);
    const [ saveMenu ] = useSaveMenuMutation();
    const [ saveMenuPrice, { isSuccess: isSuccessSaveMenuPrice, reset: resetSaveMenuPrice } ] = useSaveMenuPriceMutation();
    const [ deleteMenuPrice ] = useDeleteMenuPriceMutation();
    const [ saveMenuDish,  { isSuccess: isSuccessSaveMenuDish, reset: resetSaveMenuDish } ] = useSaveMenuDishMutation();
    const [ deleteMenuDish ] = useDeleteMenuDishMutation();

    useEffect(() => {
        if (data){
            setState((prevState) => ({
                ...prevState,
                menu: {...data}
            }));
        }    
    }, [data]);


    const handleCloseEditMenuModal = useCallback(() => {
        setState((prevState) => ({
            ...prevState, 
            edit_menu_show_modal: false,
            selected_dishes_ids: [],
            selected_meal_id: 0,
        }));
    }, []);

    useEffect(() => {
        if (isSuccessSaveMenuDish && state.edit_menu_show_modal){
            handleCloseEditMenuModal();
            resetSaveMenuDish();
        }    
    }, [isSuccessSaveMenuDish, state.edit_menu_show_modal, resetSaveMenuDish, handleCloseEditMenuModal]);
    
    const handleClosePriceModal = useCallback(() => {
        setState((prevState) => ({
            ...prevState, 
            price_show_modal: false,
        }));
    }, []);
    
    useEffect(() => {
        if (isSuccessSaveMenuPrice && state.price_show_modal){
            handleClosePriceModal();
            resetSaveMenuPrice();
        }    
    }, [isSuccessSaveMenuPrice, state.price_show_modal, resetSaveMenuPrice, handleClosePriceModal]);
    


    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setState({ ...state, tab_value: newValue });
    };

    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {
            saveMenu(state.menu);
        }
    }
    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/menus");
    }

    const handleEditMenu = (event: any, meal_id: number) => {

        var selectedDishIds: number[] = [];
        
        if (meal_id === 0) {
            dispatch(setError("Selected meal id can not be 0"));
            return;
        }

        state.menu.dishes.filter(x =>
            x.data.meal_id === meal_id).forEach(item => {
                selectedDishIds.push(item.data.dish_id);
            });

        setState({
            ...state, 
            edit_menu_show_modal: true,
            selected_dishes_ids: selectedDishIds,
            selected_meal_id: meal_id,
        });
    }

    const handleSaveEditMenuModal = (selectedDishIds: number[]) => {
        
        var menu: MenuDishes = new MenuDishes();
        
        if (state.menu.menu_id === 0) {
            dispatch(setError("First save the menu"));
            return;
        }
        if (state.selected_meal_id === 0) {
            dispatch(setError("Selected meal id can not be 0"));
            return;
        }

        menu.menu_id = state.menu.menu_id;
        menu.meal_id = state.selected_meal_id;
        menu.dish_ids = selectedDishIds;

        saveMenuDish(menu);
        
    }



    const handleCloseViewDishModal = () => {
        setState({
            ...state, 
            view_dish_show_modal: false,
            view_dish_modal_title: "",
            view_dish_id: 0,
        });
    }

    const handleViewDish = (dish_id: number) => {
        setState({
            ...state, 
            view_dish_show_modal: true,
            view_dish_modal_title: 'View dish',
            view_dish_id: dish_id,
        });
    }

    const handleDelteDish = (id: number, dish_title: string) => {

        if (!window.confirm("Do you want to delete the dish " + dish_title + "?"))
            return;
        else {
            deleteMenuDish({id, menu_id: state.menu.menu_id});
            return;
        }
    }

    const handleCheckMenuType = (event: any) => {
        var i;
        let menu_types = [...state.menu.menu_types];
        for (i = 0; i < menu_types.length; i++) {
            let menu_type = { ...menu_types[i] };
            if (menu_type.menu_type_id === Number(event.target.value)) {
                menu_type.selected = event.target.checked;
                menu_types[i] = menu_type;
                break;
            }
        }
        setState(prevState => ({
            ...prevState,
            menu: {
                ...prevState.menu,
                menu_types: menu_types
            }
        }));
    }



    
    const handleChangePriceStartDate = (newValue: Date | null) => {
        setState({
            ...state, 
            menu_price: {
                ...state.menu_price,
                start_date: utils.formatDate(newValue)
            }
        });
    }

    const handleChangePriceEndDate = (newValue: Date | null) => {
        setState({
            ...state, 
            menu_price: {
                ...state.menu_price,
                end_date: utils.formatDate(newValue)
            }
        });
    }


    const handleValidation = () => {
        let result = true;
        let err = '';

        var title = state.menu.title;
        var ext_code = state.menu.ext_code;
        var menu_types = state.menu.menu_types;

        if (!Validator.isText(title)) {
            err += "Illegal character in Title field!\n";
            result = false;
        }
        if (title.length <= 0 || title.length > 1000) {
            err += "Title should be between 1 and 1000 characters!\n";
            result = false;
        }

        if (!Validator.isText(ext_code)) {
            err += "Illegal character in Ext code field!\n";
            result = false;
        }
        if (ext_code.length > 50) {
            err += "Ext code should be between 0 and 50 characters!\n";
            result = false;
        }

        if (menu_types.length <= 0) {
            err += "Menu type list in empty!\n";
            result = false;
        }

        if (menu_types.filter(x => x.selected).length <= 0){
            err += "Select at least one menu type!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err));
        }

        return result;
    }

    const handleValidationPrice = () => {
        let result = true;
        let err = '';

        var description = state.menu_price.description;
        var price = state.menu_price.price;

        if (!Validator.isAlphaNumericAndSpaceUnderscore(description)) {
            err += "Description should be alpha numeric or underscore!\n";
            result = false;
        }
        if (description.length <= 0 || description.length > 50) {
            err += "Description should be between 1 and 50 characters!\n";
            result = false;
        }

        if (price <= 0) {
            err += "Price is mandatory!\n";
            result = false;
        }

        if (!Validator.isDateISO(state.menu_price.start_date)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (state.menu_price.end_date !== "" && !Validator.isDateISO(state.menu_price.end_date)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err));
        }

        return result;
    }

    const handleSavePrice = (event: any) => {
        event.preventDefault();

        if (handleValidationPrice()) {
            saveMenuPrice(state.menu_price);
        }
    }

    const handleAddPrice = () => {
        setState({
            ...state, 
            price_show_modal: true,
            menu_price: {
                ...getMenuPriceDefaultValues(),
                menu_id: state.menu.menu_id
            }
        });
    }

    const handleEditPrice = (menu_price: MenuPrice) => {
        setState({
            ...state, 
            price_show_modal: true,
            menu_price: { ...menu_price }
        });
    }

    const handleDeletePrice = (id: number, title: string) => {
        if (!window.confirm("Do you want to delete the menu price: " + title))
            return;
        else {
            deleteMenuPrice({ id, menu_id: state.menu.menu_id });
        }
    }

    const renderMeal = (meal_id: number, meal_title: string) => {

        const dishes = state.menu.dishes.filter(x => x.data.meal_id === meal_id);

        return (
            <Grid item xs={4} key={meal_id}>
                <Card>
                    <CardHeader
                        title={meal_title}
                    />
                    <Divider />
                    <List sx={{ width: '100%', maxWidth: 360 }}>
                        {dishes != null && dishes.length > 0 ?
                            dishes.map(dish =>
                                <MealMenuItem
                                    key={dish.data.dish_id}
                                    dish={dish}
                                    disabled = {!dish.data.enabled}
                                    onDeleteCallback={handleDelteDish}
                                    onViewDishCallback={handleViewDish}
                                />
                            )
                            :
                            <ListItem
                                key={0}
                            >
                                <ListItemText
                                    primary={meal_title + " is not defined, please edit the menu"}
                                />
                            </ListItem>
                        }
                    </List>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >
                        <Button
                            color="primary"
                            endIcon={<ArrowRightIcon />}
                            size="small"
                            variant="text"
                            disabled={state.menu.menu_id === 0}
                            onClick={e => handleEditMenu(e, meal_id)}
                        >
                            Edit menu
                        </Button>
                    </Box>
                </Card>
            </Grid>

        );
    }

    const renderMeals = () => {
        return (
            <Grid container spacing={1} style={{ paddingTop: 20 }}>                
                <Grid item xs={12}>
                    <Grid container spacing={5}>
                        {mealsList?.map(o =>
                            renderMeal(o.meal_id, o.description)
                        )}
                    </Grid>
                </Grid>                
            </Grid>
        );
    }


    // Returns the HTML Form to the render() method.  
    const renderCreateForm = () => {
        return (
            <>
                <Dialog
                    onClose={handleCloseViewDishModal}
                    aria-labelledby="customized-dialog-title-view-dish"
                    open={state.view_dish_show_modal}
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle id="customized-dialog-title-view-dish" onClose={handleCloseViewDishModal}>
                        {state.view_dish_modal_title}
                    </DialogTitle>
                    <DialogContent dividers>
                        <ViewDish
                            dish_id={state.view_dish_id}
                            onCloseCallback={handleCloseViewDishModal}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullScreen
                    onClose={handleCloseEditMenuModal}
                    aria-labelledby="customized-dialog-title-edit-menu"
                    open={state.edit_menu_show_modal}
                >
                    <DialogTitle id="customized-dialog-title-edit-menu" onClose={handleCloseEditMenuModal}>
                        Edit menu
                    </DialogTitle>
                    <DialogContent dividers>
                        <FetchDishes
                            selectable={true}
                            selectedDishesIds={state.selected_dishes_ids}
                            onCloseCallback={handleCloseEditMenuModal}
                            onSaveCallback={handleSaveEditMenuModal}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    onClose={handleClosePriceModal}
                    aria-labelledby="customized-dialog-title-edit-price"
                    open={state.price_show_modal}
                    fullWidth={true}
                    maxWidth={"md"}
                >
                    <DialogTitle id="customized-dialog-title-edit-price" onClose={handleClosePriceModal}>
                        Edit price
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>
                                    
                                    <Grid item xs={12}>
                                        <InputLabel id="labelDescription">
                                            Description
                                        </InputLabel>
                                        <TextField
                                            name="description"
                                            variant="standard"
                                            value={state.menu_price.description}
                                            onChange={e => changeState(e, 'menu_price')}
                                            required
                                        />
                                    </Grid>

                                    
                                    <Grid item xs={12}>
                                        <InputLabel id="labelPrice">
                                            Price
                                        </InputLabel>
                                        <TextField
                                            name="price"
                                            variant="standard"
                                            type="number"
                                            value={state.menu_price.price}
                                            onChange={e => changeState(e, 'menu_price')}
                                            required
                                        />
                                        <Select
                                            name="ccy"
                                            value={ccyList ? state.menu_price.ccy : ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={e => changeState(e, 'menu_price')}
                                        >
                                            {ccyList?.map(o =>
                                                <MenuItem key={o.Value} value={o.Value}>{o.Description}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel id="labelStartDate">
                                            Start Date
                                        </InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                format="dd.MM.yyyy"
                                                value={new Date(state.menu_price.start_date)}
                                                onChange={handleChangePriceStartDate}
                                                slotProps={{ textField: { variant: 'standard' } }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel id="labelEndDate">
                                            End Date
                                        </InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                format="dd.MM.yyyy"
                                                value={new Date(state.menu_price.end_date)}
                                                onChange={handleChangePriceEndDate}
                                                slotProps={{ textField: { variant: 'standard' } }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={5}>
                                    {state.menu_price.ins_date !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Created at:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {moment(state.menu_price.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.menu_price.ins_user !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Created by:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {state.menu_price.ins_user}
                                            </Grid></> : ""
                                    }
                                    {state.menu_price.upd_date !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Modified at:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {moment(state.menu_price.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                            </Grid></> : ""
                                    }
                                    {state.menu_price.upd_user !== "" ?
                                        <>
                                            <Grid item xs={4}>
                                                <strong>Modified by:</strong>
                                            </Grid>
                                            <Grid item xs={8}>
                                                {state.menu_price.upd_user}
                                            </Grid></> : ""
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>                                
                                <Box sx={styled.buttonsBox}>
                                    <Button variant="contained" color="primary" onClick={handleSavePrice}>
                                        Save
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handleClosePriceModal}>
                                        Close
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={state.tab_value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                                <Tab label="Basic data" value="1" />
                                <Tab label="Price" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <form name="formEditCompany" id="formEditCompany" onSubmit={handleSave}>
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <strong>Title:</strong>
                                        <TextareaAutosize
                                            name="title"
                                            maxRows={5}
                                            style={{ width: "100%" }}
                                            className="textarea"
                                            value={state.menu.title}
                                            onChange={e => changeState(e, 'menu')}
                                            placeholder="Menu title"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container spacing={5}>

                                            <Grid item xs={5}>
                                                <strong>Ext code:</strong>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField
                                                    name="ext_code"
                                                    variant="standard"
                                                    value={state.menu.ext_code}
                                                    onChange={e => changeState(e, 'menu')}
                                                />
                                            </Grid>


                                            <Grid item xs={5}>
                                                <strong>Enabled:</strong>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Switch
                                                    name="enabled"
                                                    checked={state.menu.enabled}
                                                    onChange={e => changeState(e, 'menu')}
                                                    color="primary"
                                                />
                                            </Grid>

                                            <Grid item xs={5}>
                                                <strong>Menu type:</strong>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Grid container spacing={1}>
                                                {
                                                    state.menu.menu_types.map(item => (
                                                        <Grid item xs={12} key={ item.menu_type_id }>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        name="MenuTypes"
                                                                        checked={item.selected}
                                                                        value={item.menu_type_id}
                                                                        key={item.menu_type_id}
                                                                        onChange={handleCheckMenuType}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={item.name}
                                                            />
                                                        </Grid>
                                                    ))
                                                }
                                                </Grid>
                                            </Grid>

                                            {state.menu.ins_date !== "" ?
                                                <>
                                                    <Grid item xs={5}>
                                                        <strong>Created at:</strong>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        {moment(state.menu.ins_date).format('DD.MM.YYYY HH:mm:ss')}
                                                    </Grid></> : ""
                                            }
                                            {state.menu.ins_user !== "" ?
                                                <>
                                                    <Grid item xs={5}>
                                                        <strong>Created by:</strong>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        {state.menu.ins_user}
                                                    </Grid></> : ""
                                            }
                                            {state.menu.upd_date !== "" ?
                                                <>
                                                    <Grid item xs={5}>
                                                        <strong>Modified at:</strong>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        {moment(state.menu.upd_date).format('DD.MM.YYYY HH:mm:ss')}
                                                    </Grid></> : ""
                                            }
                                            {state.menu.upd_user !== "" ?
                                                <>
                                                    <Grid item xs={5}>
                                                        <strong>Modified by:</strong>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        {state.menu.upd_user}
                                                    </Grid></> : ""
                                            }
                                            <Grid item xs={12}>
                                                <Box sx={styled.buttonsBox}>
                                                    <Button variant="contained" color="primary" type="submit">
                                                        Save
                                                    </Button>
                                                    <Button variant="contained" color="primary" onClick={handleClose}>
                                                        Close
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {renderMeals()}
                                    </Grid>
                                </Grid>
                            </form>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Price" />
                                        <Divider />
                                        {state.menu.price_list.length > 0 ?
                                            <CardContent>
                                                <Box>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow key={0}>
                                                                <TableCell>
                                                                    Description
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    Price
                                                                </TableCell>
                                                                <TableCell>
                                                                    Ccy
                                                                </TableCell>
                                                                <TableCell>
                                                                    Start Date
                                                                </TableCell>
                                                                <TableCell>
                                                                    End Date
                                                                </TableCell>
                                                                <TableCell style={{ minWidth: "100px" }}>
                                                                    Edit / Delete
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {state.menu.price_list.map((item: MenuPrice) => (
                                                                <TableRow
                                                                    hover
                                                                    key={item.price_id}
                                                                >
                                                                    <TableCell>
                                                                        {item.description}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {item.price.toLocaleString('ro-RO', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.ccy}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {moment(item.start_date).format('DD.MM.YYYY')}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.end_date === "" ? "Unlimited" : moment(item.end_date).format('DD.MM.YYYY')}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Box sx={styled.buttonsBox}>
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                size="small"
                                                                                endIcon={<ModeEditIcon />}
                                                                                onClick={e => handleEditPrice(item)}
                                                                            >
                                                                                Edit
                                                                            </Button>

                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                size="small"
                                                                                endIcon={<DeleteForeverIcon />}
                                                                                onClick={e => handleDeletePrice(item.price_id, item.description)}
                                                                            >
                                                                                Delete
                                                                            </Button>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </CardContent>
                                            :
                                            "Prices are not defined. To add prices use the button 'ADD PRICE'"
                                        }
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={e => handleAddPrice()}
                                    >
                                        Add price
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Box>
            </>
        )
    }

    return (
        <div>
            <h3>Menu definition</h3>
            <hr />
            {renderCreateForm()}
        </div>
    );
}

export default EditMenu;