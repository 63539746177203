import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux'
import * as Models from '../models/Models';
import * as ClientsMenuStore from '../store/ClientsMenu';
import ViewDish from './ViewDish';
import FetchMenus from './FetchMenus';
import MealMenuItem from './MealMenuItem';
import { Box, List, Input, InputLabel, Select, MenuItem, Grid, Dialog, Button, Card, CardHeader, Divider, ListItem, ListItemText } from '@mui/material';
import * as utils from '../utils/Utils';
import { DialogTitle, DialogContent } from './StyledComponents'
import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import * as styled from './StyledComponents'
import { useDeleteClientsMenuMutation, useGetClientsMenuQuery, useGetListClientsQuery, useGetListMealsQuery, useSaveClientsMenuMutation } from '../store/apiSlice';
import { RootState, useAppDispatch } from '../store/configureStore';
import { setError } from '../store/ApiInterface';



export class ClientsMenuState {
    client_id: number = 0;
    view_dish_show_modal: boolean = false;
    view_dish_modal_title: string = "";
    view_dish_id: number = 0;
    edit_menu_show_modal: boolean = false;
    selected_menu_ids: number[] = [];
    selected_client_id: number = 0;
}

export const ClientsMenu : React.FC = (props) => {   

    const dispatch = useAppDispatch();
    
    const storeState = useSelector((state: RootState) => state.clientsMenu)

    const [state, setState] = useState<ClientsMenuStore.FormData & ClientsMenuState>({
        ...storeState.formData,
        view_dish_show_modal: false,
        view_dish_modal_title: "",
        view_dish_id: 0,
        edit_menu_show_modal: false,
        selected_menu_ids: [],
        selected_client_id: 0,        
    });

    const [searchState, setSearchState] = useState<ClientsMenuStore.FormData>({
        ...storeState.formData
    });


    const { data: clients } = useGetListClientsQuery({default_value: '0', default_description: 'Select the client'});
    const { data: meals } = useGetListMealsQuery();

    const { data: menus } = useGetClientsMenuQuery(searchState);
    
    const [ saveMenu, { isSuccess, reset } ] = useSaveClientsMenuMutation()
    const [ deleteMenu ] = useDeleteClientsMenuMutation()   
    

    const handleCloseEditMenuModal = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            edit_menu_show_modal: false,
            selected_menu_ids: [],
            selected_client_id: 0,
        }));
    }, []);

    useEffect(() => {

        if (isSuccess && state.edit_menu_show_modal){
            handleCloseEditMenuModal();
            reset();
        }
    
    }, [isSuccess, reset, state.edit_menu_show_modal, handleCloseEditMenuModal]);


    const handleChangeClient = (event: any) => {
        setState((prevState) => ({ ...prevState, client_id: Number(event.target.value) }));
        setSearchState((prevState) => ({ ...prevState, client_id: Number(event.target.value) }));
    }


    const handleDelteMenu = (id: number, menu_title: string) => {

        if (!window.confirm("Do you want to delete the menu " + menu_title + "?"))
            return;
        else {
            deleteMenu({ id });
            return;
        }
    }

    const handleEditMenu = (event: any) => {

        var selectedMenuIds: number[] = [];

        if (state.client_id === 0) {
            dispatch(setError("Select the client"));
            return;
        }

        menus?.filter(x =>
            x.client_id === state.client_id).forEach(item => {
                selectedMenuIds.push(item.menu_id);
            });

        setState({
            ...state,
            edit_menu_show_modal: true,
            selected_menu_ids: selectedMenuIds,
            selected_client_id: state.client_id,
        });
    }

    const handleSaveEditMenuModal = (selectedMenuIds: number[]) => {
        var menu: Models.ClientMenu = new Models.ClientMenu();

        if (state.selected_client_id === 0) {
            dispatch(setError("Selected client can not be 0"));
            return;
        }

        menu.client_id = state.selected_client_id;
        menu.menu_ids = selectedMenuIds;

        saveMenu(menu);
    }

    const handleCloseViewDishModal = () => {
        setState({
            ...state,
            view_dish_show_modal: false,
            view_dish_modal_title: "",
            view_dish_id: 0,
        });
    }

    const handleViewDish = (dish_id: number) => {
        setState({
            ...state,
            view_dish_show_modal: true,
            view_dish_modal_title: 'View dish',
            view_dish_id: dish_id,
        });
    }    

    const renderSearchBox = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={3}>
                    <InputLabel id="labelClientId">
                        Client
                    </InputLabel>
                    <Select
                        id="ClientId"
                        labelId="labelClientId"
                        value={clients ? state.client_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangeClient}
                    >
                        {clients?.map(o =>
                            <MenuItem key={o.client_id} value={o.client_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <Box sx={styled.buttonsBox}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={(e) => handleEditMenu(e)}
                            disabled={state.client_id === 0}
                        >
                            Edit menu
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    const renderMeal = () => {

        return (
            menus != null && menus.length > 0 ?
                menus.map(menu =>
                    
                    <Grid item xs={3} key={menu.client_menu_id}>
                        <Card>
                            <CardHeader style={{ textAlign: 'center', overflowWrap: 'anywhere' }}
                                title={menu.title}
                            />
                            <List sx={{ width: '100%', maxWidth: 360 }}>
                                {meals?.map(meal =>
                                    <div key={meal.meal_id}>
                                        <ListItem key={0}>
                                            <ListItemText
                                                primary={meal.description}
                                            />
                                        </ListItem>
                                        <Divider />                                            
                                        {menu.dishes.filter(d => d.data.meal_id === meal.meal_id).map(dish =>
                                            <MealMenuItem
                                                key={dish.data.dish_id}
                                                dish={dish}
                                                disabled = {!dish.data.enabled}
                                                onViewDishCallback={handleViewDish}
                                            />
                                        )}
                                    </div>
                                )}
                            </List>
                            <Divider />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <Button
                                    color="primary"
                                    endIcon={<DeleteForeverIcon />}
                                    size="small"
                                    variant="text"
                                    onClick={(e) => handleDelteMenu(menu.client_menu_id, menu.title)}
                                >
                                    Delete menu
                                </Button>
                            </Box>
                        </Card>
                    </Grid>
                    
                )
                :
                <Grid item xs={3}>
                    <h6 id="tabelLabel">Menu is empty, please edit the menu</h6>
                </Grid>
        );

    }

    const renderMenus = () => {
        return (
            <Grid container spacing={1} style={{ paddingTop: 20 }}>
                {(state.client_id !== 0) ?
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={5}>
                                {renderMeal()}
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Grid item xs={12}>
                        <h6 id="tabelLabel">Select the client</h6>
                    </Grid>
                }
            </Grid>
        );
    }
    const renderSearchResult = () => {
        return (
            <div>
                <Dialog
                    onClose={handleCloseViewDishModal}
                    aria-labelledby="customized-dialog-title-view-dish"
                    open={state.view_dish_show_modal}
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <DialogTitle id="customized-dialog-title-view-dish" onClose={handleCloseViewDishModal}>
                        {state.view_dish_modal_title}
                    </DialogTitle>
                    <DialogContent dividers>
                        <ViewDish
                            dish_id={state.view_dish_id}
                            onCloseCallback={handleCloseViewDishModal}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullScreen
                    onClose={handleCloseEditMenuModal}
                    aria-labelledby="customized-dialog-title-edit-menu"
                    open={state.edit_menu_show_modal}
                >
                    <DialogTitle id="customized-dialog-title-edit-menu" onClose={handleCloseEditMenuModal}>
                        Edit menu
                    </DialogTitle>
                    <DialogContent dividers>
                        <FetchMenus
                            selectable={true}
                            selectedMenusIds={state.selected_menu_ids}
                            onCloseCallback={handleCloseEditMenuModal}
                            onSaveCallback={handleSaveEditMenuModal}
                        />
                    </DialogContent>
                </Dialog>
                {renderMenus()}

            </div>
        );
    }

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Clients menu</h1>
            {renderSearchBox()}
            {renderSearchResult()}
        </React.Fragment>
    );

}

export default ClientsMenu;