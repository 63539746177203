import * as Models from "../models/Models";
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.


export interface  FetchMenusState {
    formData: FormData;    
}

export class FormData extends Models.SearchPaginationInput {
    title: string = "";
    ext_code: string = "";
    dish_id: number = 0;
    menu_type_id: number = 0;
    ingredient_id: number = 0;
    enabled: string = "0";
}


const unloadedState: FetchMenusState = {    
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        title: "",
        ext_code: "",
        dish_id: 0,
        menu_type_id: 0,
        ingredient_id: 0,
        enabled: "0",
    }
};

/* new reducer */

const fetchMenusSlice = createSlice({
    name: 'FetchMenus',
    initialState: unloadedState,
    reducers: {
      setFetchMenusFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = fetchMenusSlice
  // Extract and export each action creator by name
  export const { setFetchMenusFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer