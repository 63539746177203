import * as Models from "../models/Models";
import { PayloadAction, createSlice } from '@reduxjs/toolkit';


export interface  FetchDishesState {
    formData: FormData;
}

export class FormData extends Models.SearchPaginationInput {
    title: string = "";
    ext_code: string = "";
    ingredient_id: number = 0;
    enabled: string = "0";
}

const unloadedState: FetchDishesState = {
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        title: "",
        ext_code: "",
        ingredient_id: 0,
        enabled: "0",
    }
};


/* new reducer */

const fetchClientsSlice = createSlice({
    name: 'FetchDishes',
    initialState: unloadedState,
    reducers: {
      setFetchDishesFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = fetchClientsSlice
  // Extract and export each action creator by name
  export const { setFetchDishesFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer