import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import * as FetchCompaniesStore from '../store/FetchCompanies';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { Box, Select, Grid, TextField, Button, MenuItem, Input, InputLabel, Stack, Pagination } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import { useChangeState } from '../utils/useChangeState';
import { useDeleteCompanyMutation, useGetCompaniesQuery, useGetListsQuery } from '../store/apiSlice';
import { RootState, useAppDispatch } from '../store/configureStore';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { setError } from '../store/ApiInterface';
import { shallowCompare } from '../utils/Utils';


export const FetchCompanies : React.FC = (props) => {

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.fetchCompanies)
    
    const [state, setState] = useState<FetchCompaniesStore.FormData>({
        ...storeState.formData,
    });
    
    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<FetchCompaniesStore.FormData>({
        ...storeState.formData
    });

    const { data: yesNoList } = useGetListsQuery('1');
    const { data: pageSizeList } = useGetListsQuery('3');
    const { data, refetch } = useGetCompaniesQuery((yesNoList != null && pageSizeList != null) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea
    const [ deleteData ] = useDeleteCompanyMutation();    

    

    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            if (shallowCompare(state, searchState)){
                //cazul in care apasa pe "search" fara sa schimbe vreun parametru - fortam refresh
                refetch();
            }
            else {
                setSearchState({ ...state, PageNumber: 0 })
            }
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }

    const handleNewCompany = (event: any) => {
        navigate("/company/edit/0");
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var name = state.name;

        if (name != null && name.length > 0 && !Validator.isAlphaNumericAndSpace(name)) {
            err += "Name should be alpha numeric!\n";
            result = false;
        }
        if (name != null && name.length > 50) {
            err += "Name should not exceed 50 characters!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }
        return result;
    }

    const renderSearchBox = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={4}>                            
                    <TextField
                        name="name"
                        variant="standard"
                        label="Name"
                        value={state.name}
                        onChange={changeState}
                    />
                </Grid>
                <Grid item xs={4}>                            
                    <InputLabel shrink id="labelEnabled">
                        Enabled
                    </InputLabel>
                    <Select
                        name="enabled"
                        labelId="labelEnabled"
                        value={yesNoList? state.enabled: ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                        >
                        {yesNoList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel shrink id="labelPageSize">
                        Page Size
                    </InputLabel>
                    <Select
                        id="PageSize"
                        labelId="labelPageSize"
                        value={pageSizeList? state.PageSize : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangePageSize}
                    >
                        {pageSizeList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={ styled.buttonsBox }>
                        <Button variant="contained" color="primary" onClick={handleSearch}>
                            Search
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleNewCompany} >
                            Create new company
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    const renderSearchResult = useMemo(() => {

        const handleDelete = (company_id: number, name: string) => {
            if (!window.confirm("Do you want to delete the company: " + name))
                return;
            else {
                deleteData(company_id);
            }
        }
        const handleEdit = (companyId: number) => {
            navigate("/company/edit/" + companyId);        
        }    

        const handleNavigation = (pageNumber: number) => {
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }

        const columns: GridColDef[] = [        
            { field: 'name', headerName: 'Name', flex: 0.1 },
            {
                field: 'enabled',
                headerName: 'Enabled',
                flex: 0.1,
                renderCell: (params: GridCellParams) => (
                    <span>
                        {(params.row['enabled'] as boolean) ? "Yes" : "No"}
                    </span>
                ),
            },
            {
                field: 'company_id',
                headerName: 'Edit',
                width: 200,
                renderCell: (params: GridCellParams) => (
                    <span>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={() => handleEdit(params.row['company_id'] as number)}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={() => handleDelete(params.row['company_id'] as number, params.row['name']?.toString() || '')}
                        >
                            Delete
                        </Button>
                    </span>
                ),
            },
        ];

        return (
            data &&
                <fieldset>
                    <legend>Search results</legend>
                    <Box sx={{ height: '100%', width: '100%' }}>
                        <DataGrid
                            autoHeight
                            rows={data.Data}
                            columns={columns}
                            getRowId={(r) => r.company_id}
                            rowCount={data.RowCount}
                            pagination
                            pageSizeOptions={[searchState.PageSize]}
                            paginationMode="server"
                            paginationModel= {{ pageSize: searchState.PageSize, page: searchState.PageNumber }}
                            onPaginationModelChange={(e) => handleNavigation(e.page)}
                        />
                    </Box>
                    <Stack spacing={2} justifyContent="center" alignItems="center">
                        <Pagination 
                            count={Navigation.pageCount(data.Last, data.Current)} 
                            page={Navigation.currentPage(data.Current)} 
                            onChange={(event, page) => handleNavigation(page - 1)} 
                            disabled={Navigation.isNavigationDisabled(data.Last, data.Current)} 
                            showFirstButton
                            showLastButton
                        />
                    </Stack>
                </fieldset>
        );
    }, [data, deleteData, navigate, searchState.PageNumber, searchState.PageSize]);

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Companies</h1>
            {renderSearchBox()}
            {renderSearchResult}
        </React.Fragment>
    );
}

export default FetchCompanies;