import * as React from 'react';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { Tooltip,
    IconButton, 
    Card, 
    CardContent,
    Typography,
    Checkbox,
    InputLabel,
    Select,
    Input,
    MenuItem,
    FormControlLabel,
    TextField,
    Autocomplete,
    InputAdornment,
    Grid,
    Box
} from '@mui/material';

import {
    LocalHotel as LocalHotelIcon,
    LocalHotelOutlined as LocalHotelOutlinedIcon,
    Edit as EditIcon,
    DeleteForever as DeleteForeverIcon,
    EditOff as EditOffIcon,
    Save as SaveIcon,
    SaveAs as SaveAsIcon,
    Boy as BoyIcon,
    AddShoppingCart as AddShoppingCartIcon,
    Notes as NotesIcon,
    Accessible as AccessibleIcon,
} from '@mui/icons-material';
import { red, green } from '@mui/material/colors';
import * as Models from "../models/Models";
import { OrderDetails, OrderDetailsSave, OrderMenu } from '../models/Models';
import { 
    useDeleteOrderDetailsMutation,
    useSaveOrderDetailsCustomMenuMutation,
    useSaveOrderDetailsMutation,
    useSaveOrderDetailsStandardMenuMutation 
} from '../store/apiSlice';
import { RootState, store, useAppDispatch } from '../store/configureStore';
import { setError } from '../store/ApiInterface';
import * as Validator from "../validator/Validator";
import { handleChangeSelectedStructures } from '../store/EditOrder';
import { createSelector } from '@reduxjs/toolkit';
import * as utils from '../utils/Utils';
import { useChangeState } from '../utils/useChangeState';
import EditOrderPopper from './EditOrderPopper';


export interface EditOrderItemPropsToPass {
    order: Models.Order;
    item: OrderDetails;
    multiple_select: boolean;
    onOpenEditMenuDialog(title: string, patient_name: string, details: string, menu_type_id: number, structure_id: number, menu_ids: OrderMenu[]): void;
    menuTypeList: Models.MenuType[];
    menuTypeListWithDefaultValue: Models.MenuType[];
    menuTypeSuggestions: boolean;
}

export interface EditOrderItemState {
    menu_type_id: number;
    patient_name: string;
    details: string;
    menu_ids: OrderMenu[];
}

export const EditOrderItem : React.FC<EditOrderItemPropsToPass> = (props) => {

    const dispatch = useAppDispatch();

    //https://redux.js.org/usage/deriving-data-selectors
    const selectItems = (state: RootState) => state.editOrder.selectedStructures;
    const selectItemId = (state: RootState, itemId: number) => itemId

    const selectItemById = createSelector(
        [selectItems, selectItemId],
        (items, itemId) => {            
            if (items.length === 0) {                
                return false
            }
            else {
                const found = items.findIndex(x => x === itemId);
                if (found >= 0)
                    return true;
                else
                    return false;
            }
        },
    )

    const selectedStrucure = selectItemById(store.getState(), props.item.structure_id)

    const [checkedBed, setCheckedBed] = useState<boolean>(false);
    
    const [state, setState] = useState<EditOrderItemState>({
        menu_type_id: props.item.menu_type_id,
        patient_name: props.item.patient_name,
        details: props.item.details,
        menu_ids: [...props.item.menu_ids]
    });

    //daca nu gasim meniul in lista returnam null (nu undefined); daca returnam undefined componenta "Autocomplete" da eroare "A component is changing the controlled value state of Autocomplete to be uncontrolle"
    //https://stackoverflow.com/questions/61219164/material-ui-autocomplete-default-value-of-empty-string            
    const [menuType, setMenuType] = useState<Models.MenuType | null>(props.menuTypeList.find(x => x.menu_type_id === props.item.menu_type_id) || null);
    const [menusAvailable, setMenusAvailable] = useState<Models.MenuAvailable[]>([]);
    

    const changeState = useChangeState(state, setState);    

    const [ saveOrderDetails ] = useSaveOrderDetailsMutation()
    const [ saveOrderStandardMenu ] = useSaveOrderDetailsStandardMenuMutation();
    const [ saveOrderCustomMenu ] = useSaveOrderDetailsCustomMenuMutation();
    const [ deleteOrderDetail ] = useDeleteOrderDetailsMutation();
    
    useEffect(() => {
        setCheckedBed(selectedStrucure);
    }, [selectedStrucure]);
    
    /* start edit order item  */

    useEffect(() => {
        if (props.item){

            setState({
                menu_type_id: props.item.menu_type_id,
                patient_name: props.item.patient_name,
                details: props.item.details,
                menu_ids: [...props.item.menu_ids]
            })

            const menu_type = props.menuTypeList.find(x => x.menu_type_id === props.item.menu_type_id)
            //daca nu gasim meniul in lista returnam null (nu undefined); daca returnam undefined componenta "Autocomplete" da eroare "A component is changing the controlled value state of Autocomplete to be uncontrolle"
            //https://stackoverflow.com/questions/61219164/material-ui-autocomplete-default-value-of-empty-string            
            if (menu_type){
                setMenuType(menu_type)
            }
            else{
                setMenuType(null)
            }
        }
    }, [props]);

    useEffect(() => {
        const menus_available = props.order.menus_available.filter(x => x.menu_type_id === menuType?.menu_type_id)
        setMenusAvailable(menus_available);
        setState((prevState) => ({ ...prevState, menu_type_id: menuType?.menu_type_id || 0 }));

    }, [menuType, props.order.menus_available]);

    const isChanged = useMemo(() => {
        
        const menu_equal = 
            state.menu_ids.length === props.item.menu_ids.length &&
            state.menu_ids.every((element, index) => (element.menu_id === props.item.menu_ids[index].menu_id && element.qty === props.item.menu_ids[index].qty ));

        const selected_menu_type = menuType?.menu_type_id || 0;
        const changed = 
            !menu_equal ||
            selected_menu_type !== props.item.menu_type_id ||
            state.patient_name !== props.item.patient_name ||
            state.details !== props.item.details;

        return changed;
    }, [menuType, state, props.item]);

    const handleChangeMenuType = (menuType: Models.MenuType | null) => {
        setMenusAvailable([]) //avem nevoie pt ca daca este un singur meniu in lista, dupa ce se schimba menu_type se declanseaza useEffect care pune bifa pt singurul item din lista asta pt menu_type anterior schimbarii si salvarea order-ului da eroare ca nu este disponibil
        setMenuType(menuType)
        setState((prevState) => ({ ...prevState, menu_ids: [] }));
    }

    const handleChangeMenuTypeSelect = (event: any) => {
        const menuType = props.menuTypeList.find(x => x.menu_type_id === Number(event.target.value))
        setMenusAvailable([]) //avem nevoie pt ca daca este un singur meniu in lista, dupa ce se schimba menu_type se declanseaza useEffect care pune bifa pt singurul item din lista asta pt menu_type anterior schimbarii si salvarea order-ului da eroare ca nu este disponibil
        if (menuType){
            setMenuType(menuType)
        }
        else {
            setMenuType(null)
        }
        setState((prevState) => ({ ...prevState, menu_ids: [] }));
    }  
    
    const handleSelectMenu = useCallback((event: any, menu_id: number) => {

        var selectedMenusIds: OrderMenu[] = [];

        //create clone array
        if (props.order.allow_multiple_menus)
            selectedMenusIds = state.menu_ids.map(a => { return { ...a } });

        const selectedIndex = selectedMenusIds.findIndex(x => x.menu_id === menu_id);

        const menu_item: OrderMenu = { menu_id: menu_id, qty: 1 };


        let newSelectedMenuIds: OrderMenu[] = [];

        if (selectedIndex === -1) {
            newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds, menu_item);
        } else if (selectedIndex === 0) {
            newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds.slice(1));
        } else if (selectedIndex === selectedMenusIds.length - 1) {
            newSelectedMenuIds = newSelectedMenuIds.concat(selectedMenusIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedMenuIds = newSelectedMenuIds.concat(
                selectedMenusIds.slice(0, selectedIndex),
                selectedMenusIds.slice(selectedIndex + 1)
            );
        }
        newSelectedMenuIds.sort((a ,b) => (a.menu_id > b.menu_id ? 0 : -1));

        setState((prevState) => ({ ...prevState, menu_ids: newSelectedMenuIds }));
    }, [props.order.allow_multiple_menus, state.menu_ids]);

    useEffect(() => {
        //daca avem un singur meniu disponibil atunci punem automat bifa si qty = 1 (fara sa mai fie nevoie ca userul sa bifeze intr-o lista cu un singur element)
        if (menusAvailable !== null && menusAvailable.length === 1){
            const menu_id = menusAvailable[0].menu_id;
            const menu_exists = state.menu_ids.find(x => x.menu_id === menu_id);
            if (!menu_exists){
                handleSelectMenu(null, menusAvailable[0].menu_id)
            }
        }
    }, [menusAvailable, handleSelectMenu, state.menu_ids]);

    const handleChangeQty = (event: any, menu_id: number) => {
        //create clone array
        const selectedMenusIds = state.menu_ids.map(a => { return { ...a } });

        const selectedMenu = selectedMenusIds.find(x => x.menu_id === menu_id);

        if (selectedMenu != null) {
            selectedMenu.qty = Number(event.target.value);
        }

        setState({ ...state, menu_ids: selectedMenusIds });
    };
    /* end edit order item */

    const handleValidationOrderDetails = () => {
        let result = true;
        let err = '';

        var menu_type_id = menuType?.menu_type_id || 0;
        var patient_name = state.patient_name;
        var details = state.details;
        var menu_ids = state.menu_ids;

        if (props.order == null || props.order.order_id === 0) {
            err += "Order is not saved!\n";
            result = false;
        }

        if (menu_type_id === 0) {
            err += "Select the menu type!\n";
            result = false;
        }

        if (menu_ids.length === 0) {
            err += "Select the menu!\n";
            result = false;
        }

        if (patient_name !== "" && !Validator.isAlphaNumericAndSpaceUnderscore(patient_name)) {
            err += "Patient name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (patient_name.length > 50) {
            err += "Patient name should not exeed 50 characters!\n";
            result = false;
        }

        if (details !== "" && !Validator.isAlphaNumericAndSpaceUnderscore(details)) {
            err += "Details should be alpha numeric or underscore!\n";
            result = false;
        }
        if (details.length > 1000) {
            err += "Details should not exeed 1000 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;

    }

    const handleSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {        
        if (handleValidationOrderDetails()) {
            var order_details: OrderDetailsSave = new OrderDetailsSave();

            order_details.mode = 1; /* one by one edit */
            order_details.order_id = props.order.order_id;
            order_details.structure_id = props.item.structure_id;            
            order_details.menu_type_id = menuType?.menu_type_id || 0;
            order_details.menu_ids = state.menu_ids;
            order_details.patient_name = state.patient_name;
            order_details.details = state.details;           

            saveOrderDetails(order_details);
        }
    }

    const handleEditMenu = (event: any) => {        
        props.onOpenEditMenuDialog(
            "Edit menu for: " + props.item.parent_name + " / " + props.item.name, /* title */
            props.item.patient_name,
            props.item.details,
            props.item.menu_type_id,
            props.item.structure_id,
            props.item.menu_ids
        );
    }

    const handleDeleteMenu = () => {
        if (!window.confirm("Do you want to delete menu for " + props.item.name + "?"))
            return;
        else {
            deleteOrderDetail(props.item)
        }
    }
    
    const handleValidationStandardMenu = (patient_name: string, details: string) => {
        let result = true;
        let err = '';              

        var structure_id = props.item.structure_id;        

        if (structure_id === 0) {
            err += "Structure id can not be 0!\n";
            result = false;
        }

        if (patient_name !== "" && !Validator.isAlphaNumericAndSpaceUnderscore(patient_name)) {
            err += "Patient name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (patient_name.length > 1000) {
            err += "Details should not exeed 1000 characters!\n";
            result = false;
        }

        
        if (details !== "" && !Validator.isAlphaNumericAndSpaceUnderscore(details)) {
            err += "Details should be alpha numeric or underscore!\n";
            result = false;
        }
        if (details.length > 1000) {
            err += "Details should not exeed 1000 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleValidationCustomMenu = (menu_custom: string) => {
        let result = true;
        let err = '';              

        var structure_id = props.item.structure_id;        

        if (structure_id === 0) {
            err += "Structure id can not be 0!\n";
            result = false;
        }
        
        if (menu_custom !== "" && !Validator.isText(menu_custom)) {
            err += "Illegal character in Menu custom field!\n";
            result = false;
        }
        if (menu_custom.length > 10000) {
            err += "Menu custom should not exeed 10000 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const handleSaveOrderDetailsStandardMenu = (patient_name: string, details: string) => {
        if (handleValidationStandardMenu(patient_name, details)) {
            const standard_menu : Models.OrderDetailsStandardMenu = {
                order_id: props.order.order_id,
                structure_id: props.item.structure_id,
                patient_name: patient_name,
                details: details,
            }
            saveOrderStandardMenu(standard_menu);
        }
    }

    const handleSaveOrderDetailsCustomMenu = (menu_custom: string) => {
        if (handleValidationCustomMenu(menu_custom)) {
            const standard_menu : Models.OrderDetailsCustomMenu = {
                order_id: props.order.order_id,
                order_detail_id: props.item.order_detail_id,
                structure_id: props.item.structure_id,
                menu_custom: menu_custom,
            }
            saveOrderCustomMenu(standard_menu);
        }
    }

    const handleChangeBed = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedBed(event.target.checked);
        dispatch(handleChangeSelectedStructures({ structure_id: props.item.structure_id, checked: event.target.checked }))
    };

    const BedLabel = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const BedOutlineIcon = useMemo(() => {
        return (props.item.display_status === 1 ?
            <LocalHotelOutlinedIcon
                style={{
                    color: green[700],                    
                }}
            />
            : <LocalHotelOutlinedIcon
                style={{
                    color: red[700],                    
                }}
            />)
    }, [props.item.display_status])

    const BedIcon = useMemo(() => {
        return (props.item.display_status === 1 ?
            <LocalHotelIcon
                style={{
                    color: green[700],
                }}
            />
            : <LocalHotelIcon
                style={{
                    color: red[700],
                }}
            />)
    }, [props.item.display_status])

    return (
        <>
            <Card>
                <CardContent>
                    <Grid
                        container
                        spacing={1}
                        sx={{ justifyContent: 'space-between' }}
                    >
                        <Grid item xs={'auto'}>
                            {props.multiple_select ?
                                <Checkbox
                                    {...BedLabel}
                                    checked={checkedBed}
                                    onChange={handleChangeBed}
                                    icon={<>
                                        {BedOutlineIcon}
                                    </>}
                                    checkedIcon={<>
                                        {BedIcon}
                                    </>}
                                    sx={{ boxShadow: 3 }}
                                />                                
                                :
                                <>
                                    {BedOutlineIcon}
                                </>
                            }
                        </Grid>
                        <Grid item xs={true}>
                            <Typography
                                color="textPrimary"
                                variant="h3"
                            >
                                {props.order.type === 2 ?
                                props.item.updated ?
                                    <EditIcon/>
                                    :
                                    <EditOffIcon/>
                                : null}
                                {props.item.name}
                            </Typography>

                            <Grid
                                container
                                spacing={1}
                                
                            >
                                <Grid item xs={12} md={2}>
                                    <InputLabel id="labelMenuTypeId">
                                        Menu Type
                                    </InputLabel>
                                    
                                    {props.menuTypeSuggestions ?
                                        <Autocomplete
                                            id="menu-type"
                                            options={props.menuTypeList}
                                            getOptionLabel={(option) => option.name ?? ''}                                
                                            onChange={(event, value) => {handleChangeMenuType(value)}}
                                            value={menuType}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Select menu type"
                                                    variant="standard"
                                                />
                                                )}
                                        />
                                    :                                    
                                        <Select
                                            id="MenuTypeId"
                                            labelId="labelMenuTypeId"
                                            value={props.menuTypeListWithDefaultValue ? state.menu_type_id: ''}
                                            input={<Input />}
                                            MenuProps={utils.MenuProps}
                                            onChange={handleChangeMenuTypeSelect}
                                            sx = {{ maxWidth: 'calc(100% - 2px)' }}
                                        >
                                            {props.menuTypeListWithDefaultValue?.map(o =>
                                                <MenuItem key={o.menu_type_id} value={o.menu_type_id}>{o.name}</MenuItem>
                                            )}
                                        </Select>
                                    }
                                    
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    {(menusAvailable != null && menusAvailable.length > 0 ?
                                        menusAvailable.map(menu => {
                                            var menu_item = state.menu_ids.find(x => x.menu_id === menu.menu_id);
                                            return (                                                
                                                <div key={menu.menu_id}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox checked={menu_item != null}
                                                                        onChange={(event) => handleSelectMenu(event, menu.menu_id)}
                                                                        value="true"
                                                                    />
                                                                }
                                                                disableTypography
                                                                label={
                                                                    <Typography style={{overflowWrap: 'anywhere'}}>{menu.title}</Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box
                                                                display='flex'
                                                                justifyContent='center'
                                                                alignItems='center'
                                                                paddingBottom={'30px'}
                                                            >
                                                                <TextField
                                                                    id="Qty"
                                                                    name="Qty"
                                                                    variant="standard"
                                                                    type="number"
                                                                    label="Qty"
                                                                    value={menu_item != null ? menu_item.qty : 0}
                                                                    onChange={(event) => handleChangeQty(event, menu.menu_id)}
                                                                    disabled={menu_item == null || !props.order?.allow_multiple_menus}
                                                                    sx={{ maxWidth: '100px' }}
                                                                    required
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <AddShoppingCartIcon />
                                                                        </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>                                                        
                                                    </Grid>
                                                </div>
                                            )
                                        }
                                        )
                                        :
                                        <div>Menu is empty, please edit the menu</div>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        name="patient_name"
                                        label="Patient Name"
                                        variant="standard"
                                        value={state.patient_name}
                                        onChange={changeState}
                                        InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccessibleIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                                        />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        name="details"
                                        label="Details"
                                        variant="standard"
                                        style={{ width: "100%" }}                                        
                                        value={state.details}
                                        onChange={changeState}
                                        multiline
                                        InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <NotesIcon />
                                              </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Grid
                                        container
                                        spacing={0}
                                        style={{textAlign: "center"}}
                                    >
                                        <Grid item xs={2} md={12}>
                                            <Tooltip title="Save">
                                                <span>
                                                    <IconButton
                                                        aria-label="Edit-menu"
                                                        size="large"
                                                        onClick={e => handleSave(e)}
                                                        disabled={!isChanged}
                                                    >
                                                        {isChanged ? 
                                                            <SaveAsIcon fontSize="inherit" />
                                                            :
                                                            <SaveIcon fontSize="inherit" />
                                                        }                                            
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={2} md={12}>
                                            <Tooltip title="Edit menu">
                                                <span>
                                                    <IconButton
                                                        aria-label="Edit-menu"
                                                        size="large"
                                                        onClick={e => handleEditMenu(e)}
                                                    >
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={2} md={12}>                                            
                                            <Tooltip title="Delete menu">
                                                <span>
                                                    <IconButton
                                                        aria-label="Delete-menu"
                                                        size="large"
                                                        disabled={props.item.order_detail_id === 0 || props.order.status !== 1}
                                                        onClick={e => handleDeleteMenu()}
                                                    >
                                                        <DeleteForeverIcon fontSize="inherit" />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={2} md={12}>
                                            <span>
                                                <Tooltip title="Set standard menu">
                                                    <span>
                                                        <IconButton
                                                            aria-label="Set-Standard-menu"
                                                            size="large"
                                                            onClick={e => handleSaveOrderDetailsStandardMenu(state.patient_name, state.details)}
                                                        >
                                                            <BoyIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </Grid>
                                        <Grid item xs={2} md={12}>                                                
                                            <EditOrderPopper
                                                key={props.order.order_id.toString() + "_" + props.item.order_detail_id.toString() + "_" + props.item.structure_id.toString()}
                                                disabled={ props.order.order_id <= 0 || props.item.order_detail_id <= 0}
                                                name={props.item.name}
                                                menu_ids={props.item.menu_ids}
                                                menus_available={menusAvailable}
                                                menu_custom={props.item.menu_custom}
                                                onSaveCallback={handleSaveOrderDetailsCustomMenu}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {props.item.menu_reversal !== "" ?
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="h6"
                                    style={{overflowWrap: 'anywhere'}}
                                >
                                    {"Reversal: " + props.item.menu_reversal}
                                </Typography>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            
        </>
    );    
}


export default EditOrderItem;