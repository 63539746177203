import * as React from 'react';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import * as ReportsStore from '../store/Reports';
import * as Validator from "../validator/Validator";
import { Box, Grid, Button, Input, InputLabel, Select, MenuItem, Tooltip, TextareaAutosize, Stack, Pagination } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import * as Navigation from "../navigation/Navigation";
import * as utils from '../utils/Utils';
import moment from 'moment';
import { useChangeState } from '../utils/useChangeState';
import { useGetListClientsQuery, useGetListsQuery, useGetReportsQuery, useGetReportsTypeQuery, useSaveReportMutation } from '../store/apiSlice';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { RootState, useAppDispatch } from '../store/configureStore';
import * as Models from "../models/Models";
import { setError } from '../store/ApiInterface';
import { useAxiosUtil } from '../utils/axiosUtils';
import { shallowCompare } from '../utils/Utils';

export const Reports : React.FC = (props) => {    

    const dispatch = useAppDispatch();

    const { download } = useAxiosUtil();

    const storeState = useSelector((state: RootState) => state.reports)

    const [state, setState] = useState<ReportsStore.FormData>({
        ...storeState.formData
    });
    
    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<ReportsStore.FormData>({
        ...storeState.formData
    });


    const { data: pageSizeList } = useGetListsQuery('3');
    const { data: reportsType } = useGetReportsTypeQuery({ default_value: '0', default_description: 'Select the report type' });
    const { data: clientsList } = useGetListClientsQuery({ default_value: '0', default_description: 'Select the client' });
    const { data, refetch } = useGetReportsQuery((pageSizeList != null) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea
    const [ saveData ] = useSaveReportMutation();

    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            if (shallowCompare(state, searchState)){
                //cazul in care apasa pe "search" fara sa schimbe vreun parametru - fortam refresh
                refetch();
            }
            else {
                setSearchState({ ...state, PageNumber: 0 })
            }
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }

    const handleChangeStartDate = (newValue: Date | null) => {
        setState({ ...state, start_date: utils.formatDate(newValue) });
    }

    const handleChangeEndDate = (newValue: Date | null) => {
        setState({ ...state, end_date: utils.formatDate(newValue) });
    }
    
    const handleCreateReport = (event: any) => {
        event.preventDefault();
        if (handleValidationCreateReport()) {
            const data : Models.ReportSave = { report_type: state.report_type, start_date: state.start_date, end_date: state.end_date, client_id: state.client_id, description: state.description };
            saveData(data);
        }
    }

    const handleValidationCreateReport = () => {
        let result = true;
        let err = '';

        var report_type = state.report_type;
        var start_date = state.start_date;
        var end_date = state.end_date;
        var client_id = state.client_id;
        var description = state.description;

        if (report_type === 0) {
            err += "Select report type!\n";
            result = false;
        }

        if (!Validator.isDateISO(start_date)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (!Validator.isDateISO(end_date)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (client_id === 0) {
            err += "Select the client!\n";
            result = false;
        }

        if (!Validator.isText(description)) {
            err += "Illegal character in Description field!\n";
            result = false;
        }

        if (description.length < 0 || description.length > 1000) {
            err += "Description should be between 0 and 1000 characters!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }

        return result;
    }

    const handleChangeStartDateSearch = (newValue: Date | null) => {
        setState({ ...state, start_date_search: utils.formatDate(newValue) });
    }

    const handleChangeEndDateSearch = (newValue: Date | null) => {
        setState({ ...state, end_date_search: utils.formatDate(newValue) });
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var StartDate = state.start_date_search;
        var EndDate = state.end_date_search;

        if (!Validator.isDateISO(StartDate)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (!Validator.isDateISO(EndDate)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }

        return result;
    }   
    

    const renderCreateReports = () => {
        return (
            <div>
                <fieldset>
                    <legend>Generate report</legend>
                    <Grid container spacing={5}>

                        <Grid item xs={12}>
                            <InputLabel id="labelReportType">
                                Report Type
                            </InputLabel>
                            <Select
                                name="report_type"
                                labelId="labelReportType"
                                value={reportsType? state.report_type : ''}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                onChange={(e) => changeState(e)}
                            >
                                {reportsType?.map(o =>
                                    <MenuItem key={o.type} value={o.type}>{o.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>


                        <Grid item xs={12}>
                            <InputLabel id="labelDateStart">
                                Start Date
                            </InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    format="dd.MM.yyyy"
                                    value={new Date(state.start_date)}
                                    onChange={handleChangeStartDate}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel id="labelDateEnd">
                                End Date
                            </InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    format="dd.MM.yyyy"
                                    value={new Date(state.end_date)}
                                    onChange={handleChangeEndDate}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel id="labelClientId">
                                Client
                            </InputLabel>
                            <Select
                                name="client_id"
                                labelId="labelClientId"
                                value={clientsList? state.client_id : ''}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                onChange={(e) => changeState(e)}
                            >
                                {clientsList?.map(o =>
                                    <MenuItem key={o.client_id} value={o.client_id}>{o.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel id="labelDescription">
                                Description
                            </InputLabel>
                            <TextareaAutosize
                                name="description"
                                minRows={3}
                                maxRows={3}
                                style={{ width: "100%" }}
                                className="textarea"
                                value={state.description}
                                onChange={changeState}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={handleCreateReport}>
                                Generate Report
                            </Button>
                        </Grid>
                    </Grid>
                </fieldset>
            </div>
        );
    }

    const renderSearchBox = () => {
        return (            
            <div>
                <fieldset>
                    <legend>Search reports</legend>
                    <Grid container spacing={5}>
                        <Grid item xs={3}>
                            <InputLabel id="labelDatelId">
                                Start Date
                            </InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    format="dd.MM.yyyy"
                                    value={new Date(state.start_date_search)}
                                    onChange={handleChangeStartDateSearch}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="labelDatelId">
                                End Date
                            </InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    format="dd.MM.yyyy"
                                    value={new Date(state.end_date_search)}
                                    onChange={handleChangeEndDateSearch}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id="labelPageSize">
                                Page Size
                            </InputLabel>
                            <Select
                                id="PageSize"
                                labelId="labelPageSize"
                                value={pageSizeList? state.PageSize : ''}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                onChange={handleChangePageSize}
                            >
                                {pageSizeList?.map(o =>
                                    <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary" onClick={handleSearch}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </fieldset>
            </div>
        );
    }

    const renderSearchResult = useMemo(() => {    

        const handleNavigation = (pageNumber: number) => {
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }

        const handleView = (report_id: number, type: string) => {
            download('/api/Report/' + report_id + '/' + type, type, report_id.toString() + '.' + type)
        }
    
        
        const columns: GridColDef[] = [
            { field: 'report_id', headerName: 'Report Id', flex: 0.1 },
            { field: 'type', headerName: 'Type', flex: 0.1 },
            { field: 'description', headerName: 'Description', flex: 0.1 },        
            {
                field: "age",
                headerName: "Age",
                flex: 0.1,
                type: "string",
                renderCell: (params: GridCellParams) => (
                    <Tooltip title={moment(params.row.ins_date).format('DD.MM.YYYY HH:mm:ss')}>
                        <span>
                            {params.row.age}
                        </span>
                    </Tooltip>
                ),
            },
            {
                field: 'view',
                headerName: 'View',
                width: 200,
                renderCell: (params: GridCellParams) => (
                    <span>
                        <Button
                            variant="text"
                            color="primary"
                            size="small"
                            endIcon={<DownloadIcon />}
                            style={{ marginLeft: 16 }}
                            disabled={!(params.row['has_pdf'] as boolean)}
                            onClick={() => handleView(params.row['report_id'] as number, "pdf")}
                        >
                            Pdf
                        </Button>
    
                        <Button
                            variant="text"
                            color="primary"
                            size="small"
                            endIcon={<DownloadIcon />}
                            style={{ marginLeft: 16 }}
                            disabled={!(params.row['has_xlsx'] as boolean)}
                            onClick={() => handleView(params.row['report_id'] as number, "xlsx")}
                        >
                            Excel
                        </Button>
                    </span>
                ),
            },
        ];
        
        return (
            data &&
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Box sx={{ height: '100%', width: '100%' }}>
                            <DataGrid
                                autoHeight
                                rows={data.Data}
                                columns={columns}
                                getRowId={(r) => r.report_id}                            
                                rowCount={data.RowCount}
                                pagination
                                pageSizeOptions={[searchState.PageSize]}
                                paginationMode="server"
                                paginationModel= {{ pageSize: searchState.PageSize, page: searchState.PageNumber }}
                                onPaginationModelChange={(e) => handleNavigation(e.page)}
                            />
                        </Box>
                        <Stack spacing={2} justifyContent="center" alignItems="center">
                            <Pagination 
                                count={Navigation.pageCount(data.Last, data.Current)} 
                                page={Navigation.currentPage(data.Current)} 
                                onChange={(event, page) => handleNavigation(page - 1)}
                                disabled={Navigation.isNavigationDisabled(data.Last, data.Current)}
                                showFirstButton
                                showLastButton
                            />
                        </Stack>
                    </Grid>
                </Grid>
        );
    }, [data, download, searchState.PageSize, searchState.PageNumber]);

    return (
        <React.Fragment>
            <Grid container spacing={5}>
                <Grid item xs={3}>
                    {renderCreateReports()}
                </Grid>
                <Grid item xs={9}>
                    {renderSearchBox()}
                    {renderSearchResult}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default Reports;