import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Validator from "../validator/Validator";
import { Box, Grid, TextField, Button,Switch } from '@mui/material';
import * as styled from './StyledComponents'
import * as Models from '../models/Models';
import { useChangeState } from '../utils/useChangeState';
import { useGetCompanyQuery, useSaveCompanyMutation } from '../store/apiSlice';
import { useAppDispatch } from '../store/configureStore';
import { setError } from '../store/ApiInterface';



export const EditCompany : React.FC = (props) => {
    
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const id = params.id != null ? parseInt(params.id) : 0;

    const { data } = useGetCompanyQuery(id);
    const [ saveCompany ] = useSaveCompanyMutation();

    const [state, setState] = useState({
        ...Models.getCompanyDefaultValues(),
    });
    
    const changeState = useChangeState(state, setState);
    
    useEffect(() => {
        if (data){
            setState((prevState) => ({
                ...data
            }));
        }    
    }, [data]);

    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {
            saveCompany(state);
        }
    }
    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/companies");

    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var name = state.name;

        if (!Validator.isAlphaNumericAndSpaceUnderscore(name)) {
            err += "Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (name.length <= 0 || name.length > 50) {
            err += "Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    // Returns the HTML Form to the render() method.  
    const renderCreateForm = () => {
        return (
            <div>
                <fieldset>
                    <legend>Details</legend>
                    <form name="formEditCompany" id="formEditCompany" onSubmit={handleSave}>
                        <Grid container spacing={5}>
                            <Grid item xs={4}>
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <strong>Name:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="name"
                                            variant="standard"
                                            value={state.name}
                                            onChange={changeState}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <strong>Enabled:</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Switch
                                            name="enabled"
                                            checked={state.enabled}
                                            onChange={(e) => changeState(e)}
                                            color="primary"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box sx={styled.buttonsBox}>
                                            <Button variant="contained" color="primary" type="submit">
                                                Save
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={handleClose}>
                                                Close
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </fieldset>
            </div>
        )
    }

    return (
        <div>
            <h3>Company management</h3>
            <hr />
            {renderCreateForm()}
        </div>
    );

}

export default EditCompany;