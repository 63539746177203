import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import * as SettingsStore from '../store/Settings';
import { Box, Select, Grid, TextField, Button, Input, InputLabel, Switch, MenuItem } from '@mui/material';
import * as utils from '../utils/Utils';
import * as Models from "../models/Models";
import * as styled from './StyledComponents'
import { useChangeState } from '../utils/useChangeState';
import { RootState, useAppDispatch } from '../store/configureStore';
import { useGetListCompaniesQuery, useGetSettingsQuery, useSaveSettingsMutation } from '../store/apiSlice';
import { setError } from '../store/ApiInterface';


export const Settings : React.FC = (props) => {

    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.settings)

    const [state, setState] = useState<Models.Settings>({
        ...Models.geSettingsDefaultValues()
    });

    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<SettingsStore.FormData>({
        ...storeState.formData
    });

    const { data: companies } = useGetListCompaniesQuery({default_value: '0', default_description: 'Select the company'});

    const { data } = useGetSettingsQuery(searchState, {skip: searchState.company_id === 0})
    const [ saveSettings ] = useSaveSettingsMutation()
    

    useEffect(() => {
        if (data){
            setState({ ...data });
        }
    }, [data]);


    const handleChangeCompany = (event: any) => {
        setSearchState((prevState) => ({ ...prevState, company_id: Number(event.target.value) }));
    }

    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {
            saveSettings(state);
        }
    }

    const handleValidation = () => {
        let result = true;
        let err = '';        

        var cut_off_time = state.cut_off_time;
        var company_id = state.company_id;
        

        if (cut_off_time.length <= 0) {
            err += "Fill order cut off time!\n";
            result = false;
        }
        if (company_id === 0) {
            err += "Select the company!\n";
            result = false;
        }
        
        if (!result) {
            console.log(err);
            dispatch(setError(err))
        }

        return result;
    }

    const renderSearchBox = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={3}>
                    <InputLabel id="labelCompanyId">
                        Company
                    </InputLabel>
                    <Select
                        id="CompanyId"
                        labelId="labelCompanyId"
                        value={companies? searchState.company_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangeCompany}
                    >
                        {companies?.map(o =>
                            <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
            </Grid>
        );
    }
    const renderCreateForm = () => {
        return (
            <div>
                {searchState.company_id !== 0 ?
                    <>
                        <h3>{"Settings for " + state.company_name }</h3>
                        <hr />
                        <form name="formSettings" id="formSettings" onSubmit={handleSave}>
                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={5}>
                                            <strong>Order cut off time:</strong>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField
                                                name="cut_off_time"
                                                variant="standard"
                                                type="time"
                                                value={state.cut_off_time}
                                                onChange={changeState}
                                            />
                                        </Grid>

                                        <Grid item xs={5}>
                                            <strong>Allow multiple menus:</strong>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Switch
                                                name="allow_multiple_menus"
                                                checked={state.allow_multiple_menus}
                                                onChange={(e) => changeState(e)}
                                                color="primary"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box sx={styled.buttonsBox}>
                                                <Button variant="contained" color="primary" type="submit">
                                                    Save
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </>
                    :
                    null
                }
            </div>
        )
    }

    return (
        <div>
            {renderSearchBox()}
            {renderCreateForm()}
        </div>
    );
}

export default Settings;
