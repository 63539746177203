import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import * as FetchUsersStore from '../store/FetchUsers';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { Box, Select, Grid, TextField, Button, MenuItem, Input, InputLabel, Stack, Pagination } from '@mui/material';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'
import { useChangeState } from '../utils/useChangeState';
import { RootState, useAppDispatch } from '../store/configureStore';
import { useGetListClientsQuery, useGetListCompaniesQuery, useGetListsQuery, useGetListsWithDefaultValueQuery, useGetUsersQuery, useDeleteUserMutation } from '../store/apiSlice';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { setError } from '../store/ApiInterface';
import { shallowCompare } from '../utils/Utils';


export const FetchUsers : React.FC = (props) => {

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.fetchUsers)
    
    const [state, setState] = useState<FetchUsersStore.FormData>({
        ...storeState.formData,
    });    
    
    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<FetchUsersStore.FormData>({
        ...storeState.formData
    });

    const { data: yesNoList } = useGetListsQuery('1');
    const { data: pageSizeList } = useGetListsQuery('3');
    const { data: user_type } = useGetListsWithDefaultValueQuery({list_id: '5', default_value: '0', default_description: 'All'});
    const { data: clients_list } = useGetListClientsQuery({default_value: '0', default_description: 'All'});
    const { data: companies_list } = useGetListCompaniesQuery({default_value: '0', default_description: 'All'});

    const { data, refetch } = useGetUsersQuery((yesNoList != null && pageSizeList != null && user_type != null && clients_list != null && companies_list != null) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea

    const [ deleteData ] = useDeleteUserMutation();      


    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            if (shallowCompare(state, searchState)){
                //cazul in care apasa pe "search" fara sa schimbe vreun parametru - fortam refresh
                refetch();
            }
            else {
                setSearchState({ ...state, PageNumber: 0 })
            }
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }
    const handleNewUser = (event: any) => {
        navigate("/user/edit/0");
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var FirstName = state.FirstName;
        var LastName = state.LastName;        
        var UserName = state.UserName;

        if (FirstName != null && FirstName.length > 0 && !Validator.isAlphaNumericAndSpace(FirstName)) {
            err += "First Name should be alpha numeric!\n";
            result = false;
        }
        if (FirstName != null && FirstName.length > 50) {
            err += "First Name should not exceed 50 characters!\n";
            result = false;
        }

        if (LastName != null && LastName.length > 0 && !Validator.isAlphaNumericAndSpace(LastName)) {
            err += "Last Name should be alpha numeric!\n";
            result = false;
        }
        if (LastName != null && LastName.length > 50) {
            err += "Last Name should not exceed 50 characters!\n";
            result = false;
        }

        if (!Validator.isText(UserName)) {
            err += "User name is not valid!\n";
            result = false;
        }
        if (UserName != null && UserName.length > 100) {
            err += "User name should not exceed 100 characters!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }
        return result;
    }


    const renderSearchBox = () => {
        return (            
            <Grid container spacing={5}>
                <Grid item xs={3}>                            
                    <TextField
                        name="FirstName"
                        variant="standard"
                        label="First Name"
                        value={state.FirstName}
                        onChange={changeState}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        name="LastName"
                        variant="standard"
                        label="Last Name"
                        value={state.LastName}
                        onChange={changeState}
                    />
                </Grid>                        
                <Grid item xs={3}>
                    <TextField
                        name="UserName"
                        variant="standard"
                        label="User name"
                        value={state.UserName}
                        onChange={changeState}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputLabel shrink id="labelEnabled">
                        Enabled
                    </InputLabel>
                    <Select
                        name="Enabled"
                        labelId="labelEnabled"
                        value={yesNoList? state.Enabled : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {yesNoList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>

                <Grid item xs={3}>
                    <InputLabel shrink id="labelType">
                        Type
                    </InputLabel>
                    <Select
                        name="Type"
                        labelId="labelType"
                        value={user_type? state.Type : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {user_type?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <InputLabel shrink id="labelClientId">
                        Client
                    </InputLabel>
                    <Select
                        name="ClientId"
                        labelId="labelClientId"
                        value={clients_list? state.ClientId : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {clients_list?.map(o =>
                            <MenuItem key={o.client_id} value={o.client_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <InputLabel shrink id="labelCompany">
                        Company
                    </InputLabel>
                    <Select
                        name="CompanyId"
                        labelId="labelCompany"
                        value={clients_list? state.CompanyId : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {clients_list?.map(o =>
                            <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <InputLabel shrink id="labelPageSize">
                        Page Size
                    </InputLabel>
                    <Select
                        id="PageSize"
                        labelId="labelPageSize"
                        value={pageSizeList? state.PageSize : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangePageSize}
                    >
                        {pageSizeList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styled.buttonsBox}>
                        <Button variant="contained" color="primary" onClick={handleSearch}>
                            Search
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleNewUser} >
                            Create new user
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    const renderSearchResult = useMemo(() => {

        const handleDelete = (userId: number, userName: string) => {
            if (!window.confirm("Do you want to delete the user: " + userName))
                return;
            else {
                deleteData(userId);
            }
        }

        const handleEdit = (userId: number) => {
            navigate("/user/edit/" + userId);
        }

        const handleNavigation = (pageNumber: number) => {
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }

        const columns: GridColDef[] = [
            { field: 'UserName', headerName: 'User Name', flex: 0.1 },
            { field: 'FirstName', headerName: 'First Name', flex: 0.1 },
            { field: 'LastName', headerName: 'Last Name', flex: 0.1 },
            { field: 'Type', headerName: 'Type', flex: 0.1 },
            { field: 'ClientName', headerName: 'Client Name', flex: 0.1 },
            { field: 'CompanyName', headerName: 'Company Name', flex: 0.1 },
            
            {
                field: 'user_id',
                headerName: 'Edit',
                width: 200,
                renderCell: (params: GridCellParams) => (
                    <span>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={() => handleEdit(params.row['UserId'] as number)}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            onClick={() => handleDelete(params.row['UserId'] as number, params.row['UserName']?.toString() || '')}
                        >
                            Delete
                        </Button>
                    </span>
                ),
            },
    
        ];

        return (
            data &&
                <fieldset>
                    <legend>Search results</legend>
                    <Box sx={{ height: '100%', width: '100%' }}>
                        <DataGrid
                            autoHeight
                            rows={data.Data}
                            columns={columns}
                            getRowId={(r) => r.UserId}
                            rowCount={data.RowCount}
                            pagination
                            pageSizeOptions={[searchState.PageSize]}
                            paginationMode="server"
                            paginationModel= {{ pageSize: searchState.PageSize, page: searchState.PageNumber }}
                            onPaginationModelChange={(e) => handleNavigation(e.page)}
                        />
                    </Box>
                    <Stack spacing={2} justifyContent="center" alignItems="center">
                        <Pagination 
                            count={Navigation.pageCount(data.Last, data.Current)} 
                            page={Navigation.currentPage(data.Current)} 
                            onChange={(event, page) => handleNavigation(page - 1)}
                            disabled={Navigation.isNavigationDisabled(data.Last, data.Current)} 
                            showFirstButton
                            showLastButton
                        />
                    </Stack>
                </fieldset>
                
        );
    }, [data, deleteData, navigate, searchState.PageSize, searchState.PageNumber]);

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Users</h1>
            {renderSearchBox()}
            {renderSearchResult}
        </React.Fragment>
    );
}

export default FetchUsers;