import * as React from 'react';
import { useSelector } from 'react-redux'
import * as FetchDishesStore from '../store/FetchDishes';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { Select, Grid, TextField, Button, MenuItem, Input, InputLabel, Stack, Pagination } from '@mui/material';
import { Close as CloseIcon, SaveAlt as SaveAltIcon } from '@mui/icons-material';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'

import {
    Box,
    Card,
    Checkbox,
    ListItemAvatar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import Image from './Image'
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useChangeState } from '../utils/useChangeState';
import { RootState, useAppDispatch } from '../store/configureStore';
import { useDeleteDishMutation, useGetDishesQuery, useGetListIngredientsQuery, useGetListsQuery } from '../store/apiSlice';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { setError } from '../store/ApiInterface';
import { shallowCompare } from '../utils/Utils';

export interface FetchDishesProps {
    selectable?: boolean;
    selectedDishesIds?: number[];
    onCloseCallback?(): void;
    onSaveCallback?(selectedDishesIds: number[]): void;
}


export const FetchDishes : React.FC<FetchDishesProps> = (props) => {
    
    const navigate = useNavigate();
    
    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.fetchDishes)
    
    const [state, setState] = useState<FetchDishesStore.FormData>({
        ...storeState.formData,
    });
    
    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<FetchDishesStore.FormData>({
        ...storeState.formData
    });

    const [selectedDishesIds, setSelectedDishesIds] = useState<number[]>(props.selectedDishesIds ?? []);

    const { data: yesNoList } = useGetListsQuery('1');
    const { data: pageSizeList } = useGetListsQuery('3');
    const { data: ingredients } = useGetListIngredientsQuery({default_value: '0', default_description: 'All'});
    const { data, refetch } = useGetDishesQuery((yesNoList != null && pageSizeList != null && ingredients != null) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea
    const [ deleteData ] = useDeleteDishMutation();

    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            if (shallowCompare(state, searchState)){
                //cazul in care apasa pe "search" fara sa schimbe vreun parametru - fortam refresh
                refetch();
            }
            else {
                setSearchState({ ...state, PageNumber: 0 })
            }
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }

    const handleNewDish = (event: any) => {
        navigate("/dishes/edit/0");
    }
    
    const handleValidation = () => {
        let result = true;
        let err = '';

        var title = state.title;
        var ext_code = state.ext_code;

        if (!Validator.isText(title)) {
            err += "Illegal character in Title field!\n";
            result = false;
        }
        if (title != null && title.length > 1000) {
            err += "Title should not exceed 1000 characters!\n";
            result = false;
        }

        if (!Validator.isText(ext_code)) {
            err += "Illegal character in Ext code field!\n";
            result = false;
        }
        if (ext_code != null && ext_code.length > 50) {
            err += "Ext code should not exceed 50 characters!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }
        return result;
    }


    const renderSearchBox = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={3}>
                    <TextField
                        name="title"
                        variant="standard"
                        label="Title"
                        value={state.title}
                        onChange={changeState}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        name="ext_code"
                        variant="standard"
                        label="Ext code"
                        value={state.ext_code}
                        onChange={changeState}
                    />
                </Grid>
                
                <Grid item xs={2}>
                    <InputLabel shrink id="labelIngredient">
                        Ingredient
                    </InputLabel>
                    <Select
                        name="ingredient_id"
                        labelId="labelIngredient"
                        value={ingredients? state.ingredient_id : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {ingredients?.map(o =>
                            <MenuItem key={o.ingredient_id} value={o.ingredient_id}>{o.name}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel shrink id="labelEnabled">
                        Enabled
                    </InputLabel>
                    <Select
                        name="enabled"
                        labelId="labelEnabled"
                        value={yesNoList? state.enabled : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                    >
                        {yesNoList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel shrink id="labelPageSize">
                        Page Size
                    </InputLabel>
                    <Select
                        id="PageSize"
                        labelId="labelPageSize"
                        value={pageSizeList? state.PageSize : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangePageSize}
                    >
                        {pageSizeList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styled.buttonsBox}>
                        <Button variant="contained" color="primary" onClick={handleSearch}>
                            Search
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleNewDish} >
                            Add dish
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    const renderSearchResult = useMemo(() => {            

        const handleDelete = (dish_id: number, title: string) => {
            if (!window.confirm("Do you want to delete the dish: " + title))
                return;
            else {
                deleteData(dish_id);
            }
        }
        const handleEdit = (dish_id: number) => {
            navigate("/dishes/edit/" + dish_id);
        }    

        const handleNavigation = (pageNumber: number) => {        
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }

        
        const handleSelectDish = (event: any, dish_id: number) => {            
            const selectedIndex = selectedDishesIds.indexOf(dish_id);
            let newSelectedDishesIds: number[] = [];

            if (selectedIndex === -1) {
                newSelectedDishesIds = newSelectedDishesIds.concat(selectedDishesIds, dish_id);
            } else if (selectedIndex === 0) {
                newSelectedDishesIds = newSelectedDishesIds.concat(selectedDishesIds.slice(1));
            } else if (selectedIndex === selectedDishesIds.length - 1) {
                newSelectedDishesIds = newSelectedDishesIds.concat(selectedDishesIds.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelectedDishesIds = newSelectedDishesIds.concat(
                    selectedDishesIds.slice(0, selectedIndex),
                    selectedDishesIds.slice(selectedIndex + 1)
                );
            }
            setSelectedDishesIds(newSelectedDishesIds);
        };
    

        return (
            data &&
                <div>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <fieldset>
                                <legend>Search results</legend>
                                <Card>
                                    <Box>
                                        <Table>
                                            <TableHead>
                                                <TableRow key={0}>
                                                    {props.selectable ?
                                                        <TableCell>
                                                            Select
                                                        </TableCell> : ""
                                                    }
                                                    <TableCell>
                                                        Title
                                                    </TableCell>
                                                    <TableCell>
                                                        Ext code
                                                    </TableCell>
                                                    <TableCell>
                                                        KCal
                                                    </TableCell>
                                                    <TableCell>
                                                        Description
                                                    </TableCell>
                                                    <TableCell>
                                                        Enabled
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: "200px" }}>
                                                        Edit
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.Data.map((item) => (
                                                    <TableRow
                                                        hover
                                                        key={item.data.dish_id}
                                                        selected={props.selectable && selectedDishesIds.indexOf(item.data.dish_id) !== -1}
                                                    >
                                                        {props.selectable ?
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={selectedDishesIds.indexOf(item.data.dish_id) !== -1}
                                                                    onChange={(event) => handleSelectDish(event, item.data.dish_id)}
                                                                    value="true"
                                                                />
                                                            </TableCell> : ""
                                                        }

                                                        <TableCell>
                                                            <Box
                                                                sx={{
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}
                                                            >
                                                                <ListItemAvatar>
                                                                    <Image 
                                                                        url = {item.picture.picture_thumbnail_url}
                                                                        height = {48}
                                                                        width = {48}
                                                                        />
                                                                </ListItemAvatar>
                                                                <Typography
                                                                    color="textPrimary"
                                                                    variant="body1"
                                                                >
                                                                    {item.data.title}
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.data.ext_code}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.data.kcal}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.data.description}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.data.enabled ? "Yes" : "No"}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                onClick={(id) => handleEdit(item.data.dish_id)}>
                                                                Edit
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                size="small"
                                                                style={{ marginLeft: 16 }}
                                                                onClick={(id) => handleDelete(item.data.dish_id, item.data.title)}>
                                                                Delete
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Card>
                                <Stack spacing={2} justifyContent="center" alignItems="center">
                                    <Pagination 
                                        count={Navigation.pageCount(data.Last, data.Current)} 
                                        page={Navigation.currentPage(data.Current)} 
                                        onChange={(event, page) => handleNavigation(page - 1)} 
                                        disabled={Navigation.isNavigationDisabled(data.Last, data.Current)} 
                                        showFirstButton
                                        showLastButton
                                    />
                                </Stack>
                            </fieldset>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Box sx={styled.buttonsBox}>
                                {props.onCloseCallback != null ?
                                    <Button 
                                        variant="outlined" 
                                        startIcon={<CloseIcon />} 
                                        onClick={props.onCloseCallback}>
                                        Close
                                    </Button> : ""
                                }
                                {props.onSaveCallback != null ?
                                    <Button 
                                        variant="contained" 
                                        endIcon={<SaveAltIcon />} 
                                        onClick={(e) => {
                                            if (props.onSaveCallback) {
                                                props.onSaveCallback(selectedDishesIds)}
                                            }
                                        }>
                                        Save
                                    </Button> : ""
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </div>
        );
    }, [data, deleteData, navigate, selectedDishesIds, props]);

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Dishes</h1>
            {renderSearchBox()}
            {renderSearchResult}
        </React.Fragment>
    );
}


export default FetchDishes;