



export function displayNavigationText(navigationText: string, page: number): string {
    var ret = '';

    if (navigationText === 'Current') {
        ret = '(' + Number(page + 1) + ')';
    }
    else {
        if (page >= 0)
            ret = navigationText + '(' + Number(page + 1) + ')';
        else
            ret = navigationText;
    }
    return ret;
}


export function isNavigationDisabled(last:number, current: number): boolean {
    var ret = last === -1 && current === 0;
    return ret;
}

export function currentPage(current: number): number {    
    return current + 1;
}

export function pageCount(last:number, current: number): number {
    if (last === -1) //last va fi -1 cand am ajuns la ultima pagina (nu mai exista alte pagini dupa) -> in acest caz returnam pagina curenta ca fiind numarul total de pagini
        return current + 1;
    else //daca inca avem pagini de navigat atunci returnam "last + 1" -> este 0 based calculul de pagini in navigare
        return last + 1;

}