import * as React from 'react';
import * as Models from "../models/Models";
import { useState } from 'react';
import { Tooltip, IconButton, Box, Popper, Button, Grid, ClickAwayListener } from '@mui/material';

import { 
    PauseCircleOutline as PauseCircleOutlineIcon,  
    Delete as DeleteIcon
} 
from '@mui/icons-material';

import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import * as utils from '../utils/Utils';
import moment from 'moment';

export interface SchedulerSetEndDatePopperState {
    anchorEl: null | HTMLElement;
    end_date: string | null;
}

export interface SchedulerSetEndDatePopperProps {
    end_date: string | null;
    trigger: Models.SchedulerTrigger;
    onSaveCallback(trigger: Models.SchedulerTrigger, end_date: string): void;
}

export const SchedulerSetEndDatePopper : React.FC<SchedulerSetEndDatePopperProps> = (props) => {

    const [state, setState] = useState<SchedulerSetEndDatePopperState>({        
        anchorEl: null,
        end_date: props.end_date,
    });

    const handleCustomMenuOpen = (event: React.MouseEvent<HTMLElement>) => {        
        setState((prevState) => ({
            ...prevState,
            anchorEl: event.currentTarget,
        }));
    }

    const handleCustomMenuClose = (event: any) => {
        setState((prevState) => ({
            ...prevState,
            anchorEl: null,
        }));
    }

    const handleCustomMenuSave = () => {
        setState({
            ...state,
            anchorEl: null,
        });

        const date = state.end_date != null ? moment(state.end_date).format('DD.MM.YYYY') : '';
        props.onSaveCallback(props.trigger, date);
    }
    
    const handleChangeDate = (newValue: Date | null) => {
        setState({ ...state, end_date: newValue !== null ? utils.formatDate(newValue) : null });
    }

    const handleClearDate = (event: React.MouseEvent<HTMLElement>) => {        
        setState({ ...state, end_date: null });
    }
    
    return (
        <>
            <Box sx={{ display: 'flex', alignItems:'center' }}>
                <div>
                    {props.end_date ? moment(props.end_date).format('DD.MM.YYYY') : ''}
                </div>
                <Tooltip title="Set End Date">
                        <span>                    
                            <IconButton
                                color="primary" 
                                aria-label="set-end-date"
                                size="large"
                                onClick={handleCustomMenuOpen}                        
                            >
                                <PauseCircleOutlineIcon fontSize="inherit" />
                            </IconButton>
                        </span>
                    </Tooltip>
            </Box>            

            <Popper id="popper-custom-menu"
                open={Boolean(state.anchorEl)}
                placement="bottom-start"
                anchorEl={state.anchorEl}>

                <ClickAwayListener onClickAway={handleCustomMenuClose}>

                    <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                        <div style={{ padding: 5, }}>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label='End Date'
                                            format="dd.MM.yyyy"
                                            value={state.end_date !== null ? new Date(state.end_date) : null}
                                            onChange={handleChangeDate}
                                            slotProps={{ textField: { variant: 'standard' } }}
                                        />
                                    </LocalizationProvider>
                                    <Tooltip title="Clear End Date">
                                        <span>
                                            <IconButton
                                                color="primary" 
                                                aria-label="set-end-date"
                                                size="large"
                                                disabled={state.end_date === null}
                                                onClick={handleClearDate}                        
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button onClick={handleCustomMenuClose}>
                                        Close
                                    </Button>
                                    <Button color="primary" onClick={e => handleCustomMenuSave()}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </ClickAwayListener>
            </Popper>
        </>
    );
    
}

export default SchedulerSetEndDatePopper;

