import { useCallback } from "react";

export function useChangeState(state: any, setState: any) {       
    
    
    const changeState = useCallback(function(e: any, inner_object_1?: string, inner_object_2?: string) {
        const var_name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        //updateState(state, setState, var_name, value, inner_object_1, inner_object_2)
        if (var_name == null){
            throw new Error('Variable name is not specified; set "name" attibute!');
        }

        if (inner_object_2 != null && inner_object_1 == null){
            throw new Error('inner_object_2 can not be null and inner_object_1 null!');
        }
        
        if (inner_object_1 != null && inner_object_2 != null){ // caz folosit din EditCompany unde obiectele din state sunt pe 2 nivele ex.

            const new_state = { ...state };
            const obj1 = state[inner_object_1]
            const obj2 = state[inner_object_1][inner_object_2];            

            if (obj1 == null){
                throw new Error(`state does not contain ${inner_object_1} !`);
            }
            if (obj2 == null){
                throw new Error(`state does not contain ${inner_object_1}.${inner_object_2} !`);
            }

            if (obj2[var_name] == null){
                throw new Error(`${inner_object_1}.${inner_object_2} does not contain ${var_name} !`);
            }

            obj2[var_name] = value;
            setState(new_state);
        }
        else if (inner_object_1 != null){           
            const new_state = { ...state }; 
            const obj = new_state[inner_object_1];

            if (obj == null){
                throw new Error(`state does not contain ${inner_object_1} !`);
            }

            if (obj[var_name] == null){
                throw new Error(`${inner_object_1} does not contain ${var_name} !`);
            }

            obj[var_name] = value;
                        
            setState(new_state);
            
        }
        else{

            if (state[var_name] == null){
                throw new Error(`state does not contain ${var_name} !`);
            }            
            setState({...state, [var_name]: value})            
        }

    }, [state, setState]);


    return changeState;
}