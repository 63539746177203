import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthState } from '../models/Models';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  AppfaceState {
    online: boolean;
    auth_state?: AuthState | null;
}

const unloadedState: AppfaceState = {
    online: window.navigator.onLine,
};

/* new reducer */

const ApiInterfaceSlice = createSlice({
    name: 'App',
    initialState: unloadedState,
    reducers: {
        setOnline(state, action: PayloadAction<boolean>) {
            state.online = action.payload        
        },
        setAuthState(state, action: PayloadAction<AuthState>) {
            state.auth_state = action.payload
        },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = ApiInterfaceSlice
  // Extract and export each action creator by name
  export const { setOnline, setAuthState } = actions
  // Export the reducer, either as a default or named export
  export default reducer