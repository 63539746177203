import * as Models from "../models/Models";
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface  FetchIngredientsState {
    formData: FormData;
}

export class FormData extends Models.SearchPaginationInput {
    name: string = "";
    ext_code: string = "";
    enabled: string = "0";
}

const unloadedState: FetchIngredientsState = {
    formData: {
        ...Models.getSearchPaginationInputDefaultValues(),
        name: "",
        ext_code: "",
        enabled: "0",
    }
};


/* new reducer */

const fetchIngredientsSlice = createSlice({
    name: 'FetchIngredients',
    initialState: unloadedState,
    reducers: {
      setFetchIngredientsFormData(state, action: PayloadAction<FormData>) {
        state.formData = action.payload
      },
    },
  })
  
  // Extract the action creators object and the reducer
  const { actions, reducer } = fetchIngredientsSlice
  // Extract and export each action creator by name
  export const { setFetchIngredientsFormData } = actions
  // Export the reducer, either as a default or named export
  export default reducer