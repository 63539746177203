import React, { useState, useEffect } from 'react';
import missingImage from '../icons/missing-image.jpg'; // with import
import { useAxiosUtil } from '../utils/axiosUtils';

interface ImageProps {
    url: string;
    height: number;
    width: number;
  }
  
  const Image: React.FC<ImageProps> = ({ url, height, width }) => {    

    const [imageUrl, setImageUrl] = useState<string>();

    const { download_as_object_url } = useAxiosUtil()

    function clean() {
      if (imageUrl != null)
      {
        URL.revokeObjectURL(imageUrl);
      }
    } 

    useEffect(() => {
      if (url)
      {        
        //cazul cand facem upload la o poza si vrem sa o afisam inaine de a o salva in baza de date
        //in acest caz se creaza un URL din poza (obiectul blob) care se seteaza la parametrul url al componentei Image
        //deja avand obiectul blob facut nu mai trebuie sa-l obtinem apeland API-ul
        //vezi metoda "handleUploadFile" din componenta "EditDish"
        if (url.startsWith('blob')){
          setImageUrl(url);
        }
        else{
          download_as_object_url(url).then((url: string) =>
          {
            setImageUrl(url);
          })
        }
      }
    }, [url, download_as_object_url]);

    /*alternativa la clean pe evenimentul onload din img
    useEffect( () => () => {
      if (localImageUrl != null)
      {
        URL.revokeObjectURL(localImageUrl);
      }
    }, [] );
    */

    
    return (
      <img
          src={imageUrl == null ? missingImage : imageUrl}
          alt=''
          style={{
              height: height,
              width: width
          }}
          onLoad = {() => { clean(); } }
      />      
    );
  };
  
  export default Image;
  