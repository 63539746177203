import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux'
import * as FetchMenuTypesStore from '../store/FetchMenuTypes';
import * as Validator from "../validator/Validator";
import * as Navigation from "../navigation/Navigation";
import { Select, Grid, TextField, Button, MenuItem, Input, InputLabel, Stack, Pagination } from '@mui/material';
import * as utils from '../utils/Utils';
import * as styled from './StyledComponents'

import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { useChangeState } from '../utils/useChangeState';
import { RootState, useAppDispatch } from '../store/configureStore';
import { useGetListsQuery, useGetMenuTypesQuery, useDeleteMenuTypeMutation } from '../store/apiSlice';
import { setError } from '../store/ApiInterface';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { shallowCompare } from '../utils/Utils';


export const FetchMenuTypes : React.FC = (props) => {
    
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.fetchMenuTypes)
    
    const [state, setState] = useState<FetchMenuTypesStore.FormData>({
        ...storeState.formData
    });    
    
    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<FetchMenuTypesStore.FormData>({
        ...storeState.formData
    });

    const { data: yesNoList } = useGetListsQuery('1');
    const { data: pageSizeList } = useGetListsQuery('3');

    const { data, refetch } = useGetMenuTypesQuery((yesNoList != null && pageSizeList != null) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea

    const [ deleteData ] = useDeleteMenuTypeMutation();        

    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            if (shallowCompare(state, searchState)){
                //cazul in care apasa pe "search" fara sa schimbe vreun parametru - fortam refresh
                refetch();
            }
            else {
                setSearchState({ ...state, PageNumber: 0 })
            }
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }


    const handleNewMenuType = (event: any) => {
        navigate("/menutypes/edit/0");
    }

    
    const handleValidation = () => {
        let result = true;
        let err = '';

        var name = state.name;

        if (name != null && name.length > 0 && !Validator.isAlphaNumericAndSpace(name)) {
            err += "Name should be alpha numeric!\n";
            result = false;
        }
        if (name != null && name.length > 50) {
            err += "Name should not exceed 50 characters!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err));
        }
        return result;
    }
    

    const renderSearchBox = () => {
        return (
            <Grid container spacing={5}>
                <Grid item xs={4}>                            
                    <TextField
                        name="name"
                        variant="standard"
                        label="Name"
                        value={state.name}
                        onChange={changeState}
                    />
                </Grid>
                <Grid item xs={4}>                            
                    <InputLabel shrink id="labelEnabled">
                        Enabled
                    </InputLabel>
                    <Select
                        name="enabled"
                        labelId="labelEnabled"
                        value={yesNoList? state.enabled : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={(e) => changeState(e)}
                        >
                        {yesNoList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel shrink id="labelPageSize">
                        Page Size
                    </InputLabel>
                    <Select
                        id="PageSize"
                        labelId="labelPageSize"
                        value={pageSizeList? state.PageSize : ''}
                        input={<Input />}
                        MenuProps={utils.MenuProps}
                        onChange={handleChangePageSize}
                    >
                        {pageSizeList?.map(o =>
                            <MenuItem key={o.Value} value={o.Value}>{o.Description || o.Value}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styled.buttonsBox}>
                        <Button variant="contained" color="primary" onClick={handleSearch}>
                            Search
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleNewMenuType} >
                            Create new menu type
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        );
    }

    const renderSearchResult = useMemo(() => {
        const handleDelete = (menu_type_id: number, name: string) => {
            if (!window.confirm("Do you want to delete the menu type: " + name))
                return;
            else {
                deleteData(menu_type_id);
            }
        }
    
        const handleEdit = (menu_typeId: number) => {
            navigate("/menutypes/edit/" + menu_typeId);
        }

        const handleNavigation = (pageNumber: number) => {        
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }

        return (
            data &&
                <div>
                    <fieldset>
                        <legend>Search results</legend>
                        <Card>                        
                            <Box>
                                <Table>
                                    <TableHead>
                                        <TableRow key={0}>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                Enabled
                                            </TableCell>
                                            <TableCell>
                                                Dashboard color
                                            </TableCell>
                                            <TableCell style={{ minWidth: "200px" }}>
                                                Edit
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.Data.map((item) => (
                                            <TableRow
                                                hover
                                                key={item.menu_type_id}>
                                                <TableCell>
                                                    {item.name}
                                                </TableCell>
                                                <TableCell>
                                                    {item.enabled ? "Yes" : "No"}
                                                </TableCell>
                                                <TableCell>
                                                    <div style={{ backgroundColor: item.dashboard_color, height: "20px" }} >
                                                    
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        onClick={(id) => handleEdit(item.menu_type_id)}>
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        size="small"
                                                        style={{ marginLeft: 16 }}
                                                        onClick={(id) => handleDelete(item.menu_type_id, item.name)}>
                                                        Delete
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Card>
                        <Stack spacing={2} justifyContent="center" alignItems="center">
                            <Pagination 
                                count={Navigation.pageCount(data.Last, data.Current)} 
                                page={Navigation.currentPage(data.Current)} 
                                onChange={(event, page) => handleNavigation(page - 1)}
                                disabled={Navigation.isNavigationDisabled(data.Last, data.Current)} 
                                showFirstButton
                                showLastButton
                            />
                        </Stack>
                    </fieldset>
                </div>
        );
    }, [data, deleteData, navigate]);

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Menu type</h1>
            {renderSearchBox()}
            {renderSearchResult}
        </React.Fragment>
    );
}

export default FetchMenuTypes;